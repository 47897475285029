import {TriggersActions} from "../actionTypes/triggers.constant";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import cookie from "react-cookies";

export const openAppSidebarTop = (response) => ({
    type: TriggersActions.OPEN_APP_SIDEBAR_TOP,
    response: response,
})

export const openAnnotationAppAnno = (response) => ({
    type: TriggersActions.OPEN_ANNOTATION_APP_ANNOTATION,
    response: response,
})

export const updateTopBarAppOpen = (response) => ({
    type: TriggersActions.UPDATE_TOP_BAR_APP_OPEN,
    response: response
})