import {
	ADD_NEW_ANNOTATIONS,
	UPDATE_ANNOTATION_STATE,
	UPDATE_DEEP_BIO_STATE,
	UPDATE_GRID_STATE,
	UPDATE_MAP_STATE,
	UPDATE_MAPS_STATE,
	UPDATE_SLIDE_STATE,
	UPDATE_VIEWER_SETTINGS,
	ADD_NEW_DEEP_BIO_ANNOTATIONS,
	DELETE_ANNOTATIONS, DELETE_DEEP_BIO_ANNOTATIONS
} from "../actionTypes/maps.state.const";

const initialState = {
}

export const mapsStateReducer = (state = initialState, action) => {
	let annotationState, deepBioState;
	switch (action.type) {
		case UPDATE_MAP_STATE:
			return Object.assign({}, state, {
				[action.mapId] : {
					...state[action.mapId],
					...action.mapState,
				}
			});

		case UPDATE_MAPS_STATE:
			return Object.assign({}, action.state);

		case UPDATE_SLIDE_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					slideState: {
						...state[action.mapId].slideState,
						...action.slideState,
					}
				}
			});

		case UPDATE_GRID_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					gridState: {
						...state[action.mapId].gridState,
						...action.gridState,
					}
				}
			});

		case UPDATE_VIEWER_SETTINGS:
			state[action.mapId].slideState.slide_data.viewer_settings = {
				...state[action.mapId].slideState.slide_data.viewer_settings,
				...action.viewerSettings,
			};
			return Object.assign({}, state);

		case UPDATE_ANNOTATION_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					annotationState: {
						...state[action.mapId].annotationState,
						...action.annotationState,
					}
				}
			});

		case ADD_NEW_ANNOTATIONS:
			annotationState = state[action.mapId].annotationState;
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					annotationState: {
						...annotationState,
						annotations: [...annotationState.annotations.filter(annotation =>
							action.annotations.map(annotation => annotation.id).indexOf(annotation.id) === -1),
							...action.annotations].sort((annotation1, annotation2) =>
							annotation2.id - annotation1.id),
						// sort in decrement order by id, same way it's coming from backend
						features: [...annotationState.features.filter(feature =>
							action.features.map(feature => feature.getId()).indexOf(feature.getId()) === -1),
							...action.features].sort((feature1, feature2) =>
							feature2.getId() - feature1.getId()),
					}
				}
			});

		case DELETE_ANNOTATIONS:
			annotationState = state[action.mapId].annotationState;
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					annotationState: {
						...annotationState,
						annotations: [...annotationState.annotations.filter(annotation =>
							action.annotationIds.indexOf(annotation.id) === -1)],
						features: [...annotationState.features.filter(feature =>
							action.annotationIds.indexOf(feature.getId()) === -1)],
					},
				},
			});

		case UPDATE_DEEP_BIO_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					deepBioState: {
						...state[action.mapId].deepBioState,
						...action.deepBioState,
					}
				}
			});

		case ADD_NEW_DEEP_BIO_ANNOTATIONS:
			deepBioState = state[action.mapId].deepBioState;
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					deepBioState: {
						...deepBioState,
						annotations: [...deepBioState.annotations.filter(annotation =>
							action.annotations.map(annotation => annotation.id).indexOf(annotation.id) === -1),
							...action.annotations].sort((annotation1, annotation2) =>
							annotation2.id - annotation1.id),
						// sort in decrement order by id, same way it's coming from backend
						features: [...deepBioState.features.filter(feature =>
							action.features.map(feature => feature.getId()).indexOf(feature.getId()) === -1),
							...action.features].sort((feature1, feature2) =>
							feature2.getId() - feature1.getId()),
					}
				}
			});

		case DELETE_DEEP_BIO_ANNOTATIONS:
			deepBioState = state[action.mapId].deepBioState;
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					deepBioState: {
						...deepBioState,
						annotations: [...deepBioState.annotations.filter(annotation =>
							action.annotationIds.indexOf(annotation.id) === -1)],
						features: [...deepBioState.features.filter(feature =>
							action.annotationIds.indexOf(feature.getId()) === -1)],
					},
				},
			});

		default:
			return state;
	}
}
