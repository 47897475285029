import React, { Component } from "react";
import {Stack, IconButton, Tooltip} from "@mui/material";
import {ScreenshotTwoTone} from "@mui/icons-material";
import {morphleBaseTheme1} from "../themes";
import {downloadScreenshot} from "../utils/screenshot_utils";
import {connect} from "react-redux";

class Screenshot extends Component {

	initData = () => {
		this.activeMapId = this.props.activeMapId;
		this.slideState = this.props.mapsState[this.activeMapId].slideState;
		this.viewerSettings = this.slideState.slide_data.viewer_settings;
	}

	render() {
		this.initData();
		if (!this.slideState) return <div />;

		let filename = "morphle_image";

		return <Stack direction={"row"} spacing={1} justifyContent="flex-start" sx={{alignItems: 'center'}}>
			<Tooltip title={"Screenshot"} placement={"bottom"}>
				<IconButton size="small" onClick={() => downloadScreenshot("viewer",
					filename, this.props.screenshotState.extension, this.viewerSettings)}>
					<ScreenshotTwoTone sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}} />
				</IconButton>
			</Tooltip>
		</Stack>
	}
}


const mapStateToProps = (state) => {
	return {
		activeMapId: state.gammaStateReducer.activeMapId,
		mapsState: state.mapsStateReducer,
		screenshotState: state.screenshotStateReducer,
	}
}
export default connect(mapStateToProps)(Screenshot)
