import React, { Component } from "react";
import { connect } from "react-redux";
import Plot from 'react-plotly.js';
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token";
import { Input, Switch, Drawer, Modal, Row, Col, Icon, Spin, message, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';


var querystring = require('querystring');

class GeneralSettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            export_path : null,
            export_prefix : null,
            auto_export_enabled : false
        }
    }

    getData = () => {
        console.log("going to get sat");
        let url = `/api/get_global_conf/` ;
        console.log("path ", url);
        axios.get(url)
            .then(response => {
                if (response.status !== 200) {
                    message.error("Error");
                } else {
                    console.log(response);
                    this.setState({
                        export_path: response.data['export_path'],
                        export_prefix: response.data['export_prefix'],
                        auto_export_enabled: response.data['auto_export_enabled'] == "true" ? true :  false
                    })
                }
                console.log(this.state);
            })
            .catch(err => {
                console.log(err);
            })
    };

    setData = (key, value) => {
        let url = `/api/set_global_conf/` ;
        let data = {'key':key, 'value': value};
        console.log("path ", url, "data", data);
        axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status !== 200) {
                    message.error("Error");
                } else {
                    message.info("Request Success");
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    componentDidMount = () => {
        this.getData();
    }

    changeExportPath = () => {
        this.setData("export_path", this.state.export_path);
        message.info("Export Path Updated");
    }

    changeExportPrefix = () => {
        this.setData("export_prefix", this.state.export_prefix);
        message.info("Export Prefix Updated");
    }

    onAutoExportChange = (checked, event) => {
        this.setState({
            auto_export_enabled: checked
        });
        this.setData("auto_export_enabled", checked ? "true":"false");
        message.info(checked ? "Auto export is now ON" : "Auto Export turned off");
    }

    render() {
        return (
            <div id="scanner-div">
                <Row className="rounded-container">
                    <Col className="scanner-name arial-font" span={10}>
                        {"Export Settings"}
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row>
                    <Col span={2} offset={1}>
                    Auto Export
                    </Col>
                    <Col span={5}>
                        <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.auto_export_enabled}
                        onChange={this.onAutoExportChange}
                        /> </Col>

                </Row>
                <Row><Col span={20} offset={1}>Scans taken while auto export is ON, will be auto exported to given export path.</Col></Row>
                <br></br>
                <Row>
                    <Col span={2} offset={1}>
                    Export Prefix
                    </Col>
                    <Col span={10}>
                        <Input.Group compact>
                        <Input style={{ width: 'calc(100% - 200px)' }} value={this.state.export_prefix}
                        onChange={(e) => e.target.value != undefined && e.target.value != "" ? this.setState({export_prefix: e.target.value}) : undefined}/>
                        <Button type="primary" onClick={this.changeExportPrefix}>Submit</Button>
                        </Input.Group>
                    </Col>
                </Row>
                <Row><Col span={20} offset={1}>Only scans with above prefix in their name are taken up for export</Col></Row>
                <br></br>
                <Row>
                    <Col span={2} offset={1}>
                    Export Path
                    </Col>
                    <Col span={10}>
                        <Input.Group compact>
                        <Input style={{ width: 'calc(100% - 200px)' }} value={this.state.export_path}
                        onChange={(e) => e.target.value != undefined && e.target.value != "" ? this.setState({export_path: e.target.value}) : undefined}/>
                        <Button type="primary" onClick={this.changeExportPath}>Submit</Button>
                        </Input.Group>
                    </Col>
                </Row>
                <Row><Col span={20} offset={1}>Path Where scans are to be stored after export</Col></Row>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(GeneralSettingsView);