import React, {Component} from  "react";
import {Row, Col, Divider, Switch } from 'antd';
import {connect} from 'react-redux';


import {View} from "ol";
import {OverviewMap} from 'ol/control.js';
import TileLayer from "ol/layer/Tile";
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';
import {addToVisitedList, getColorLayer} from '../../neoviewer/apps/preview_coloring';
import {showSeenAreaChanged} from "../../../action/viewerSetting.action";

import "../../../asset/style/neoviewer/preview.css"

class PreviewMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewColouring: false
        }
        this.slideState = this.props.mapsState[this.props.activeMapId].slideState;
        this.init();
        document.addEventListener("keydown", this.preventTab)
    }

    init = () => {
        this.initResolutions();
        this.initPreviewMap();
        this.initZoomVisitedMap();
        this.initColorLayer();

        this.slideState.slidemap.on(['click', 'singleclick', 'moveend'], this.onVisitEvent);
    }

    initResolutions = () => {
        let resolutions = [];
        (this.slideState.zoomLevels).forEach((level) => {
            resolutions.push(this.slideState.slide_data.uperpixel * Math.pow(2, parseInt(level)));
        });
        resolutions = resolutions.reverse();
        this.resolutions = resolutions;
    }

    initPreviewMap = () => {
        this.previewMap = new OverviewMap({
            className: "preview-map",
            layers: [
                new TileLayer({
                    source: new TileImage({
                        tileGrid: new TileGrid({
                            extent:  this.slideState.view.getProjection().getExtent(),
                            origin: [0,  this.slideState.view.getProjection().getExtent()[3]],
                            resolutions: this.resolutions,
                            tileSize: this.slideState.tileSize,
                        }),
                        projection:  this.slideState.view.getProjection(),
                        url: '/scan-hdd/'+this.slideState.slide_data.scandrive_id+'/morphle_test/'+this.slideState.slide_data.path+'stitched/{z}/x{x}y{y}.jpg',
                    })
                }),
            ],
            view: new View({
                projection: this.slideState.view.getProjection(),
                rotation: this.slideState.view.getRotation(),
                resolutions: this.slideState.view.getResolutions(),
            }),
            collapsible: false,
            collapsed: false
        });
    }

    initZoomVisitedMap = () => {
        this.zoomVisitedMap = {};
        this.zoomVisitedFullMap = {};

        this.slideState.zoomLevels.forEach((level) => {
            this.zoomVisitedMap[parseInt(level)] = [];
        });
    }

    initColorLayer = () => {
        this.colorLayer = getColorLayer(this.previewMap, this.slideState.view, this.zoomVisitedMap);
        this.previewMap.getOverviewMap().addLayer(this.colorLayer);
    }

    onVisitEvent = () => {
        if (this.state.previewColouring) {
            addToVisitedList(this.slideState.view, this.zoomVisitedFullMap, this.zoomVisitedMap);
            this.colorLayer.getSource().changed();
        }
    }

    mountPreviewMap = () => {
        this.previewMap.setTarget('preview-map-to-target');
        this.slideState.addPreviewMapControl(this.previewMap);
        this.setRotationToZero();
    }

    unmountPreviewMap = () => {
        let e = document.getElementById("preview-map-to-target");
        for (let previewMap = e.lastChild; previewMap; previewMap = e.lastChild)
            e.removeChild(previewMap)
    }

    preventTab = (e) => {
        if (e.keyCode === 9)
            e.preventDefault()
    }

    setRotationToZero = () => {
        if(this.state.previewColouring !== false){
            this.slideState.slidemap.getView().setRotation(0);
        }
    }

    componentDidMount() {
        this.unmountPreviewMap();
        this.mountPreviewMap();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.slideState = this.props.mapsState[this.props.activeMapId].slideState;
        let prevSlideState = prevProps.mapsState[this.props.activeMapId].slideState;
        if (!this.slideState || !prevSlideState) return;
        this.setRotationToZero();
        if (prevProps.activeMapId !== this.props.activeMapId || prevProps.lastMapCount !== this.props.lastMapCount) {
            this.unmountPreviewMap();
            this.init();
            this.mountPreviewMap();
        }
        else if (this.slideState.layer !== prevSlideState.layer) {
            this.initPreviewMap();
            this.slideState.addPreviewMapControl(this.previewMap);
        }
    }

    switchPreviewColoring = (value) => {
        this.setState({
            previewColouring: value
        })
        this.props.dispatch(showSeenAreaChanged());
        this.setRotationToZero();
    }

    render() {

        return(
            <Row className='preview-component' style={{width:'15.5vw', marginTop:'1.2vh'}}>
                <Col span={24}>
                    <Row>
                        <Col span={24} id={'preview-map-to-target'}>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row className='preview-tracking-component' style={{marginTop:'0.2vh'}}>
                        <Switch
                            className="preview-tracking-switch"
                            defaultChecked={false}
                            size="small"
                            checked={this.state.previewColouring}
                            onChange={this.switchPreviewColoring}
                        />
                        <span className="preview-tracking-text unselectable">Show Seen Area</span>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        lastMapCount: state.gammaStateReducer.lastMapCount,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(PreviewMap);
