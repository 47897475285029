import React from  "react";
import { createBox } from 'ol/interaction/Draw';
import LineString from 'ol/geom/LineString';
import DrawTool from './draw_tool';
import * as keys from './draw_tool_keys';

const getLineDrawingTool = (params, isActive) => {
    return <DrawTool
        key={keys.lineDrawingKey.name}
        toolKey={keys.lineDrawingKey}
        isActive = {isActive}
        toolParams={{
            maxPoints: 2,
        }}
        getGeometricParams={
            (geometry) => {
                let coordinates = geometry.getCoordinates();
                let perimeter = geometry.getLength();
                return {
                    area: 0,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content={keys.lineDrawingKey.icon}
        contentSelected = {keys.lineDrawingKey.icon}
    />;
}

const getRectangleDrawingTool = (params, isActive) => {
    return <DrawTool
        key={keys.rectangleDrawingKey.name}
        toolKey={keys.rectangleDrawingKey}
        isActive = {isActive}
        toolParams={{
            geometryFunction: createBox(),
        }}
        getGeometricParams={
            (geometry) => {
                let coordinates = geometry.getCoordinates();
                let area = geometry.getArea();
                let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
                return {
                    area,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content={keys.rectangleDrawingKey.icon}
        contentSelected = {keys.rectangleDrawingKey.icon}
    />;
}

const getCircleDrawingTool = (params, isActive) => {
    return <DrawTool
        key={keys.circleDrawingKey}
        toolKey={keys.circleDrawingKey}
        isActive = {isActive}
        getGeometricParams={
            (geometry) => {
                let area = geometry.getRadius() * geometry.getRadius() * Math.PI;
                let perimeter = 2 * geometry.getRadius() * Math.PI;
                let coordinates = [geometry.getCenter(), geometry.getRadius()];
                return {
                    area,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content={keys.circleDrawingKey.icon}
        contentSelected = {keys.circleDrawingKey.icon}
    />;
}

const getFreelineDrawingTool = (params, isActive) => {
    return <DrawTool
        key={keys.freelineDrawingKey}
        toolKey={keys.freelineDrawingKey}
        isActive = {isActive}
        toolParams={{
            freehand: true
        }}
        getGeometricParams={
            (geometry) => {
                let coordinates = geometry.getCoordinates();
                let perimeter = geometry.getLength();
                return {
                    area: 0,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content={keys.freelineDrawingKey.icon}
        contentSelected = {keys.freelineDrawingKey.icon}
    />;
}

const getFreehandDrawingTool = (params, isActive) => {
    return <DrawTool
        key={keys.freehandDrawingKey}
        toolKey={keys.freehandDrawingKey}
        isActive = {isActive}
        toolParams={{
            freehand: true
        }}
        getGeometricParams={
            (geometry) => {
                let coordinates = geometry.getCoordinates();
                let area = geometry.getArea();
                let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
                return {
                    area,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content={keys.freehandDrawingKey.icon}
        contentSelected = {keys.freehandDrawingKey.icon}
    />;
}

const getPolygonDrawingTool = (params, isActive) => {
    return <DrawTool
        key={keys.polygonDrawingKey}
        toolKey={keys.polygonDrawingKey}
        isActive = {isActive}
        getGeometricParams={
            (geometry) => {
                let coordinates = geometry.getCoordinates();
                let area = geometry.getArea();
                let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
                return {
                    area,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content={keys.polygonDrawingKey.icon}
        contentSelected={keys.polygonDrawingKey.icon}
    />;
}

const getMagicTool = (params, isActive) => {
    return <DrawTool
        key = {keys.magicToolDrawingKey}
        toolKey = {keys.magicToolDrawingKey}
        isActive = {isActive}
        toolParams={{
            maxPoints: 2,
        }}
        getGeometricParams={
            (geometry) => {
                let coordinates = geometry.getCoordinates();
                let perimeter = geometry.getLength();
                return {
                    area: 0,
                    perimeter,
                    coordinates
                }
            }
        }
        {...params}
        content = {keys.magicToolDrawingKey.icon}
        contentSelected = {keys.magicToolDrawingKey.icon}
    />
}

export const getDrawingTools = (params, keyList, activeKeyList) => {

    if (keyList === undefined) {
        keyList = [];
        for (let drawTool in keys) {
            keyList.push(keys[drawTool].name);
        }
    }

    let active_keys = []

    for (let i=0; i < activeKeyList.length; i++){
        active_keys.push(activeKeyList[i])
    }
    return keyList.map(key => {
        let active = active_keys.indexOf(key) !== -1;
        if ( key == null) {
            return;
        } else if (key === keys.lineDrawingKey.name) {
            return getLineDrawingTool(params, active);
        } else if (key === keys.freelineDrawingKey.name) {
            return getFreelineDrawingTool(params, active);
        } else if (key === keys.freehandDrawingKey.name) {
            return getFreehandDrawingTool(params, active);
        } else if (key === keys.circleDrawingKey.name) {
            return getCircleDrawingTool(params, active);
        } else if (key === keys.rectangleDrawingKey.name) {
            return getRectangleDrawingTool(params, active);
        } else if (key === keys.polygonDrawingKey.name) {
            return getPolygonDrawingTool(params, active);
        } else if (key === keys.magicToolDrawingKey.name) {
            return getMagicTool(params, active);
        }
    })
}
