import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Col, Row, Spin } from 'antd';

import { updateTileViewerUrlStateInReducer, updateMapParams, loadMorpheusSettings } from "../../action/tile.viewer.state.action"
import { getSlideAndInitialiseMapState, plotComb, mapMotorToCanvas, drawCircle, drawDynamicFocusPoints, plotRescanBlurInfo, plotFFTHeatmapNew, 
  plotFFTHeatmapNewRecolored, plotFftFourDUnit, plotFFTFourd, plotTXTY, plotTXTYText, plotFFTRatioFourd, plotFocusFourD, plotSparseFFTHeatmap,
   plotStitchInfo, plotRepairInfo, plotDropDistance, plotStitchDisagreements, plotStitchDeltas, plotStreaks, plotImageGrid, plotFocusPoints, plotFFTFourdText, 
  plotFFTFine, plotFFTFineText, plotFocusTriangles, plotFocusFilterTriangles, plotZHeatMaps, plotIslands, plotRescanIslands, imagetoCanvasPosHelper, 
  plotDownZHeatMaps, plotFFTRatioFourDText, getCountyKey, plotTiltFocusPoints, plotCountyTiltInfo, plotFocusProfile} from './tile_viewer_map_utils'
import {plotStitchPreRound0Disagreementsv2, plotStitchPostRound0Disagreementsv2,
  plotStitchPreRound1Disagreementsv2, plotStitchPreRound2Disagreementsv2, plotStitchPreRound3Disagreementsv2,
  plotStitchPostRound1Disagreementsv2, plotStitchPostRound2Disagreementsv2, plotStitchPostRound3Disagreementsv2,
  plotStitchRound0TileMotionv2, plotStitchRound1TileMotionv2, plotStitchRound2TileMotionv2, plotStitchRound3TileMotionv2, 
  plotStitchRound0Graphv2, plotStitchRound1Graphv2, plotStitchRound2Graphv2, plotStitchRound3Graphv2,
  plotStitchGraphv2, plotStitchGraphTextv2, plotStitchDisagreementsv2, plotStitchDisagreementsTextv2} from './stitching_plots'
import AppBoard from '../neoviewer/appboard'
import cookie from 'react-cookies';
import { getErrorComponent } from '../dashboard/slidelist_utils';
import { slideViewerType, tileViewerPlotColors, tileViewerPlotTypes } from '../../utils/const';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import FixedComponent from './tileViewerFixedComponent';
import ColorScheme from './colorScheme';

import logo from "../../asset/img/logo-mp.png"
import "../../asset/style/neoviewer/slidemap.css"

class SlideMap extends Component {

  constructor(props) {
    super(props);

    this.screenSizeString = window.screen.width + 'x' + window.screen.height;

    this.state = {
      slide: -1,
      isFetching: true,
      isErrored: false,
      loadedMap: false,
      showLoadingSign: false,
      mapId: this.props.map_id,
      imageCoordinate: [-1, -1],
      islandID: "none",
      overlayText: <div></div>,
      plotStatus: {
        path: false,
        blurs0: false,
        blurs1: false,
        txtyInfo: false,
        txtyTextInfo: false,
        fftFourD: false,
        fftFourDText: false,
        ratioFFTFourD: false,
        ratioFFTFourDText: false,
        focusFourD: false,
        FFTHeatmap: false,
        FFTRecolored: false,
        sparseFFTHeatmap: false,
        stitchInfo: false,
        repairInfo: false,
        dynamicFocus: false,
        dropDistance: false,
        stitchDisagreement: false,
        stitchDeltas: false,
        stitchDisagreementv2: false,
        stitchDisagreementTextv2: false,
        stitchPreRound0Disagreementv2: false,
        stitchPostRound0Disagreementv2: false,
        stitchRound0TileMotionv2: false,
        stitchRound0Graphv2: false,
        stitchPreRound1Disagreementv2: false,
        stitchPostRound1Disagreementv2: false,
        stitchRound1TileMotionv2: false,
        stitchRound1Graphv2: false,
        stitchPreRound2Disagreementv2: false,
        stitchPostRound2Disagreementv2: false,
        stitchRound2TileMotionv2: false,
        stitchRound2Graphv2: false,
        stitchPreRound3Disagreementv2: false,
        stitchPostRound3Disagreementv2: false,
        stitchRound3TileMotionv2: false,
        stitchRound3Graphv2: false,
        stitchGraphv2: false,
        stitchGraphTextv2: false,
        streak: true,
        focusProfile: false,
        fftFine: false,
        fftFineText: false,
        focusTriangles: false,
        focusFilterTriangles: false,
        plannedVsActualFocus: false,
        plannedVsGoldenFocus: false,
        plannedVsActualFocusDown: false,
        plannedVsGoldenFocusDown: false,
        islands: false,
        rescanIslands: false,
        tiltFocusPoints: false,
        countyTiltInfo: false
      },
      showGoToPositionButton: false,
      goToPositionVal: [],
    };

    this.previewMapRef = null;
    this.props.dispatch(loadMorpheusSettings());
    this.props.dispatch(updateTileViewerUrlStateInReducer(this.props.urlState));

    if (cookie.loadAll().isMobile == 'true') {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }

  changeSpinningState = (value) => {
    this.setState({
      showLoadingSign: value,
    });
  }

  addPreviewMapControl = (previewMapRef) => this.previewMapRef = previewMapRef;

  updateUrlState = () => {

    this.props.dispatch(updateMapParams(
      this.props.urlState,
      this.state.view.getCenter()[0],
      this.state.view.getCenter()[1],
      this.state.view.getZoom(),
      this.state.view.getRotation()
    ));
  };

  updateOverlayText = (event) => {
    if (!this.props.urlState.isDrawing) {
      let closer = document.getElementById('popup-closer');

      if (this.state.slidemap.hasFeatureAtPixel(event.pixel) === true) {
        let coordinate = event.coordinate;
        let features = this.state.slidemap.getFeaturesAtPixel(event.pixel);
        let overlayText = ""; 
        for (let i = 0; i < features.length; i++) {
          let feature = features[i];
          if (feature.getProperties().label != "") {
            overlayText = feature.getProperties().label;
          }
        }
        if (this.state.plotStatus.focusProfile && typeof overlayText == "string" && overlayText.includes("|")) {
          let vals = overlayText.split("|")[0].split("~");
          overlayText = <div>{overlayText.split("|")[1]}</div>;
          this.setState({
            showGoToPositionButton: true,
            goToPositionVal: vals,
          });
        } else if (typeof overlayText == "string") {
          overlayText = (
            <div>
              <div>{overlayText}</div>
              <br />
            </div>
          );
        }
        this.setState({
          overlayText, 
        });
        this.state.overlay.setPosition(coordinate);
      } else {
        this.state.overlay.setPosition(undefined);
        closer.blur();
      }
    }
  }

  onMouseMove = (e) => {

    let islandID = -1;
    let rescanIslandID = -1;

    if (this.state.islandData.length > 0 && this.state.islandData[0]["counties"] === undefined) {
      for(let i = 0; i < this.state.islandData.length; i++) {
          let rectStartPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.islandData[i]["islandBoundary"]["x"], 
           this.state.slide_data.y_fields - this.state.islandData[i]["islandBoundary"]["y"]);
          let rectEndPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
            this.state.islandData[i]["islandBoundary"]["x"] + this.state.islandData[i]["islandBoundary"]["width"], 
            this.state.slide_data.y_fields - this.state.islandData[i]["islandBoundary"]["y"] - this.state.islandData[i]["islandBoundary"]["height"]);
          let lowerBoundX = Math.min(rectStartPoint.x, rectEndPoint.x);
          let higherBoundX = Math.max(rectStartPoint.x, rectEndPoint.x);
          let lowerBoundY = Math.min(rectStartPoint.y, rectEndPoint.y);
          let higherBoundY = Math.max(rectStartPoint.y, rectEndPoint.y);
  
          if ((e.coordinate[0] >= lowerBoundX && e.coordinate[0] <= higherBoundX && 
            e.coordinate[1] >= lowerBoundY && e.coordinate[1] <= higherBoundY)){
            islandID = this.state.islandData[i]["id"];
            break;
          }
      }
    } else { // scan_ref change
      for(let i = 0; i < this.state.islandData.length; i++) {
        for (let idx = 0; idx < this.state.islandData[i]["counties"].length; idx++) {
          const countyData = this.state.islandData[i]["counties"][idx];
          const countyBoundary = this.state.islandData[i]["counties"][idx]["countyBoundary"];
          let rectStartPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
           this.state.slide_data.x_fields - countyBoundary["x"], 
           countyBoundary["y"]);
          // brain change
          rectStartPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
            countyBoundary["x"], 
            this.state.slide_data.y_fields - countyBoundary["y"]);
          console.log(imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
            this.state.slide_data.x_fields - countyBoundary["x"], 
            countyBoundary["y"]));
           // console
          console.log(rectStartPoint);
           let rectEndPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
            this.state.slide_data.x_fields - countyBoundary["x"] - countyBoundary["width"], 
            countyBoundary["y"] + countyBoundary["height"]);
          // brain change
          rectEndPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
            countyBoundary["x"] + countyBoundary["width"], 
            this.state.slide_data.y_fields - countyBoundary["y"] - countyBoundary["height"]);
          // console
          console.log(rectEndPoint);
          let lowerBoundX = Math.min(rectStartPoint.x, rectEndPoint.x);
          let higherBoundX = Math.max(rectStartPoint.x, rectEndPoint.x);
          let lowerBoundY = Math.min(rectStartPoint.y, rectEndPoint.y);
          let higherBoundY = Math.max(rectStartPoint.y, rectEndPoint.y);
  
          if ((e.coordinate[0] >= lowerBoundX && e.coordinate[0] <= higherBoundX && 
            e.coordinate[1] >= lowerBoundY && e.coordinate[1] <= higherBoundY)){
            islandID = getCountyKey(this.state.islandData[i]["islandKey"], this.state.islandData[i]["counties"][idx]["countykey"]["countyId"]);
            break;
          }
        }
      }
    }

    const rescanIslandsData = this.state.tileViewerSlideMeta.rescan_islands_meta;
    if (rescanIslandsData != undefined){
      for(let i = 0; i < rescanIslandsData.length; i++) {
        let rectStartPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, rescanIslandsData[i]["islandBoundary"]["x"], 
        this.state.slide_data.y_fields - rescanIslandsData[i]["islandBoundary"]["y"]);
        let rectEndPoint = imagetoCanvasPosHelper(this.state.tileViewerSlideMeta, this.state.slide_data, 
          rescanIslandsData[i]["islandBoundary"]["x"] + rescanIslandsData[i]["islandBoundary"]["width"], 
          this.state.slide_data.y_fields - rescanIslandsData[i]["islandBoundary"]["y"] - rescanIslandsData[i]["islandBoundary"]["height"]);
        let lowerBoundX = Math.min(rectStartPoint.x, rectEndPoint.x);
        let higherBoundX = Math.max(rectStartPoint.x, rectEndPoint.x);
        let lowerBoundY = Math.min(rectStartPoint.y, rectEndPoint.y);
        let higherBoundY = Math.max(rectStartPoint.y, rectEndPoint.y);

        if ((e.coordinate[0] >= lowerBoundX && e.coordinate[0] <= higherBoundX && 
          e.coordinate[1] >= lowerBoundY && e.coordinate[1] <= higherBoundY)){
          rescanIslandID = rescanIslandsData[i]["id"];
          break;
        }
      }
    }

    let xVal = Math.floor((e.coordinate[0]) / (this.state.slide_data.tile_width * this.state.slide_data.uperpixel));
    let yVal = this.state.slide_data.y_fields - Math.floor((e.coordinate[1]) / (this.state.slide_data.tile_height * this.state.slide_data.uperpixel)) - 1;
    this.setState({
      imageCoordinate: [xVal, yVal],
      islandID: islandID + " rescan:" + rescanIslandID,
    });
    
    // let closer = document.getElementById('popup-closer');
    // this.state.overlay.setPosition(undefined);
    // closer.blur();

    let XYMapPosition = "NA";

    if (xVal >= 0 && xVal < this.state.slide_data.x_fields && 
      yVal >= 0 && yVal < this.state.slide_data.y_fields) {
        XYMapPosition = "x" + xVal + "y" + yVal;
    }

    let XYMotorPosition = this.state.tileViewerSlideMeta.raw.motor_mappings[XYMapPosition + "." + this.state.slide_data.img_type] != undefined ?
                          this.state.tileViewerSlideMeta.raw.motor_mappings[XYMapPosition + "." + this.state.slide_data.img_type] : "NA";

    if (this.state.plotStatus.path && XYMotorPosition != "NA") {
      let pathPlotSource = new VectorSource({});
      this.state.pathPlotLayer.setSource(pathPlotSource);
      let Y = parseFloat(XYMotorPosition.split("_Y_")[1])
      let X = parseFloat(XYMotorPosition.split("_Y_")[0].split("X_")[1]);
      let combInfo = this.state.motorToCombMap[X + "_" + Y];

      let comb = this.state.islandData[combInfo.i].combList[combInfo.ci];
      plotComb(comb, this.state.slide_data, pathPlotSource, "#ffffff", this.state.tileViewerSlideMeta, combInfo.ci);

      let focusPoint = this.state.islandData[combInfo.i].focusList[combInfo.ci].positionsToTry[0];
      let focusCanvasPosition = mapMotorToCanvas(focusPoint.xPosition, focusPoint.yPosition, this.state.slide_data, this.state.tileViewerSlideMeta);
      drawCircle(focusCanvasPosition.x, focusCanvasPosition.y, 0.5 * this.state.slide_data.tile_height * this.state.slide_data.uperpixel, pathPlotSource, "", 2, tileViewerPlotColors.plannedFocusColor);
      this.setState({
        pathPlotSource,
      });
    }
  }

  initializePlots = () => {
    // drawDynamicFocusPoints(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.dynamicFocusPlotSource);
    plotRescanBlurInfo(this.state.slidemap, this.state.tileViewerSlideMeta, this.state.slide_data, this.state.projection, (obj) => {this.setState(obj)});
    // plotFFTHeatmapNew(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.FFTHeatmapPlotSource);
    // plotFFTHeatmapNewRecolored(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.FFTRecoloredPlotSource);
    //plotFFTFourd(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.fftFourDPlotSource);
    plotFFTFourdText(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.fftFourDTextPlotSource);
    plotTXTY(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.txtyInfoPlotSource);
    plotTXTYText(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.txtyTextInfoPlotSource);
    plotFFTRatioFourd(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.ratioFFTFourDPlotSource);
    plotFFTRatioFourDText(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.ratioFFTFourDTextPlotSource);
    // plotFocusFourD(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.focusFourDPlotSource);
    // plotSparseFFTHeatmap(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.sparseFFTHeatmapPlotSource);
    // plotStitchInfo(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchInfoPlotSource);
    // plotRepairInfo(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.repairInfoPlotSource);
    // plotDropDistance(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.plannedDropDistancePlotSource, this.state.actualDropDistancePlotSource);
    // plotStitchDisagreements(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchDisagreementPlotSource);
    // plotStitchDeltas(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchDeltasPlotSource);
    plotStreaks(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.streakPlotSource);
    plotImageGrid(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.focusProfilePlotSource);
    plotStitchDisagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchDisagreementv2PlotSource);
    plotStitchDisagreementsTextv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchDisagreementTextv2PlotSource);
    
    // Stitching: ROUND 0
    // plotStitchPreRound0Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPreRound0Disagreementv2PlotSource);
    // plotStitchPostRound0Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPostRound0Disagreementv2PlotSource);
    // plotStitchRound0TileMotionv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound0TileMotionv2PlotSource);
    // plotStitchRound0Graphv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound0Graphv2PlotSource);

    // // // Stitching: ROUND 1
    // plotStitchPreRound1Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPreRound1Disagreementv2PlotSource);
    // plotStitchPostRound1Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPostRound1Disagreementv2PlotSource);
    // plotStitchRound1TileMotionv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound1TileMotionv2PlotSource);
    // plotStitchRound1Graphv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound1Graphv2PlotSource);

    // // // Stitching: ROUND 2
    // plotStitchPreRound2Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPreRound2Disagreementv2PlotSource);
    // plotStitchPostRound2Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPostRound2Disagreementv2PlotSource);
    // plotStitchRound2TileMotionv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound2TileMotionv2PlotSource);
    // plotStitchRound2Graphv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound2Graphv2PlotSource);

    // // // Stitching: ROUND 3
    // plotStitchPreRound3Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPreRound3Disagreementv2PlotSource);
    // plotStitchPostRound3Disagreementsv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchPostRound3Disagreementv2PlotSource);
    plotStitchRound3TileMotionv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound3TileMotionv2PlotSource);
    plotStitchRound3Graphv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchRound3Graphv2PlotSource);

    plotStitchGraphv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchGraphv2PlotSource);
    plotStitchGraphTextv2(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.stitchGraphTextv2PlotSource);
    // plotStreaks(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.streakPlotSource);
    // plotFocusProfile(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.focusProfilePlotSource);
    plotFocusPoints(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.focusPointsPlotSource);
    //plotFFTFine(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.fftFinePlotSource);
    //plotFFTFineText(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.fftFineTextPlotSource);
    // plotFocusTriangles(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.focusTrianglesPlotSource);
    // plotFocusFilterTriangles(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.focusFilterTrianglesPlotSource);
    // plotZHeatMaps(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.plannedVsActualFocusPlotSource, this.state.plannedVsGoldenFocusPlotSource, (obj) => {this.setState(obj)});
    // plotDownZHeatMaps(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.plannedVsActualFocusDownPlotSource, this.state.plannedVsGoldenFocusDownPlotSource, (obj) => {this.setState(obj)});
    plotIslands(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.islandsPlotSource);
    plotRescanIslands(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.rescanIslandsPlotSource);
    plotTiltFocusPoints(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.tiltFocusPointsPlotSource);
    plotCountyTiltInfo(this.state.tileViewerSlideMeta, this.state.slide_data, this.state.countyTiltInfoPlotSource);
  }

  updatePlotStatus = (key) => {
    let plotStatus = Object.assign({}, this.state.plotStatus);
    plotStatus[key] = !plotStatus[key];

    // Update visibility of plots
    this.updatePathPlot(key, plotStatus);
    this.updateDynamicFocusPlot(key, plotStatus);
    this.updateBlurRescanPlot(key, plotStatus);
    this.updateFFTHeatmapPlot(key, plotStatus);
    this.updateFFTRecoloredPlot(key, plotStatus);
    this.updateFFTFourDPlot(key, plotStatus);
    this.updateFFTFourDTextPlot(key, plotStatus);
    this.updateTXTYPlot(key, plotStatus);
    this.updateTXTYTextPlot(key, plotStatus);
    this.updateRatioFFTFourDPlot(key, plotStatus);
    this.updateRatioFFTFourDTextPlot(key, plotStatus);
    this.updateFocusFourDPlot(key, plotStatus);
    this.updateSparseFFTHeatmapPlot(key, plotStatus);
    this.updateStitchInfoPlot(key, plotStatus);
    this.updateRepairInfoPlot(key, plotStatus);
    this.updateDropDistancePlot(key, plotStatus);
    this.updateStitchDisagreementPlot(key, plotStatus);
    this.updateStitchDeltasPlot(key, plotStatus);
    this.updateStitchDisagreementv2Plot(key, plotStatus);
    this.updateStitchDisagreementTextv2Plot(key, plotStatus);
    
    // Stitching: ROUND 0
    this.updateStitchPreRound0Disagreementv2Plot(key, plotStatus);
    this.updateStitchPostRound0Disagreementv2Plot(key, plotStatus);
    this.updateStitchRound0TileMotionv2Plot(key, plotStatus);
    this.updateStitchRound0Graphv2Plot(key, plotStatus);
    // Stitching: ROUND 1
    this.updateStitchPreRound1Disagreementv2Plot(key, plotStatus);
    this.updateStitchPostRound1Disagreementv2Plot(key, plotStatus);
    this.updateStitchRound1TileMotionv2Plot(key, plotStatus);
    this.updateStitchRound1Graphv2Plot(key, plotStatus);
    // Stitching: ROUND 2
    this.updateStitchPreRound2Disagreementv2Plot(key, plotStatus);
    this.updateStitchPostRound2Disagreementv2Plot(key, plotStatus);
    this.updateStitchRound2TileMotionv2Plot(key, plotStatus);
    this.updateStitchRound2Graphv2Plot(key, plotStatus);
    // Stitching: ROUND 3
    this.updateStitchPreRound3Disagreementv2Plot(key, plotStatus);
    this.updateStitchPostRound3Disagreementv2Plot(key, plotStatus);
    this.updateStitchRound3TileMotionv2Plot(key, plotStatus);
    this.updateStitchRound3Graphv2Plot(key, plotStatus);

    this.updateStitchGraphv2Plot(key, plotStatus);
    this.updateStitchGraphTextv2Plot(key, plotStatus);
    this.updateStreakPlot(key, plotStatus);
    this.updateFocusProfilePlot(key, plotStatus);
    this.updateFocusPointsPlot(key, plotStatus);
    this.updateFFTFinePlot(key, plotStatus);
    this.updateFFTFineTextPlot(key, plotStatus);
    this.updateFocusTrianglesPlot(key, plotStatus);
    this.updateFocusFilterTrianglesPlot(key, plotStatus);
    this.updatePlannedvsActualFocusPlot(key, plotStatus);
    this.updatePlannedvsGoldenFocusPlot(key, plotStatus);
    this.updatePlannedvsActualFocusDownPlot(key, plotStatus);
    this.updatePlannedvsGoldenFocusDownPlot(key, plotStatus);
    this.updateIslandsPlot(key, plotStatus);
    this.updateRescanIslandsPlot(key, plotStatus);
    this.updateTiltFocusPointsPlot(key, plotStatus);
    this.updateCountyTiltInfoPlot(key, plotStatus);
    
    this.setState({
      plotStatus,
    });
  }

  updatePathPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.PATH) {
      if (!plotStatus[key]) {
        let pathPlotSource = new VectorSource({});
        this.state.pathPlotLayer.setSource(pathPlotSource);
        this.setState({
          pathPlotSource,
        });
      }
    }
  }

  updateDynamicFocusPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.DYNAMIC_FOCUS) {
      if (plotStatus[key]) {
        this.state.dynamicFocusPlotLayer.setVisible(true);
      } else {
        this.state.dynamicFocusPlotLayer.setVisible(false);
      }
    }
  }

  updateBlurRescanPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.BLURS0 || key == tileViewerPlotTypes.BLURS1) {
      let layers = this.state.slidemap.getLayers().getArray();
      if (key == tileViewerPlotTypes.BLURS0) {
        if (plotStatus[key]) {
          layers.map((layer) => {
            for (let li = 0; li < this.state.rescanLayers[0].length; li++) {
                if (layer == this.state.rescanLayers[0][li]) {
                  layer.setVisible(true);
                }
            }
          });
          this.state.swapVectorLayer.setVisible(true);
        } else {
          layers.map((layer) => {
            for (let li = 0; li < this.state.rescanLayers[0].length; li++) {
                if (layer == this.state.rescanLayers[0][li]) {
                  layer.setVisible(false);
                }
            }
          });
          this.state.swapVectorLayer.setVisible(false);
        }
      } else if ((key == tileViewerPlotTypes.BLURS1)) {
        if (plotStatus[key]) {
          layers.map((layer) => {
            for (let li = 0; li < this.state.rescanLayers[1].length; li++) {
                if (layer == this.state.rescanLayers[1][li]) {
                  layer.setVisible(true);
                }
            }
          });
          this.state.swapVectorLayer.setVisible(true);
        } else {
          layers.map((layer) => {
            for (let li = 0; li < this.state.rescanLayers[1].length; li++) {
                if (layer == this.state.rescanLayers[1][li]) {
                  layer.setVisible(false);
                }
            }
          });
          this.state.swapVectorLayer.setVisible(false);
        }
      }
      
    }
  }

  updateFFTHeatmapPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FFT_HEAT_MAP) {
      if (plotStatus[key]) {
        this.state.FFTHeatmapPlotLayer.setVisible(true);
      } else {
        this.state.FFTHeatmapPlotLayer.setVisible(false);
      }
    }
  }

  updateFFTRecoloredPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FFT_RECOLORED) {
      if (plotStatus[key]) {
        this.state.FFTRecoloredPlotLayer.setVisible(true);
      } else {
        this.state.FFTRecoloredPlotLayer.setVisible(false);
      }
    }
  }

  updateFFTFourDPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FFT_FOURD) {
      if (plotStatus[key]) {
        this.state.fftFourDPlotLayer.setVisible(true);
      } else {
        this.state.fftFourDPlotLayer.setVisible(false);
      }
    }
  }

  updateFFTFourDTextPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FFT_FOURD_TEXT) {
      if (plotStatus[key]) {
        this.state.fftFourDTextPlotLayer.setVisible(true);
      } else {
        this.state.fftFourDTextPlotLayer.setVisible(false);
      }
    }
  }

  updateTXTYPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.TXTY_INFO) {
      if (plotStatus[key]) {
        this.state.txtyInfoPlotLayer.setVisible(true);
      } else {
        this.state.txtyInfoPlotLayer.setVisible(false);
      }
    }
  }

  updateTXTYTextPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.TXTY_TEXT_INFO) {
      if (plotStatus[key]) {
        this.state.txtyTextInfoPlotLayer.setVisible(true);
      } else {
        this.state.txtyTextInfoPlotLayer.setVisible(false);
      }
    }
  }

  updateRatioFFTFourDPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.RATIO_FFT_FOURD) {
      if (plotStatus[key]) {
        this.state.ratioFFTFourDPlotLayer.setVisible(true);
      } else {
        this.state.ratioFFTFourDPlotLayer.setVisible(false);
      }
    }
  }

  updateRatioFFTFourDTextPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.RATIO_FFT_FOURD_TEXT) {
      if (plotStatus[key]) {
        this.state.ratioFFTFourDTextPlotLayer.setVisible(true);
      } else {
        this.state.ratioFFTFourDTextPlotLayer.setVisible(false);
      }
    }
  }

  updateFocusFourDPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FOCUS_FOURD) {
      if (plotStatus[key]) {
        this.state.focusFourDPlotLayer.setVisible(true);
      } else {
        this.state.focusFourDPlotLayer.setVisible(false);
      }
    }
  }

  updateSparseFFTHeatmapPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.SPARSE_FFT_HEATMAP) {
      if (plotStatus[key]) {
        this.state.sparseFFTHeatmapPlotLayer.setVisible(true);
      } else {
        this.state.sparseFFTHeatmapPlotLayer.setVisible(false);
      }
    }
  }

  updateStitchInfoPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_INFO) {
      if (plotStatus[key]) {
        this.state.stitchInfoPlotLayer.setVisible(true);
      } else {
        this.state.stitchInfoPlotLayer.setVisible(false);
      }
    }
  }

  updateRepairInfoPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.REPAIR_INFO) {
      if (plotStatus[key]) {
        this.state.repairInfoPlotLayer.setVisible(true);
      } else {
        this.state.repairInfoPlotLayer.setVisible(false);
      }
    }
  }

  updateDropDistancePlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.DROP_DISTANCE) {
      if (plotStatus[key]) {
        this.state.plannedDropDistancePlotLayer.setVisible(true);
        this.state.actualDropDistancePlotLayer.setVisible(true);
      } else {
        this.state.plannedDropDistancePlotLayer.setVisible(false);
        this.state.actualDropDistancePlotLayer.setVisible(false);
      }
    }
  }

  updateStitchDisagreementPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_DISAGREEMENT) {
      if (plotStatus[key]) {
        this.state.stitchDisagreementPlotLayer.setVisible(true);
      } else {
        this.state.stitchDisagreementPlotLayer.setVisible(false);
      }
    }
  }

  updateStitchDeltasPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_DELTAS) {
      if (plotStatus[key]) {
        this.state.stitchDeltasPlotLayer.setVisible(true);
      } else {
        this.state.stitchDeltasPlotLayer.setVisible(false);
      }
    }
  }

  updateStitchDisagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchDisagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchDisagreementv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStitchDisagreementTextv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_DISAGREEMENT_TEXT_v2) {
      if (plotStatus[key]) {
        this.state.stitchDisagreementTextv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchDisagreementTextv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStitchPreRound0Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_PRE_ROUND0_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPreRound0Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPreRound0Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchPostRound0Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_POST_ROUND0_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPostRound0Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPostRound0Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound0TileMotionv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND0_TILEMOTION_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound0TileMotionv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound0TileMotionv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound0Graphv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND0_GRAPH_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound0Graphv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound0Graphv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStitchPreRound1Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_PRE_ROUND1_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPreRound1Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPreRound1Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchPostRound1Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_POST_ROUND1_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPostRound1Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPostRound1Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound1TileMotionv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND1_TILEMOTION_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound1TileMotionv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound1TileMotionv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound1Graphv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND1_GRAPH_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound1Graphv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound1Graphv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStitchPreRound2Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_PRE_ROUND2_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPreRound2Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPreRound2Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchPostRound2Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_POST_ROUND2_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPostRound2Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPostRound2Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound2TileMotionv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND2_TILEMOTION_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound2TileMotionv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound2TileMotionv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound2Graphv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND2_GRAPH_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound2Graphv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound2Graphv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStitchPreRound3Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_PRE_ROUND3_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPreRound3Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPreRound3Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchPostRound3Disagreementv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_POST_ROUND3_DISAGREEMENT_v2) {
      if (plotStatus[key]) {
        this.state.stitchPostRound3Disagreementv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchPostRound3Disagreementv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound3TileMotionv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND3_TILEMOTION_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound3TileMotionv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound3TileMotionv2PlotLayer.setVisible(false);
      }
    }
  }
  updateStitchRound3Graphv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_ROUND3_GRAPH_v2) {
      if (plotStatus[key]) {
        this.state.stitchRound3Graphv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchRound3Graphv2PlotLayer.setVisible(false);
      }
    }
  }


  updateStitchGraphv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_GRAPH_v2) {
      if (plotStatus[key]) {
        this.state.stitchGraphv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchGraphv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStitchGraphTextv2Plot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STITCH_GRAPH_TEXT_v2) {
      if (plotStatus[key]) {
        this.state.stitchGraphTextv2PlotLayer.setVisible(true);
      } else {
        this.state.stitchGraphTextv2PlotLayer.setVisible(false);
      }
    }
  }

  updateStreakPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.STREAK) {
      if (plotStatus[key]) {
        this.state.streakPlotLayer.setVisible(true);
      } else {
        this.state.streakPlotLayer.setVisible(false);
      }
    }
  }

  updateFocusProfilePlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FOCUS_PROFILE) {
      if (plotStatus[key]) {
        this.state.focusProfilePlotLayer.setVisible(true);
      } else {
        this.state.focusProfilePlotLayer.setVisible(false);
        this.setState({
          showGoToPositionButton: false,
        });
      }
    }
  }

  updateFocusPointsPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FOCUS_POINTS) {
      if (plotStatus[key]) {
        this.state.focusPointsPlotLayer.setVisible(true);
      } else {
        this.state.focusPointsPlotLayer.setVisible(false);
      }
    }
  }

  updateFFTFinePlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FFT_FINE) {
      if (plotStatus[key]) {
        this.state.fftFinePlotLayer.setVisible(true);
      } else {
        this.state.fftFinePlotLayer.setVisible(false);
      }
    }
  }

  updateFFTFineTextPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FFT_FINE_TEXT) {
      if (plotStatus[key]) {
        this.state.fftFineTextPlotLayer.setVisible(true);
      } else {
        this.state.fftFineTextPlotLayer.setVisible(false);
      }
    }
  }

  updateFocusTrianglesPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FOCUS_TRIANGLES) {
      if (plotStatus[key]) {
        this.state.focusTrianglesPlotLayer.setVisible(true);
        this.state.focusFilterTrianglesPlotLayer.setVisible(true);
      } else {
        this.state.focusTrianglesPlotLayer.setVisible(false);
        this.state.focusFilterTrianglesPlotLayer.setVisible(false);
      }
    }
  }

  updateFocusFilterTrianglesPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.FOCUS_FILTER_TRIANGLES) {
      if (plotStatus[key]) {
        this.state.focusFilterTrianglesPlotLayer.setVisible(true);
      } else {
        this.state.focusFilterTrianglesPlotLayer.setVisible(false);
      }
    }
  }

  updatePlannedvsActualFocusPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS) {
      if (plotStatus[key]) {
        this.state.plannedVsActualFocusPlotLayer.setVisible(true);
      } else {
        this.state.plannedVsActualFocusPlotLayer.setVisible(false);
      }
    }
  }

  updatePlannedvsGoldenFocusPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS) {
      if (plotStatus[key]) {
        this.state.plannedVsGoldenFocusPlotLayer.setVisible(true);
      } else {
        this.state.plannedVsGoldenFocusPlotLayer.setVisible(false);
      }
    }
  }

  updatePlannedvsActualFocusDownPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS_DOWN) {
      if (plotStatus[key]) {
        this.state.plannedVsActualFocusDownPlotLayer.setVisible(true);
      } else {
        this.state.plannedVsActualFocusDownPlotLayer.setVisible(false);
      }
    }
  }

  updatePlannedvsGoldenFocusDownPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS_DOWN) {
      if (plotStatus[key]) {
        this.state.plannedVsGoldenFocusDownPlotLayer.setVisible(true);
      } else {
        this.state.plannedVsGoldenFocusDownPlotLayer.setVisible(false);
      }
    }
  }

  updateIslandsPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.ISLANDS) {
      if (plotStatus[key]) {
        this.state.islandsPlotLayer.setVisible(true);
      } else {
        this.state.islandsPlotLayer.setVisible(false);
      }
    }
  }

  updateRescanIslandsPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.RESCAN_ISLANDS) {
      if (plotStatus[key]) {
        this.state.rescanIslandsPlotLayer.setVisible(true);
      } else {
        this.state.rescanIslandsPlotLayer.setVisible(false);
      }
    }
  }

  updateTiltFocusPointsPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.TILT_FOCUS_POINTS) {
      if (plotStatus[key]) {
        this.state.tiltFocusPointsPlotLayer.setVisible(true);
      } else {
        this.state.tiltFocusPointsPlotLayer.setVisible(false);
      }
    }
  }

  updateCountyTiltInfoPlot = (key, plotStatus) => {
    if (key == tileViewerPlotTypes.COUNTY_TILT_INFO) {
      if (plotStatus[key]) {
        this.state.countyTiltInfoPlotLayer.setVisible(true);
      } else {
        this.state.countyTiltInfoPlotLayer.setVisible(false);
      }
    }
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.urlState.apps_initialised != this.props.urlState.apps_initialised && !this.state.loadedMap) {
      getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
        obj.goHome = this.goHome;
        obj.addPreviewMapControl = this.addPreviewMapControl;
        this.setState(obj)
      }, this.props.urlState, this.updateUrlState, this.onMouseMove, this.updateOverlayText);
    }

    if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
      this.state.slidemap.setTarget("map-" + this.props.map_id);
      if (this.previewMapRef != undefined) {
        console.log("Preview Map Was Undefined!!!");
        this.setState({
          loadedMap: true
        })
        this.state.slidemap.addControl(this.previewMapRef);

        let container = document.getElementById('popup');
        let closer = document.getElementById('popup-closer');

        this.state.overlay.setElement(container);

        let self = this;
        closer.onclick = function() {
          self.state.overlay.setPosition(undefined);
          closer.blur();
          return false;
        };

        this.initializePlots();

      }
      if (this.props.urlState.x === -1) this.goHome();
    }
  }

  goHome = () => this.state.slidemap.getView().fit(this.state.layer.getExtent());

  render() {

    if (this.state.isErrored) {
      console.log(this.state.errMessage);
    }

    return (
      this.state.isFetching || this.state.isErrored ? <div>Loading</div> :
        isNaN(this.props.urlState.x) || isNaN(this.props.urlState.y) || isNaN(this.props.urlState.z) || isNaN(this.props.urlState.r) ?
          getErrorComponent() :
          <Spin spinning={this.state.showLoadingSign}>
            <div>
              <div className={(this.props.urlState || {}).presentCode != undefined ? "no-pointer-activity" : ""}>
                <Row className="slide-map-row">
                  <Col className="slide-map-col">
                    <Row>
                      <AppBoard slideState={this.state} viewerType={slideViewerType.TILE_SLIDE_VIEWER} 
                        updatePlotStatus={this.updatePlotStatus} changeSpinningState={this.changeSpinningState} />
                      <Col tabIndex="0" id={"map-" + this.props.map_id} className="slide-map-container">
                        {(this.props.urlState || {}).presentCode != undefined ? null : <img className="morphle-watermark" id="morphle-watermark" src={logo}></img>}
                      </Col>
                      <FixedComponent slideData={this.state.slide_data} tileViewerSlideMetaData={this.state.tileViewerSlideMeta}
                        imageCoordinate={this.state.imageCoordinate} plotStatus={this.state.plotStatus} islandID={this.state.islandID}
                        minPlannedvsActualFUpDiff={this.state.minPlannedvsActualFUpDiff} maxPlannedvsActualFUpDiff={this.state.maxPlannedvsActualFUpDiff} 
                        minPlannedvsBestFUpDiff={this.state.minPlannedvsBestFUpDiff} maxPlannedvsBestFUpDiff={this.state.maxPlannedvsBestFUpDiff}
                        minPlannedvsActualFDownDiff={this.state.minPlannedvsActualFDownDiff} maxPlannedvsActualFDownDiff={this.state.maxPlannedvsActualFDownDiff} 
                        minPlannedvsBestFDownDiff={this.state.minPlannedvsBestFDownDiff} maxPlannedvsBestFDownDiff={this.state.maxPlannedvsBestFDownDiff} />
                      <ColorScheme />
                      <div id="popup" className="ol-popup">
                        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                        <div id="popup-content" style={{color: 'black'}}>{this.state.overlayText}</div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
    );
  }
}

const mapStateToProps = state => {
  return {
    urlState: state.tileViewerUrlReducer,
  };
};

export default connect(mapStateToProps)(SlideMap);
