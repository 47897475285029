import React,{Component} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {connect} from "react-redux";
import {getAllSideBarBottomApps} from "./app_maker";
import {getTabComponent, getTabPanelComponent} from "./utils/side_bar_utils";
import {CircularProgress, Stack} from "@mui/material";
import {updateAppInstalledStatus} from "../../action/gamma.state.action";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    console.log("xx-4", value, index)
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 , overflowY:'scroll', height:'inherit'}}>
                    <Typography sx={{overflowY:'scroll', height:'inherit'}}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

class SideBarBottom extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentTab: -1,
            tabs: [],
            tabPanels: [],
        }
    }

    handleChange = (event, value) => {
        console.log("xx-change", value)
        if(value == this.state.currentTab){
            this.setState({
                currentTab: -1,
            })
            return;
        }
        this.setState({
            currentTab: value,
        })
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.triggers.top_bar_app_open== false && this.props.triggers.top_bar_app_open== true && this.state.currentTab==-1){
            this.setState({
                currentTab: 0,
            })
        }
    }

    getTabsAndTabPanels = (gammaState) => {
        let app_list = getAllSideBarBottomApps(gammaState);
        if(!app_list){
            return [[],[]]
        }
        let tabs = [];
        let tabPanels = [];
        for(let i=0; i<app_list.length; i++){
            let app = app_list[i];
            if (app.permission === undefined)
                this.props.dispatch(updateAppInstalledStatus(app.id, app.defaultPermission));
            if (!app.permission)
                continue;
            tabs.push(getTabComponent(i, app, this.handleChange));
            tabPanels.push(getTabPanelComponent(i, app, this.state.currentTab));
        }
        return [tabs, tabPanels];
    }

    render() {
        let [tabs, tabPanels] = this.getTabsAndTabPanels(this.props.gammaState);
        return (
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                    height:'24vh',
                }}
            >
                <Stack direction={"column-reverse"}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.currentTab}
                        onChange={this.handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: "divider",  '.MuiTabs-indicator': {
                                bottom: 0,
                            },}}
                    >
                        {tabs}
                    </Tabs>
                </Stack>
                {tabPanels}
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        triggers: state.triggerReducer,
    }
}

export default connect(mapStateToProps)(SideBarBottom);
