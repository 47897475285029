import React, {Component} from "react";
import {
	Autocomplete,
	Button,
	Chip,
	Grid,
	Popover,
	Stack,
	Tab,
	Tabs,
	TextField,
	IconButton,
	Tooltip
} from "@mui/material";
import {connect} from "react-redux";
import {BorderVertical, Sync} from "@mui/icons-material";
import {initSlides} from "../../../action/maps.state.action";
import {updateSync} from "../../../action/gamma.state.action";
import {morphleBaseTheme1} from "../themes";
import {typeAheadSlideSearch} from "../utils/typeaheads";
import {updateActiveMap, updateLastMapCount} from "../../../action/gamma.state.action";

class SplitSync extends Component {

	constructor(props) {
		super(props);

		let slides = Object.keys(this.props.mapsState).map(mapId => ({
			id: this.props.mapsState[mapId].slideState.slide_data.id,
			name: this.props.mapsState[mapId].slideState.slide_data.name,
		}));

		this.permanentSlides = slides.slice(0, 1);

		this.state = {
			anchorEl : null,
			value: null,
			selectedSlides: slides,
			options: [],
		}

		if (this.props.sync) this.saveMapsPosition();
	}

	handleTabChange = (event, value) => {
		event.stopPropagation();
		this.setState({
			anchorEl: event.currentTarget,
			value: value,
		});
	}

	handleTabClose = () => {
		this.setState({
			anchorEl : null,
			value: null
		});
	}

	mapSlides = () => {
		this.props.dispatch(updateActiveMap('0'));
		this.props.dispatch(initSlides(this.state.selectedSlides));
		this.props.dispatch(updateLastMapCount());
	}

	resetMaps = () => {
		this.setState({
			selectedSlides: this.permanentSlides,
		}, this.mapSlides);
	}

	toggleSync = () => {
		if (Object.keys(this.props.mapsState).length < 2) return;
		if (!this.props.sync)
			this.saveMapsPosition();
		this.props.dispatch(updateSync(!this.props.sync));
		this.setState({});
	}

	// reducer variables are directly modified instead of dispatching,
	// since we don't need any state change for older positions, no need to dispatch, reduce lags wherever possible
	saveMapsPosition = () => {
		let mapsState = this.props.mapsState;
		for (let map_id in mapsState) {
			let mapState = mapsState[map_id];
			// saving current state to use in sync mode, not changing the object pointer as not dispatching
			mapState.X = mapState.x;
			mapState.Y = mapState.y;
			mapState.Z = mapState.z;
			mapState.R = mapState.r;
		}
	}

	render() {

		return (
			<>
				<Tabs value={this.state.value} onChange={this.handleTabChange} scrollButtons="auto"
				      aria-label="scrollable auto tabs example">
					<Tooltip title={"Split"} placement={"bottom"}>
						<Tab icon={<BorderVertical />} />
					</Tooltip>
					<Tooltip title={"Sync"} placement={"bottom"}>
						<IconButton onClick={this.toggleSync} sx={{verticalAlign:'middle'}}>
							{this.props.sync ?
								<Sync sx={{color : morphleBaseTheme1.palette.headBarIcon.secondary}}/> :
								<Sync sx={{color : morphleBaseTheme1.palette.headBarIcon.primary}}/>}
						</IconButton>
					</Tooltip>
				</Tabs>
				<Popover open={this.state.value !== null} anchorEl={this.state.anchorEl}
				         anchorOrigin={{
					         vertical: 'bottom',
					         horizontal: 'left',
				         }}
				         onClose={this.handleTabClose}>
					<div>
						<Grid container alignItems={"center"} direction={"column"}>
							<Autocomplete
								multiple
								sx={{width:'10vw'}}
								defaultValue={this.permanentSlides}
								value={this.state.selectedSlides}
								onChange={(event, value) =>
									this.setState({
										selectedSlides: [...this.permanentSlides,
											...value.filter(slide => this.permanentSlides.indexOf(slide) === -1)],
									})
								}
								onInputChange={(e, v) =>
									typeAheadSlideSearch(v, (options) => this.setState({
										options: options
									}))
								}
								renderInput={(params) =>
									<TextField {...params} variant="outlined" placeholder="Slide" sx={{padding:0}} />}
								renderTags={(slides, getSlideProps) =>
									slides.map((slide, index) =>
										<Chip label={slide.name} sx={{width: 200, justifyContent: 'left'}}
										      {...getSlideProps({index})}
										      disabled={this.permanentSlides.indexOf(slide) !== -1} />
									)
								}
 								options={this.state.options}
								getOptionLabel={option => option.name}
								/>
							<Stack direction={"row"} spacing={1} marginBottom={1}>
								<Button disabled={this.props.sync} color={"secondary"}
								        onClick={this.mapSlides}>Map Slides</Button>
								<Button disabled={Object.keys(this.props.mapsState).length < 2 ||
									this.props.sync} color={"secondary"}
								        onClick={this.resetMaps}>Reset</Button>
							</Stack>
						</Grid>
					</div>
				</Popover>
			</>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		mapsState: state.mapsStateReducer,
		sync: state.gammaStateReducer.sync,
		splitLock: state.gammaStateReducer.splitLock,
	}
}

export default connect(mapStateToProps)(SplitSync)
