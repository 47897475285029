import {TriggersActions} from "../actionTypes/triggers.constant";

const InitialState = {
    appId_open: '',
    annotation_app_anno_id_selected: '',
    top_bar_app_open:false,
}

export const triggerReducer = (state=InitialState, action) => {
    switch (action.type){
        case TriggersActions.OPEN_APP_SIDEBAR_TOP:
            return {
                ...state,
                appId_open: action.response,
            }

        case TriggersActions.OPEN_ANNOTATION_APP_ANNOTATION:
            return {
                ...state,
                annotation_app_anno_id_selected: action.response,
            }

        case TriggersActions.UPDATE_TOP_BAR_APP_OPEN:
            return {
                ...state,
                top_bar_app_open: action.response,
            }

        default:
            return state
    }
}