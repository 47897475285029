import React, {Component} from 'react';
import {ScanOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Input, message, Row, Spin, Tabs} from 'antd';
import "../../asset/style/scanner/scanner.css"
import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant"
import { connect } from "react-redux";
import { takeAllPreviews, setDoTilingOnly, unsetDoTilingOnly, setDoTilingAfterScan, unsetDoTilingAfterScan, selectionDone, selectionCancelled, 
	selectionConfirmed, setScanSpeed, setScanName, initPreviewForScan, takePreview, setSpecimenType, setObjectiveType, setScanOutputFormat, 
	setScanZLevels, setScanZStepSize, getDefaultConfig, updatePreviewStatus, dropDistanceConfirmed, onDeleteFocusPoint, setPreviewStatusToConfirmed, 
	setDropDistancePoint, setFocusPoints, receivedPreviewStatus, setDropDistancePointLength, setDropDistancePointOnly, fetchingPreview, updateActionOngoing } from '../../action/preview_status.action';
import { getSpecimenChooser, getAdminPanel, getBrushPanel, getButtonsPanel, getCanvasComponent, getConfirmedPanel, getBlankSlidePanel, updateImages, getSpeedPanel, cleanUpExtra, getObjectiveChooser, getScanOutputFormatChanger, getZStackConfigPanel, drawCrossHairInitial, getCanvasComponentNew } from "./slot_component_utils"
import axios from 'axios';
import {brushType, scanSettingsTypeVals, scanWorkflowLoadingMessages} from "../../utils/const";
import cookie from "react-cookies";
import GeneralPreviewSettingsComp from './scanWorkflow/generalPreviewSettingsComponent';
import AdvancedPreviewSettingsComp from './scanWorkflow/advancedPreviewSettingsComponent';
import {setDeviceBusy, setDeviceIdle} from '../../action/device_status.action';
import {ScanApiConstant} from '../../actionTypes/scan.constant';
import {scanSpeed, specimenType} from './slots';
import { AuthHeader } from "../../helper/auth.token";

import querystring from "query-string";

const {Search} = Input;
const {TabPane} = Tabs;

const initState = (id, previewStatus, initRegions) => ({
	regions: initRegions,
	selectedSlot: id,
	previewStatus: previewStatus,
    usingBrush: true,
    brushType: brushType.REDRAW,
	regionArea: 0,
	brushSize: 15,
	slide_name: null,
	reading_barcode: false,
	overrideBlank: false,
	scanSettingsType: scanSettingsTypeVals.GENERAL,
	scanPathSet: false,
	currResponse: {},
	cacheBusterTime: (new Date()).getTime(),
	brightnessInputValue: 50,
	contrastInputValue: 50,
	exposureInputValue: -1,
	gammaInputValue: -1,
	exposureMaxValue: 50,
	gammaMaxValue: 1,
	exposureMinValue: 10,
	gammaMinValue: 0.1,
})

class SlotComponent extends Component {

	constructor(props) {
		super(props);
		let region = this.props.preview.region == undefined ? undefined : [this.props.preview.region];
		// if (this.props.cassetteSize == 1 || this.props.cassetteSize == 2) {
		region = [{
			data: { index: 0 },
			height: 90,
			isChanging: false,
			new: false,
			width: 67,
			x: 30,
			y: 5,
		}];
		// }
		this.state = initState(this.props.id, undefined, region);
		this.paint = false;
	}


    exposureValueChange = (value) => {
		console.error(value);
        if(value<this.state.exposureMinValue){
            this.setState({
                exposureInputValue: this.state.exposureMinValue
            });
            return;
        }
        if(value>this.state.exposureMaxValue){
            this.setState({
                exposureInputValue: this.state.exposureMaxValue
            });
            return;
        }
        this.setState({
            exposureInputValue: value
        });
    }

    gammaValueChange = (value) => {
		console.error(value);
        if(value<this.state.gammaMinValue){
            this.setState({
                gammaInputValue: this.state.gammaMinValue
            });
            return;
        }
        if(value>this.state.gammaMaxValue){
            this.setState({
                gammaInputValue: this.state.gammaMaxValue
            });
            return;
        }
        this.setState({
            gammaInputValue: value
        });
    }

    brightnessValueChange = (value) => {
		console.error(value);
        if(value<0){
            this.setState({
                brightnessInputValue: 0
            });
            return;
        }
        if(value>100){
            this.setState({
                brightnessInputValue: 100
            });
            return;
        }
        this.setState({
            brightnessInputValue: value
        });
    }

    contrastValueChange = (value) => {
		console.error(value);
        if(value<1){
            this.setState({
                contrastInputValue: 1
            });
            return;
        }
        if(value>100){
            this.setState({
                contrastInputValue: 100
            });
            return;
        }
        this.setState({
            contrastInputValue: value
        });
    }

	setValues = (minExp, maxExp, minGamma, maxGamma, brightness, contrast, exposure, gamma) => {
		this.setState({
			exposureMinValue: minExp,
			exposureMaxValue: maxExp,
			gammaMinValue: minGamma,
			gammaMaxValue: maxGamma,
			brightnessInputValue: brightness,
			contrastInputValue: contrast,
			exposureInputValue: exposure,
			gammaInputValue: gamma
		})
	}

    setBrightnessAndContrast = () => {
        let partsOfUrl = "api~scan~set_advance_mode_configs"
        let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?slot_id=" + this.props.id + "&bri=" + (this.state.brightnessInputValue) + "&con=" + (this.state.contrastInputValue) + "&exp=" + (this.state.exposureInputValue) + "&gamma=" + (this.state.gammaInputValue);
        console.error(url)
		axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                // if (response.status === 200) {
                //     message.success("Sent Successfully!!!")
                // }
            }
            )
            .catch(error => {
				console.error(error)
            })
    }

	onCanvasUpdate = (regions) => {
		this.setState({
			regions: regions
		});
		let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.id]
		let url = `/server/devices/${this.props.device_id}/get_area_mm_from_bounding_box/`;
		let reg = regions[0];
		if (reg !== undefined) {
			let previewHeight = ((slot_status||{}).previewResponse||{}).previewHeight
        	let previewWidth = ((slot_status||{}).previewResponse||{}).previewWidth
			var x1 = parseInt((reg.x*previewWidth) / 100.0);
			var x2 = parseInt(((reg.x + reg.width) * previewWidth) / 100.0);
			var y1 = parseInt((reg.y * previewHeight) / 100.0);
			var y2 = parseInt(((reg.y + reg.height) * previewHeight) / 100.0);
			var data = {
				'slot_id': this.state.selectedSlot, 
				'top_x': x1, 
				'top_y': y1, 
				'bottom_x': x2, 
				'bottom_y': y2
			}
			axios.post(url, querystring.stringify(data)).then(res => {
				this.setState({
					regionArea: res.data.toFixed(0)
				});
			})
			.catch(err => {
					console.log(err);
			});
		}
	}

	componentDidMount = () => {
		let newStatus = (this.props.preview || {}).status;
		if (this.state.selectedSlot !== this.props.id) {
			if (newStatus === RegionSelectionConstants.SELECTION_DONE) {
				this.props.dispatch(selectionCancelled(this.props.id));
			}
			let newRegions = newStatus === RegionSelectionConstants.CONFIRMED ? [this.props.preview.region] : undefined;
			this.setState(Object.assign({}, this.state, initState(this.props.id, undefined, newRegions)));
		} else if (this.state.previewStatus !== newStatus && this.previewCanvas != null) {
			if (newStatus === RegionSelectionConstants.FETCHED_IMAGE || 
				newStatus === RegionSelectionConstants.SELECTION_DONE || 
				newStatus === RegionSelectionConstants.CONFIRMED) {
				let cache_buster_time = this.state.cacheBusterTime;
				let defaultMaskLocation = this.props.preview.response.defaultMaskLocation + "?d=" + cache_buster_time;
				if ((this.props.preview || {}).specimenType == specimenType.HNE_TISSUE) {
					defaultMaskLocation = this.props.preview.response.defaultHneMaskLocation + "?d=" + cache_buster_time;
				} else if ((this.props.preview || {}).specimenType == specimenType.IHC) {
					defaultMaskLocation = this.props.preview.response.defaultIhcMaskLocation + "?d=" + cache_buster_time;
				} else if ((this.props.preview || {}).specimenType == specimenType.LBC) {
					defaultMaskLocation = this.props.preview.response.defaultPapMaskLocation + "?d=" + cache_buster_time;
				}
				updateImages(this.previewCanvas, this.props.preview.response.uploadLocation, 
					this.thresholdCanvas, defaultMaskLocation, this.state.regions);
			}
			this.setState(Object.assign({}, this.state, {previewStatus : newStatus}));
		}
		this.selectFullRegion();
		this.onCanvasUpdate(this.state.regions);
	}

	componentDidUpdate(prevProps, prevState) {
        this.updatePreviewBox(prevState);
        
        if (prevProps.preview.status != this.props.preview.status && this.props.preview.status == RegionSelectionConstants.FETCHED_IMAGE) {
			this.selectFullRegion();
		}

		if (prevProps.preview.status != this.props.preview.status && this.props.preview.status == RegionSelectionConstants.START_DROP_DISTANCE_SELECTION) {
			if (this.props.preview.dropDistancePoint != undefined) {
                drawCrossHairInitial(this.props.preview.dropDistancePoint[0].x, this.props.preview.dropDistancePoint[0].y, this.dropDistanceCanvas,
                    {clearCanvas: true, color: 'red', size: 10, width: 1}, this.props.preview);
            }
		}

		if (prevProps.preview.status != this.props.preview.status && this.props.preview.status == RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			let pointDrawn = false;
			for (let island in this.props.preview.focusPoints) {
                for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
                    let focusUnit = this.props.preview.focusPoints[island][i];
                    if (focusUnit.length > 0) {
                        drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, this.focusPointsCanvas,
							{clearCanvas: !pointDrawn, color: 'green', size: 5, width: 1}, this.props.preview);
						pointDrawn = true;
                    }
                }
            }
		}
	}

	updatePreviewBox = (prevState) => {
		let newStatus = (this.props.preview || {}).status;
		if (this.state.selectedSlot !== this.props.id) {
			if (newStatus === RegionSelectionConstants.SELECTION_DONE) {
				this.props.dispatch(selectionCancelled(this.props.id));
			}
			let newRegions = newStatus === RegionSelectionConstants.CONFIRMED ? [this.props.preview.region] : undefined;
			this.setState(Object.assign({}, this.state, initState(this.props.id, undefined, newRegions)));
		} 
		else if ((this.state.previewStatus !== newStatus || 
			(this.state.brushType !== prevState.brushType && 
				!(prevState.brushType == brushType.BRUSH && this.state.brushType == brushType.ERASER) &&
				!(prevState.brushType == brushType.ERASER && this.state.brushType == brushType.BRUSH)) || 
			this.state.regions !== prevState.regions) && this.previewCanvas != null) {
			if (newStatus === RegionSelectionConstants.FETCHED_IMAGE || 
				newStatus === RegionSelectionConstants.SELECTION_DONE || 
				newStatus === RegionSelectionConstants.CONFIRMED) {
				let cache_buster_time = this.state.cacheBusterTime;
				let defaultMaskLocation = this.props.preview.response.defaultMaskLocation + "?d=" + cache_buster_time;
				if ((this.props.preview || {}).specimenType == specimenType.HNE_TISSUE) {
					defaultMaskLocation = this.props.preview.response.defaultHneMaskLocation + "?d=" + cache_buster_time;
				} else if ((this.props.preview || {}).specimenType == specimenType.IHC) {
					defaultMaskLocation = this.props.preview.response.defaultIhcMaskLocation + "?d=" + cache_buster_time;
				} else if ((this.props.preview || {}).specimenType == specimenType.LBC) {
					defaultMaskLocation = this.props.preview.response.defaultPapMaskLocation + "?d=" + cache_buster_time;
				}
				this.setState({
					scanPathSet: false,
				});
				updateImages(this.previewCanvas, this.props.preview.response.uploadLocation + "?d=" + cache_buster_time,
					this.thresholdCanvas, defaultMaskLocation, this.state.regions);
			}
			this.setState({
				previewStatus : newStatus,
			});
			// this.setState(Object.assign({}, this.state, {previewStatus : newStatus}));
		}
	}

	confirmClicked = () => {
		if (this.props.preview.status === RegionSelectionConstants.FETCHED_IMAGE) {
			this.props.dispatch(selectionDone(this.props.id));
		} else if (this.props.preview.status === RegionSelectionConstants.SELECTION_DONE) {
			let imageURLData = this.thresholdCanvas.toDataURL("image/jpeg");
			let reg = this.state.regions[0];
			let x1 = parseInt((reg.x * this.props.preview.response.previewWidth) / 100.0);
			let x2 = parseInt(((reg.x + reg.width) * this.props.preview.response.previewWidth) / 100.0);
			let y1 = parseInt((reg.y * this.props.preview.response.previewHeight) / 100.0);
			let y2 = parseInt(((reg.y + reg.height) * this.props.preview.response.previewHeight) / 100.0);
			let actualReg = [x1, y1, x2, y2]

			let thresholdCanvas = this.thresholdCanvas;
			let x1_box = parseInt((reg.x * thresholdCanvas.width) / 100.0);
			let x2_box = parseInt(((reg.x + reg.width) * thresholdCanvas.width) / 100.0);
			let y1_box = parseInt((reg.y * thresholdCanvas.height) / 100.0);
			let y2_box = parseInt(((reg.y + reg.height) * thresholdCanvas.height) / 100.0);
			let green_box_region = [x1_box,y1_box, x2_box , y2_box];

			this.props.dispatch(selectionConfirmed(this.props.device_id, this.props.id, reg, actualReg, 
				imageURLData, this.props.preview.slideName, this.props.startScanning, this.props.cassetteSize, this.props.preview.specimenType, 
				this.props.preview.objectiveType, this.props.preview, this.dropDistanceCanvas,green_box_region));
		} else if (this.props.preview.status === RegionSelectionConstants.START_DROP_DISTANCE_SELECTION) {
			this.props.dispatch(dropDistanceConfirmed(this.props.device_id, this.props.id, this.props.preview, this.focusPointsCanvas));
		} else if (this.props.preview.status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			this.props.dispatch(updatePreviewStatus(this.props.id, RegionSelectionConstants.CONFIRMED));
		}
	}

    confirmPreviewSettingChanges = () => {
		this.setBrightnessAndContrast();
		if (!this.state.scanPathSet) {
			let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
			let imageURLData = this.thresholdCanvas.toDataURL("image/jpeg");
			let reg = this.state.regions[0];
			let x1 = parseInt((reg.x * this.props.preview.response.previewWidth) / 100.0);
			let x2 = parseInt(((reg.x + reg.width) * this.props.preview.response.previewWidth) / 100.0);
			let y1 = parseInt((reg.y * this.props.preview.response.previewHeight) / 100.0);
			let y2 = parseInt(((reg.y + reg.height) * this.props.preview.response.previewHeight) / 100.0);
			let actualReg = [x1, y1, x2, y2];

			let thresholdCanvas = this.thresholdCanvas;
			let x1_box = parseInt((reg.x * thresholdCanvas.width) / 100.0);
			let x2_box = parseInt(((reg.x + reg.width) * thresholdCanvas.width) / 100.0);
			let y1_box = parseInt((reg.y * thresholdCanvas.height) / 100.0);
			let y2_box = parseInt(((reg.y + reg.height) * thresholdCanvas.height) / 100.0);
			let green_box_region = [x1_box,y1_box, x2_box , y2_box];

			this.props.dispatch(setDeviceBusy(this.props.device_id));
			this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.CALCULATE_FOCUS_POINTS));
			let url = '/server/devices/' + this.props.device_id + "/send_threshold_image/?slot_id=" + this.props.id;
			axios({
				method:"post",
				url : url, 
				data: {
					imgBase64: imageURLData, 
					specimenType: this.props.preview.specimenType,
					objectiveType: this.props.preview.objectiveType,
					actualRegion: actualReg,
					previewData: this.props.preview,
					greenBoxRegion : green_box_region,
				}
			})
			.then(response => {
				if (response.status === 200) {
					this.props.dispatch(setPreviewStatusToConfirmed(this.props.id, reg, actualReg));
					this.props.dispatch(setDropDistancePointOnly(this.props.id, response.data.dropDistancePoint));
					this.props.dispatch(setDropDistancePointLength(this.props.id, response.data.dropDistancePoint));
					this.props.dispatch(setFocusPoints(this.props.id, response.data.focusPoints));
					this.props.dispatch(updatePreviewStatus(this.props.id, RegionSelectionConstants.CONFIRMED));
					this.setState({
						scanPathSet: true,
						currResponse: response,
					});
					
				}
				else {
					this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, this.props.id))
					message.error("Not able to confirm preview settings. Please contact admin.", 2.5);
				}
				this.props.dispatch(setDeviceIdle(this.props.device_id));
				this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
			})
			.then(response => {
					// if (!(this.props.cassetteSize === 1 || this.props.cassetteSize === 2)) {
						console.log("closeExpadedSlideView")
						this.props.closeExpadedSlideView();}
					// }
			)
			.catch(err => {
				this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, this.props.id))
				this.props.dispatch(setDeviceIdle(this.props.device_id));
				this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
				message.error("Not able to confirm preview settings. Please contact admin.", 2.5);
			})
			
		} else {
			this.props.dispatch(updatePreviewStatus(this.props.id, RegionSelectionConstants.CONFIRMED));
			if (!(this.props.cassetteSize === 1 || this.props.cassetteSize === 2)) {
				this.props.closeExpadedSlideView();
			}
		}
	}

	onRetakePreview = () => {
		this.clearSelection();
		this.props.dispatch(getDefaultConfig(this.props.device_id, this.props.id));
		this.props.dispatch(takePreview(this.props.device_id, this.props.id));
		this.readbarcode();
	}

	clearSelection = () => {
		this.setState(Object.assign({}, this.state, {
			regions: undefined,
			slide_name: null,
			reading_barcode: false
		}));
		this.props.dispatch(selectionCancelled(this.props.id));
	}

	overrideBlankSlide = () => {
		this.clearSelection();
		this.setState(Object.assign({}, this.state, {
			overrideBlank: true
		}));
	}

	paintCell = (x, y) => {
		const thresholdContext = this.thresholdCanvas.getContext('2d');
		var cellWidth = (parseInt(this.state.brushSize / 2)) * 2;
		thresholdContext.clearRect(x - cellWidth / 2, y - cellWidth / 2, cellWidth, cellWidth);
		// thresholdContext.fillStyle = this.state.usingBrush ? "#FFFFFF" : "#000000";
		thresholdContext.fillStyle = this.state.brushType == brushType.BRUSH ? "#FFFFFF" : "#000000";
		thresholdContext.fillRect(x - cellWidth / 2, y - cellWidth / 2, cellWidth, cellWidth);
	}

	showBrushOrEraserSize = (event) => {
		const cnvs = this.brushEraserPrevCanvas;
		if(cnvs != null) {
			const cnvsCtx = cnvs.getContext('2d');
			cnvsCtx.clearRect(0, 0, cnvs.width, cnvs.height);
			var rect = cnvs.getBoundingClientRect();
			var canvasx = rect.x;
			var canvasy = rect.y;
			var mousex = parseInt(((event.pageX - canvasx) / rect.width) * cnvs.width);
			var mousey = parseInt(((event.pageY - canvasy) / rect.height) * cnvs.height);
			var cellWidth = (parseInt(this.state.brushSize / 2)) * 2;
			cnvsCtx.clearRect(mousex - cellWidth / 2, mousey - cellWidth / 2, cellWidth, cellWidth);
			cnvsCtx.fillStyle = this.state.brushType == brushType.BRUSH ? "rgba(20,200,20,0.15)" : "rgba(200,20,20,0.15)";
			cnvsCtx.fillRect(mousex - cellWidth / 2, mousey - cellWidth / 2, cellWidth, cellWidth);
			cnvsCtx.lineWidth = 1;
			cnvsCtx.strokeStyle = this.state.brushType == brushType.BRUSH ? "#22ee22" : "#EE2222";
			cnvsCtx.strokeRect(mousex - cellWidth / 2, mousey - cellWidth / 2, cellWidth, cellWidth);
		} else {
			console.warn("brush eraser preview canvas is null");
		}
	}

	paintCanvas = (event) => {
		if (this.paint) {
			var rect = this.thresholdCanvas.getBoundingClientRect();
			var canvasx = rect.x;
			var canvasy = rect.y;
			var mousex = parseInt(((event.pageX - canvasx) / rect.width) * this.thresholdCanvas.width);
			var mousey = parseInt(((event.pageY - canvasy) / rect.height) * this.thresholdCanvas.height);
			this.paintCell(mousex, mousey);
			
			// Clear any painting outside the region box
			cleanUpExtra(this.thresholdCanvas, this.thresholdCanvas.getContext('2d'), this.state.regions[0]);
		}
		// show preview of current brush or eraser size
		this.showBrushOrEraserSize(event);
	}

	startPainting = (event) => {
		this.paint = true;
		this.paintCanvas(event);
		if (this.state.scanPathSet) {
			this.setState({
				scanPathSet: false,
			});
		}
	}

	stopPainting = () => this.paint = false;

	selectFullRegion = () => {
		this.setState(Object.assign({}, this.state, {
			regions: [
				{
					data: { index: 0 },
					height: 100,
					isChanging: false,
					new: false,
					width: 100,
					x: 0,
					y: 0,
				}
			], 
			oldState: RegionSelectionConstants.SELECTION_DONE
		}));
		this.confirmClicked();
	}

	toggleTiling = (event) => {
		if (event.target.checked) {
			this.props.dispatch(setDoTilingOnly(this.props.id));
		} else {
			this.props.dispatch(unsetDoTilingOnly(this.props.id));
		}
	}

	toggleTilingAfterScan = (event) => {
		if (event.target.checked) {
			this.props.dispatch(setDoTilingAfterScan(this.props.id));
		} else {
			this.props.dispatch(unsetDoTilingAfterScan(this.props.id));
		}
	}

	readbarcode = () => {
		this.setState({
			reading_barcode: true
		});
		let previewPath = this.props.preview.response.barcodeLocation;
		let url = `/server/devices/${this.props.device_id}/read_barcode/?path=${previewPath}`;
		axios.get(url).then(res => {
			if (res.data === "") {
				message.warn("No Barcode found on Slide. Please write Slide Name.")
			}
			else{
				this.props.dispatch(setScanName(this.props.id, res.data))
			}
			this.setState({
				reading_barcode: false
			});	
		})
		.catch(err => {
				console.log(err);
		});
	}

	movedBrushSizeSlider = (value) => this.setState({ brushSize : value });

	toggleBrushEraser = () => this.setState((prevState) => ({ usingBrush : !prevState.usingBrush}));

	changeBrushType = (e) => {
		if (e.target.value == brushType.REDRAW) {
			this.setState({
				scanPathSet: false,
			});
		}
        this.setState({
            brushType: e.target.value,
        });
    }

	previewCanvasRef = previewCanvas => this.previewCanvas = previewCanvas;

	thresholdCanvasRef = thresholdCanvas => this.thresholdCanvas = thresholdCanvas;

	brushEraserPrevCanvasRef = brushEraserPrevCanvas => this.brushEraserPrevCanvas = brushEraserPrevCanvas;

	dropDistanceCanvasRef = dropDistanceCanvas => this.dropDistanceCanvas = dropDistanceCanvas;

	focusPointsCanvasRef = focusPointsCanvas => this.focusPointsCanvas = focusPointsCanvas;

	onScanSpeedChange = (event) => {
		this.props.dispatch(setScanSpeed(this.props.id, event.target.value));
	} 

	onScanSpeedChangeStack = () => {
		this.props.dispatch(setScanSpeed(this.props.id, scanSpeed.ZSTACK));
	}

	onZLevelsChange = (event) => this.props.dispatch(setScanZLevels(this.props.id, event.target.value));

	onStepSizeChange = (event) => this.props.dispatch(setScanZStepSize(this.props.id, event.target.value));

	onSpecimenTypeChange = (event) => {
		console.log("spectype--changer", event.target.value)
		this.props.dispatch(setSpecimenType(this.props.id, event.target.value));
		
		let cache_buster_time = this.state.cacheBusterTime;
		let defaultMaskLocation = this.props.preview.response.defaultMaskLocation + "?d=" + cache_buster_time;
		if (event.target.value == specimenType.HNE_TISSUE) {
			defaultMaskLocation = this.props.preview.response.defaultHneMaskLocation + "?d=" + cache_buster_time;
		} else if (event.target.value == specimenType.IHC) {
			defaultMaskLocation = this.props.preview.response.defaultIhcMaskLocation + "?d=" + cache_buster_time;
		} else if (event.target.value == specimenType.LBC) {
			defaultMaskLocation = this.props.preview.response.defaultPapMaskLocation + "?d=" + cache_buster_time;
		}
		updateImages(this.previewCanvas, this.props.preview.response.uploadLocation + "?d=" + cache_buster_time,
			this.thresholdCanvas, defaultMaskLocation, this.state.regions);
		
		this.setState({
			scanPathSet: false,
		});
	}

	onObjectiveTypeChange = (event) => this.props.dispatch(setObjectiveType(this.props.id, event.target.value));

	onScanOutputFormatChange = (event) => this.props.dispatch(setScanOutputFormat(this.props.id, event.target.value));

	onScanNameChange = (event) => {
		let slide_name = event.target.value;
		this.setState({
			slide_name: slide_name
		}, () => this.props.dispatch(setScanName(this.props.id, slide_name)));
	}

	getFocusPointsTable = () => {
		let tableRows = [];
		tableRows.push(<Divider />);
		tableRows.push(
			<div>
				<Row>
					<Col offset={2} span={5}>
						Island ID
					</Col>
					<Col span={5}>
						Point
					</Col>
				</Row>
				<Divider />
			</div>
		);
		for (let island in this.props.preview.focusPoints) {
			for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
				let focusUnit = this.props.preview.focusPoints[island][i];
				if (focusUnit.length > 0) {
					tableRows.push(this.getFocusPointRow(island, i, focusUnit[0]));
				}
			}
		}
		tableRows.push(<Divider />);
		return tableRows;
	}

	getFocusPointRow = (islandID, index, point) => {
		return (
			<div>
				<Row>
					<Col offset={2} span={5}>
						{islandID}
					</Col>
					<Col span={5}>
						{"X: " + point.x + ", Y: " + point.y}
					</Col>
					<Col span={5}>
						<Button type={"link"} size="small" onClick={() => this.redrawnFocusPoints(islandID, index)}>
							Select
						</Button>
					</Col>
					<Col span={5}>
						<Button type={"link"} size="small" onClick={() => this.deleteFocusPoint(islandID, index)}>
							Delete
						</Button>
					</Col>
				</Row>
				<Divider />
			</div>
		);
	}

	redrawnFocusPoints = (islandID, index) => {
		let pointDrawn = false;

		for (let island in this.props.preview.focusPoints) {
			for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
				let focusUnit = this.props.preview.focusPoints[island][i];
				if (focusUnit.length > 0) {
					if (island == islandID && i == index) {
						drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, this.focusPointsCanvas,
							{clearCanvas: !pointDrawn, color: '#7CFC00', size: 8, width: 2}, this.props.preview);
					} else {
						drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, this.focusPointsCanvas,
							{clearCanvas: !pointDrawn, color: 'green', size: 5, width: 1}, this.props.preview);
					}
					pointDrawn = true;
				}
			}
		}
	}

	deleteFocusPoint = (islandID, index) => {
		this.props.dispatch(onDeleteFocusPoint(this.props.device_id, this.props.id, this.props.preview, this.focusPointsCanvas, islandID, index));
	}

    changeScanSettingsType = (key) => {
		if (key == scanSettingsTypeVals.ADVANCED) {
			if (!this.state.scanPathSet) {
				let imageURLData = this.thresholdCanvas.toDataURL("image/jpeg");
				let reg = this.state.regions[0];
				let x1 = parseInt((reg.x * this.props.preview.response.previewWidth) / 100.0);
				let x2 = parseInt(((reg.x + reg.width) * this.props.preview.response.previewWidth) / 100.0);
				let y1 = parseInt((reg.y * this.props.preview.response.previewHeight) / 100.0);
				let y2 = parseInt(((reg.y + reg.height) * this.props.preview.response.previewHeight) / 100.0);
				let actualReg = [x1, y1, x2, y2];

				let thresholdCanvas = this.thresholdCanvas;
				let x1_box = parseInt((reg.x * thresholdCanvas.width) / 100.0);
				let x2_box = parseInt(((reg.x + reg.width) * thresholdCanvas.width) / 100.0);
				let y1_box = parseInt((reg.y * thresholdCanvas.height) / 100.0);
				let y2_box = parseInt(((reg.y + reg.height) * thresholdCanvas.height) / 100.0);
				let green_box_region = [x1_box,y1_box, x2_box , y2_box];

				this.props.dispatch(setDeviceBusy(this.props.device_id));
				this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.CALCULATE_FOCUS_POINTS));
				let url = '/server/devices/' + this.props.device_id + "/send_threshold_image/?slot_id=" + this.props.id;
				axios({
					method:"post",
					url : url, 
					data: {
						imgBase64: imageURLData, 
						specimenType: this.props.preview.specimenType,
						objectiveType: this.props.preview.objectiveType,
						actualRegion: actualReg,
						previewData: this.props.preview,
						greenBoxRegion: green_box_region,
					}
				})
				.then(response => {
					if (response.status === 200) {
						this.props.dispatch(setPreviewStatusToConfirmed(this.props.id, reg, actualReg));
						this.props.dispatch(setDropDistancePointOnly(this.props.id, response.data.dropDistancePoint));
						this.props.dispatch(setDropDistancePointLength(this.props.id, response.data.dropDistancePoint));
						this.props.dispatch(setFocusPoints(this.props.id, response.data.focusPoints));
						this.setState({
							scanSettingsType: key,
							scanPathSet: true,
							currResponse: response,
						});
					}
					else {
						this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, this.props.id));
						message.error("Not able to switch to advanced settings. Please contact admin.", 2.5);
					}
					this.props.dispatch(setDeviceIdle(this.props.device_id));
					this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
				})
				.catch(err => {
					this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, this.props.id))
					this.props.dispatch(setDeviceIdle(this.props.device_id));
					this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
					message.error("Not able to switch to advanced settings. Please contact admin.", 2.5);
				})
			} else {
				this.setState({
					scanSettingsType: key,
				});
			}
		} else if (key == scanSettingsTypeVals.GENERAL) {
			this.setState({
				scanSettingsType: key,
			});
		}
    }

	render() {
		console.log("washere",typeof((((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).busy))
		console.log("names--1234", this.state.slide_name)
		let status = (this.props.preview || {}).status;
		let brushComponent = null;
		let speedSetter = null;
		let zStackConfig = null;
		let allowPainting = false;
		let focusPointsTable = null;
		let buttonsPanel = null;
		let slotComp = null;
		let slideNameInput = null;
		let scanButton = null;
		let specimenChooser = null;
		let objectiveChooser = null;
		let scanOutputFormatChanger = null;
		let adminPanel = null;

		if (status === RegionSelectionConstants.SELECTION_DONE) {
			brushComponent = getBrushPanel(this.state.brushType, this.state.brushSize, this.changeBrushType, this.movedBrushSizeSlider);
			speedSetter = getSpeedPanel(this.onScanSpeedChange, this.props.preview);
			zStackConfig = getZStackConfigPanel(this.onZLevelsChange, this.onStepSizeChange, this.props.preview);
			console.log("names--", this.props.preview.slideName, this.props.preview.response.slideName, this.state.slide_name)
			slideNameInput = <Row>
								<Col span={10} offset={7}>
									<span className="arial-font" style={{marginRight:10, fontSize:13}}>Slide Name </span>
									<Spin spinning={this.state.reading_barcode} tip="Trying to Read Barcode...">
										<Search value={this.props.preview.slideName} placeholder={this.props.preview.response.slideName} enterButton="Read Barcode" onChange={this.onScanNameChange} onSearch={this.readbarcode}/>
									</Spin>
								</Col>
							</Row>
			// allowPainting = true;
		}

		if (this.state.brushType != brushType.REDRAW) {
			allowPainting = true;
		}

		if (status === RegionSelectionConstants.FETCHED_IMAGE || status === RegionSelectionConstants.SELECTION_DONE || 
			status === RegionSelectionConstants.START_DROP_DISTANCE_SELECTION || status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			buttonsPanel = [
				getButtonsPanel(status, this.confirmClicked, this.onRetakePreview, this.clearSelection, this.selectFullRegion, this.state.regions, this.props.cassetteSize), 
				<Row key={1}>
					<Col span={6} offset = {9} style={{fontSize:14}}>
						Selected Area : {this.state.regionArea} mm2
					</Col>
				</Row>
			]
		}

		if (status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			focusPointsTable = this.getFocusPointsTable();
		}

		if (status === RegionSelectionConstants.CONFIRMED) {
			buttonsPanel = getConfirmedPanel(this.clearSelection);
		}

		if(!this.state.overrideBlank && ((this.props.preview || {}).response || {}).islandsFailure && status === RegionSelectionConstants.CONFIRMED){
			buttonsPanel = getBlankSlidePanel(this.overrideBlankSlide);
		}

		if (this.props.cassetteSize === 1 && status === RegionSelectionConstants.SELECTION_DONE) {
			scanButton =  <Row className="start-scan-button-div">
							<Button className="start-scan-button" type="danger" size={'large'} onClick={this.confirmClicked}><ScanOutlined /> Start Scanning</Button>
						</Row>;
		}

		let is_superuser = cookie.loadAll().superuser === "true";

		if ( is_superuser && status === RegionSelectionConstants.SELECTION_DONE) {
			adminPanel = getAdminPanel(this.props.preview, this.toggleTiling, this.toggleTilingAfterScan);
		}

		if (status === RegionSelectionConstants.SELECTION_DONE) {
			specimenChooser = getSpecimenChooser(this.onSpecimenTypeChange, this.props.preview, is_superuser);
			if (this.props.isObjectiveSwitcherPresent && this.props.allowObjectiveChange) {
				objectiveChooser = getObjectiveChooser(this.onObjectiveTypeChange, this.props.preview)
			}
			if (this.props.allowScanOutputFormatChange) {
				scanOutputFormatChanger = getScanOutputFormatChanger(this.onScanOutputFormatChange, this.props.preview);
			}
		}

		if (this.props.showSummary) {
			slotComp = this.props.summaryDiv;
		} else if (this.props.id === -1 || status === RegionSelectionConstants.NOT_STARTED) { 
		} else if (status !== RegionSelectionConstants.ERRORED_OUT) {
			slotComp = (
				<div className="relative-parent properties-width">
					<Tabs className="tab-section-height" activeKey={this.state.scanSettingsType} onChange={this.changeScanSettingsType}>
						<TabPane tab="General" key={scanSettingsTypeVals.GENERAL}>
							<GeneralPreviewSettingsComp showBrushOrEraserSize={this.showBrushOrEraserSize} brushType={this.state.brushType} brushSize={this.state.brushSize} changeBrushType={this.changeBrushType}
								movedBrushSizeSlider={this.movedBrushSizeSlider} status={status} previewCanvasRef={this.previewCanvasRef} 
								thresholdCanvasRef={this.thresholdCanvasRef} brushEraserPrevCanvasRef={this.brushEraserPrevCanvasRef} regions={this.state.regions} onCanvasUpdate={this.onCanvasUpdate} 
								allowPainting={allowPainting} startPainting={this.startPainting} paintCanvas={this.paintCanvas} 
								stopPainting={this.stopPainting} dropDistanceCanvas={this.dropDistanceCanvas}
								dropDistanceCanvasRef={this.dropDistanceCanvasRef} focusPointsCanvas={this.focusPointsCanvas} 
								focusPointsCanvasRef={this.focusPointsCanvasRef} id={this.props.id} device_id={this.props.device_id} 
								onScanNameChange={this.onScanNameChange} readbarcode={this.readbarcode} reading_barcode={this.state.reading_barcode} onSpecimenTypeChange={this.onSpecimenTypeChange}
								onScanSpeedChange={this.onScanSpeedChange} onScanSpeedChangeStack={this.onScanSpeedChangeStack} onZLevelsChange={this.onZLevelsChange} 
								onStepSizeChange={this.onStepSizeChange} toggleTiling={this.toggleTiling} toggleTilingAfterScan={this.toggleTilingAfterScan}
								regionArea={this.state.regionArea} slideType={this.props.slideType} isMaximusScanner={this.props.isMaximusScanner} />
						</TabPane>
						<TabPane tab="Advanced" key={scanSettingsTypeVals.ADVANCED} 
								disabled = {(((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).busy}
								>
							<AdvancedPreviewSettingsComp 
								id={this.props.id} device_id={this.props.device_id} scanSettingsType={this.state.scanSettingsType} 
								currResponse={this.state.currResponse} 
								brightnessInputValue={this.state.brightnessInputValue} contrastInputValue={this.state.contrastInputValue} brightnessValueChange={this.brightnessValueChange} contrastValueChange={this.contrastValueChange}
								exposureInputValue={this.state.exposureInputValue} gammaInputValue={this.state.gammaInputValue}  exposureValueChange={this.exposureValueChange} gammaValueChange={this.gammaValueChange}
								setValues = {this.setValues}
								/>
						</TabPane>
					</Tabs>
					<Row className="save-button-height">
						<Col offset={0} span={24}>
							<Button style={{width: '40%', marginLeft: '30%', marginRight: '30%'}} type="primary" onClick={this.confirmPreviewSettingChanges}>
								{/* {(this.props.cassetteSize == 1 || this.props.cassetteSize == 2) ? "Start Scan" : "Finalize slide setting"} */}
								Finalize slide setting
							</Button>
						</Col>
					</Row>
				</div>
			)
		} else {
			slotComp = <Row>Error</Row>;
		}

		return (
			<Spin spinning={this.props.preview.status === RegionSelectionConstants.GETTING_IMAGE || this.props.preview.actionOngoing} 
				tip={this.props.preview.message}>
				{slotComp}
			</Spin>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		preview: state.previewStatusReducer[ownProps.id],
        deviceStatus: state.deviceStatusReducer,
		slot_id: ownProps.slot_id,
	};
};

export default connect(mapStateToProps)(SlotComponent);
