import React, {Component} from  "react";
import {connect} from 'react-redux';
import {CircularProgress} from "@mui/material";
import AnnotationDrawer from "./anno_drawer";
import {getAnnotationAppLayer, getDeepBioAppDrawerLayer} from "../utils/layers_info";
import * as keys from "../drawer/draw_tool_keys";
import {addNewAnnotation, addNewDeepBioAnnotation} from "../../../action/maps.state.action";

class AbstractAnnotator extends Component {

    initState = () => {
        this.gammaState = this.props.gammaState;
        this.activeAnnoDrawer = this.gammaState.activeAnnoDrawer;
        this.mapState = this.props.mapsState[this.gammaState.activeMapId];
        this.annotationState = this.mapState.annotationState;
        this.deepBioState = this.mapState.deepBioState;
    }

    getAnnotationAppDrawer = () => {
        let layer = getAnnotationAppLayer(this.gammaState);
        let vector = (this.annotationState || {}).vector;
        if (!vector)
            return <CircularProgress/>
        return <AnnotationDrawer
            vector={vector}
            creator={layer.anno_drawer}
            storeAnnotation={addNewAnnotation}
            drawToolKeys={[keys.lineDrawingKey.name,  keys.freelineDrawingKey.name, keys.circleDrawingKey.name,keys.freehandDrawingKey.name, keys.rectangleDrawingKey.name,
                keys.polygonDrawingKey.name, keys.magicToolDrawingKey.name]}
            activeDrawToolKeys={[keys.lineDrawingKey.name,  keys.freelineDrawingKey.name, keys.circleDrawingKey.name,keys.freehandDrawingKey.name, keys.rectangleDrawingKey.name,
                keys.polygonDrawingKey.name, keys.magicToolDrawingKey.name]} // made two in the moment coz we decided it might be better to disable rather disappear for diff apps
            colorDropDown={true}
        />
    }

    getDeepBioAppDrawer = () => {
        let layer = getDeepBioAppDrawerLayer(this.gammaState);
        let vector = (this.deepBioState || {}).vector;
        if (!vector)
            return <CircularProgress/>
        return <AnnotationDrawer
            vector={vector}
            creator = {layer.anno_drawer}
            storeAnnotation = {addNewDeepBioAnnotation}
            drawToolKeys={[keys.lineDrawingKey.name, keys.freelineDrawingKey.name, keys.circleDrawingKey.name,keys.freehandDrawingKey.name, keys.rectangleDrawingKey.name,
                keys.polygonDrawingKey.name, keys.magicToolDrawingKey.name]}
            activeDrawToolKeys={[keys.circleDrawingKey.name,keys.rectangleDrawingKey.name, keys.polygonDrawingKey.name]}
            colorDropDown={true}
        />
    }

    render() {
        this.initState();
        if (this.activeAnnoDrawer === 0)
            return this.getAnnotationAppDrawer();
        if (this.activeAnnoDrawer === 1)
            return this.getDeepBioAppDrawer();
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(AbstractAnnotator);
