import React, {Component} from "react";
import {connect} from 'react-redux';
import {
    Box,
    Checkbox, CircularProgress,
    FormControl, FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
    Typography
} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import {UnmountClosed} from "react-collapse";
import AnnotationListExpandedComponent from "./annotation_list_expanded_comp";
import {
    getAnnotationFromPoints,
    getAnnotationListIcon,
    getFeatureFromAnnotation
} from "../utils/annotations_app_utils";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {AllAnnotationsConstants} from "../../../consts/anno.const";
import CircleIcon from "@mui/icons-material/Circle";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {updateDeepBioResultsDisplayed} from "../../../action/deepbio.action";
import {polygonDrawingKey} from "../drawer/draw_tool_keys";
import VectorSource from "ol/source/Vector";
import {Fill, Stroke, Style, Text as OlText} from "ol/style";
import BiotechIcon from '@mui/icons-material/Biotech';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {morphleBaseTheme1} from "../themes";
import {runAIOnWholeSlide} from "../utils/deepBioAppUtils";
import {deleteAnnotation} from "../../../action/maps.state.action";
import AnnotationsDownload from "./annotationsDownload";

class AnnotationsListGeneric extends Component{
    constructor(props) {
        super(props);
        this.state = {
            expanded:[],
            annotations_list: this.props.data,
            checkBoxSelected: {},
            colorFilter: "ALL",
            showing_deepbio_results:{},
        }

        this.maxExpanded = 1;//number of annos the user can expand at a given time

    }

    initState = () => {
        this.activeMapId = this.props.activeMapId;
        this.slideState = this.props.mapsState[this.activeMapId].slideState;
        this.deepBioState = this.props.mapsState[this.activeMapId].deepBioState;
    }

    compareArrays = (array1, array2) => {
        if (array1.length === array2.length){
            for(let i=0;i<array1.length;i++){
                if(array1[i]!=array2[i]){
                    return false;
                }
            }
            return true;
        }
        else
            return false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data) {
            this.setState({
                annotations_list: this.props.data,
            });
        }

        if(prevProps.data.length === this.props.data.length){
            let prevData = prevProps.data;
            let presentData = this.props.data;
            console.log("prev--*", prevData, presentData)
            for(let i=0;i<prevData.length;i++){
                if((prevData[i]||{}).deep_bio_result==null && (presentData[i]||{}).deep_bio_result!=null){
                    console.log("changed state -- continue",(prevData[i]||{}).deep_bio_result, (presentData[i]||{}).deep_bio_result)
                    this.setState({
                        annotations_list: this.props.data,
                    })
                    continue;
                }
                if((prevData[i]||{}).deep_bio_result==null || (presentData[i]||{}).deep_bio_result==null){
                    continue;
                }
                console.log("changed state -- out", (((prevData[i]||{}).deep_bio_result[0]||{}).fields||{}).status, (((presentData[i]||{}).deep_bio_result[0]||{}).fields||{}).status )
                if((((prevData[i]||{}).deep_bio_result[0]||{}).fields||{}).status!==(((presentData[i]||{}).deep_bio_result[0]||{}).fields||{}).status){
                    console.log("changed state", this.props.data);
                    this.setState({
                        annotations_list: this.props.data,
                    })
                    break;
                }
                if((((prevData[i]||{}).deep_bio_result[0]||{}).fields||{}).progress!==(((presentData[i]||{}).deep_bio_result[0]||{}).fields||{}).progress){
                    console.log("changed state", this.props.data);
                    this.setState({
                        annotations_list: this.props.data,
                    })
                    break;
                }
            }
        }


        if(prevProps.selectedAnnoMap !== this.props.selectedAnnoMap){ // not generic has to be app specific
            // let ypos = this.countYPos(this.props.selectedAnnoMap);
            document.getElementById(`li-${this.props.selectedAnnoMap}`).scrollIntoView({behavior: "smooth", block:'start'});
            this.handleExpansionAndReverse(null, this.props.selectedAnnoMap)
        }

        if (!this.compareArrays(prevProps.deepBioData.ai_results_displayed,this.props.deepBioData.ai_results_displayed)) {
            this.updateDeepBioResultsDisplayed();
        }

        if (prevProps.deepBioData.active_filter !== this.props.deepBioData.active_filter) {
            this.updateDeepBioResultsDisplayed();
        }
        // if(prevProps.deepBioData.ai_results_displayed.length!=this.props.deepBioData.ai_results_displayed.length){
        //     console.log("arrays==")
        // }
        console.log("arrays=", prevProps.deepBioData,this.props.deepBioData)
    }

    updateDeepBioResultsDisplayed = () => {
        let selected = this.props.deepBioData.ai_results_displayed;
        let vector = this.deepBioState.vector;
        let annotations_list = this.state.annotations_list;
        let filter = this.props.deepBioData.active_filter;
        let features = [];
        for(let i=0; i<selected.length; i++){
            if(selected[i]=="ALL"){
                let contours = (((this.props.wholeSlideAi||{})['result']||{})['heatmap']||{})['contours']
                if(contours==undefined){
                    return;
                }
                for(let i=0;i<contours.length;i++){
                    if(filter!="ALL" && filter.toLowerCase()!=contours[i]['label'].toLowerCase()){
                        continue;
                    }
                    let color = contours[i]['color'];
                    let bounds = contours[i]['contour'];
                    let new_anno = getAnnotationFromPoints(
                        color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
                        this.slideState.slide_data.id
                    )
                    new_anno.title = contours[i]['label']
                    let new_anno_feature = getFeatureFromAnnotation(new_anno);
                    new_anno_feature.set('parent_anno', "ALL");
                    features.push(new_anno_feature);
                }
            }else{
                for(let j=0;j<annotations_list.length; j++){
                    // console.log("xxxx-", annotations_list[j].id, selected[i])
                    if(annotations_list[j].id == selected[i]){
                        let annotation = annotations_list[j];
                        let contours = ((annotation['stored_result']||{})['heatmap']||{})['contours'];
                        console.log("contours", contours)
                        if(!contours){
                            continue;
                        }
                        for(let i=0; i<contours.length; i++){
                            if(filter!="ALL" && filter.toLowerCase()!=contours[i]['label'].toLowerCase()){
                                continue;
                            }
                            let color = contours[i]['color'];
                            let bounds = contours[i]['contour'];
                            let new_anno = getAnnotationFromPoints(
                                color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
                                this.slideState.slide_data.id
                            )
                            new_anno.title = contours[i]['label']
                            let new_anno_feature = getFeatureFromAnnotation(new_anno);
                            new_anno_feature.set('parent_anno', annotations_list[j].id);
                            features.push(new_anno_feature);
                        }
                    }
                }
            }
        }
        vector.setSource(
            new VectorSource({
                features: features,
                wrapX: false,
            })
        )
        vector.setStyle(this.styleFunctionDeepBio);
    }

    handleExpansionAndReverse = (e, id) => {
        let expanded = this.state.expanded;
        let idx = expanded.indexOf(id)
        console.log("idx", idx)
        if(idx !== -1){
            expanded.splice(idx, 1);
        }
        else{
            if(expanded.length >= this.maxExpanded){
                expanded.shift();
            }
            expanded.push(id);
        }
        this.setState({
            expanded: expanded,
        })
    }

    locateOnMap = (e, annotation) => {
        console.log("Locate and focus this annotation on map", annotation)
        let featureFoundOnMap = false;
        let selectedFeature = this.props.vector.getSource().getFeatures().find((feature)=> {
            if(feature.getId() === annotation.id){
                featureFoundOnMap = true;
            }
            return feature.getId() === annotation.id;
        })
        if(featureFoundOnMap){
            this.slideState.slidemap.getView().fit(selectedFeature.getGeometry());
        }
    }

    // editContent = () => {
    //     console.log("edit content")
    // }

    getExpandedComponent = (annotation) => {
        return <AnnotationListExpandedComponent
                    annotation = {annotation}
                    app = {this.props.app}
                />
    }

    deleteMultipleAnnotations = () => {
        let checkBoxSelected = this.state.checkBoxSelected;
        for(let i=0; i<this.state.annotations_list.length; i++){
            if(checkBoxSelected[this.state.annotations_list[i].id]){
                checkBoxSelected[this.state.annotations_list[i].id] = false;
                this.props.dispatch(deleteAnnotation(this.activeMapId, this.state.annotations_list[i].id))
            }
        }
        this.setState({
            checkBoxSelected: {},
        })
    }

    getMultipleSelectComponent = (annotations) =>
        <Grid sx={{height:'3vh', display:'flex', marginLeft: 3, marginRight: 2, justifyContent:"space-between"}}>
            <Tooltip title={"Select All"} placement={"right"}>
                <Checkbox edge={"start"}
                    // priyanshu - it doesn't make sense, but currently it doesn't directly work with true/false,
                    // but works with true === true / false === true, remove in future if it starts working
                          checked={annotations.reduce((checked, annotation) =>
                              checked && this.state.checkBoxSelected[annotation.id], true) === true}
                          disableRipple size={'medium'}
                          icon={<CheckBoxOutlineBlankIcon/>}
                          checkedIcon={<CheckBoxIcon color={"secondary"}/>}
                          onChange={e => this.handleAllListSelect(annotations, e.target.checked)}/>
            </Tooltip>
            <IconButton onClick={this.deleteMultipleAnnotations} color={'secondary'}>
                <DeleteIcon/>
            </IconButton>
        </Grid>

    handleListSelect = (annotation, checked) =>
        this.setState({
            checkBoxSelected: {
                ...this.state.checkBoxSelected,
                [annotation.id]: checked,
            },
        });

    handleAllListSelect = (annotations, checked) => {
        let checkBoxSelected = {...this.state.checkBoxSelected};
        annotations.forEach(annotation => checkBoxSelected[annotation.id] = checked);
        this.setState({
            checkBoxSelected: checkBoxSelected,
        });
    }

    baseStyle = new Style({
        stroke: new Stroke({
            color: 'rgba(255, 0, 0, 0.0)',
            width: 2
        }),
        fill: new Fill({
            color: 'rgba(255, 0, 0, 0.0)'
        })
    });

    baseText = new OlText({
        font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
        placement: AllAnnotationsConstants.LINE,
        textBaseline: 'top',
        fill: new Fill({
            color: "#00FF00"
        }),
        backgroundFill: new Fill({
            color: "#ffffff"
        }),
        backgroundStroke: new Fill({
            color: "#ffffff"
        })
    })

    selectedStyle = new Style({
        stroke: new Stroke({
            color: "#00b3ff",
            width: 8
        })
    })

    otherStyle = new Style({
        stroke: new Stroke({
            color: "white",
            width: 0
        })
    })

    hexToRGBA = (hex, opacity=0.2) => {
        return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g'))
            .map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) })
            .concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
    }

    styleFunctionDeepBio = (feature, resolution) => {
        let baseStyleWithText = this.baseStyle;
        let baseText = this.baseText;
        this.baseText.setText(feature.get('title'));
        if(feature.getProperties().color != undefined && feature.getProperties().color != "") {
            baseStyleWithText.getStroke().setColor(feature.getProperties().color);
            baseText.getFill().setColor(feature.getProperties().color);
            baseStyleWithText.getFill().setColor(this.hexToRGBA(feature.getProperties().color))
            // baseStyleWithText.getFill().getColor().setOpacity(0.2)
        }
        baseStyleWithText.setText(baseText);
        if(feature.getProperties().selected) {
            return [this.selectedStyle, baseStyleWithText]
        }else{
            return [this.otherStyle, baseStyleWithText]
        }
    }


    handleWholeSlideAIDisplay = (e, id) => {
        console.log("res*", this.props.wholeSlideAi)
        let selected = JSON.parse(JSON.stringify(this.props.deepBioData.ai_results_displayed));
        let vector = this.deepBioState.vector;
        let contours = (((this.props.wholeSlideAi||{})['result']||{})['heatmap']||{})['contours']
        if(selected.indexOf(id) == -1){
            selected.push(id);
            // let new_features = [];
            // let features = vector.getSource().getFeatures();
            // for(let i=0;i<contours.length;i++){
            //     let color = contours[i]['color'];
            //     let bounds = contours[i]['contour'];
            //     let new_anno = getAnnotationFromPoints(
            //         color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
            //         this.slideState.slide_data.id
            //     )
            //     new_anno.title = contours[i]['label']
            //     let new_anno_feature = getFeatureFromAnnotation(new_anno);
            //     new_anno_feature.set('parent_anno', id);
            //     new_features.push(new_anno_feature);
            // }
            // let final_features = features.concat(new_features);
            // vector.setSource(
            //     new VectorSource({
            //         features: final_features,
            //         wrapX: false,
            //     })
            // )
            // vector.setStyle(this.styleFunctionDeepBio)
        }else{
            let idx = selected.indexOf(id);
            selected.splice(idx, 1);
            // let features = vector.getSource().getFeatures();
            // let final_features = []
            // for(let i=0; i<features.length; i++){
            //     console.log("parent_anno", features[i].get('parent_anno'))
            //     if(features[i].get('parent_anno')!=id){
            //         final_features.push(features[i]);
            //     }
            // }
            // vector.setSource(
            //     new VectorSource({
            //         features: final_features,
            //         wrapX: false,
            //     })
            // )
            // vector.setStyle(this.styleFunctionDeepBio);
        }
        this.props.dispatch(updateDeepBioResultsDisplayed(selected));
    }


    handleDeepBioDisplaySelect = (e, id ) => {
        let selected_ = JSON.parse(JSON.stringify(this.props.deepBioData.ai_results_displayed));
        // let vector = this.deepBioState.vector;
        // let contours = ((annotation['stored_result']||{})['heatmap']||{})['contours'];
        // let color_filter = this.props.deepBioData.colorFilter;
        // console.log("selected__", selected)
        // console.log("vec__", vector.getSource().getFeatures())
        // console.log("data--",((annotation['stored_result']||{})['heatmap']||{})['contours'])
        console.log("id--", id, selected_)
        if(selected_.indexOf(id) == -1){
            selected_.push(id);
            // let new_features = [];
            // let features = vector.getSource().getFeatures();
            // for(let i=0;i<contours.length;i++){
            //     let color = contours[i]['color'];
            //     let bounds = contours[i]['contour'];
            //     let new_anno = getAnnotationFromPoints(
            //         color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
            //         this.slideState.slide_data.id
            //     )
            //     new_anno.title = contours[i]['label']
            //     let new_anno_feature = getFeatureFromAnnotation(new_anno);
            //     new_anno_feature.set('parent_anno', id);
            //     new_features.push(new_anno_feature);
            // }
            // let final_features = features.concat(new_features);
            // vector.setSource(
            //     new VectorSource({
            //         features: final_features,
            //         wrapX: false,
            //     })
            // )
            // vector.setStyle(this.styleFunctionDeepBio)
        }else{
            let idx = selected_.indexOf(id);
            selected_.splice(idx, 1);
            // let features = vector.getSource().getFeatures();
            // let final_features = []
            // for(let i=0; i<features.length; i++){
            //     console.log("parent_anno", features[i].get('parent_anno'))
            //     if(features[i].get('parent_anno')!=id){
            //         final_features.push(features[i]);
            //     }
            // }
            // vector.setSource(
            //     new VectorSource({
            //         features: final_features,
            //         wrapX: false,
            //     })
            // )
            // vector.setStyle(this.styleFunctionDeepBio);
        }
        this.props.dispatch(updateDeepBioResultsDisplayed(selected_));
    }

    getAnnotationsList = (annotations) => annotations.map(annotation =>
        <div>
            <ListItem divider={true} id={`li-${annotation.id}`}>
                <Checkbox
                    // priyanshu - it doesn't make sense, but currently it doesn't directly work with true/false,
                    // but works with true === true / false === true, remove in future if it starts working
                    checked={this.state.checkBoxSelected[annotation.id] === true}
                    disableRipple
                    size={'small'}
                    icon = {<CheckBoxOutlineBlankIcon/>}
                    checkedIcon={<CheckBoxIcon color={"secondary"}/>}
                    onChange={(e) => this.handleListSelect(annotation, e.target.checked)}
                />
                <ListItemButton sx={{alignContent:'right', width:'65%'}} onClick={(e)=>this.locateOnMap(e, annotation)}>
                    {getAnnotationListIcon(annotation.tool_type, true)}
                    <ListItemText
                        id = {annotation.id}
                        primary={annotation.title.substring(0, 7) + `${annotation.title.length > 7 ? ".." : ""}`}
                        sx={{marginLeft:'8px'}}
                    />
                </ListItemButton>
                <ListItemButton onClick={(e)=> this.handleExpansionAndReverse(e, annotation.id)}>
                    {
                        this.state.expanded.indexOf(annotation.id) === -1
                            ?  <ExpandMore/>
                            :  <ExpandLess/>
                    }
                </ListItemButton>
            </ListItem>
            <UnmountClosed key={annotation.id} isOpened={this.state.expanded.indexOf(annotation.id)!==-1}>
                    {
                        this.props.getExpandedComponent !== undefined?
                            this.props.getExpandedComponent(annotation):
                            this.getExpandedComponent(annotation)
                    }
            </UnmountClosed>
        </div>
    );

    getWholeSlideExtendedComponent = () => {
        let summary = this.props.wholeSlideAi.result.summary;
        if (!summary) {
            return
        }
        let tableData = []
        for(let i=0; i<(Object.keys(summary).length)-1;i++){
            tableData.push(<TableRow>
                <TableCell sx={{color:"#00f548"}}>{Object.keys(summary)[i].toUpperCase()}</TableCell>
                <TableCell>{Object.values(summary)[i]}</TableCell>
            </TableRow>)
        }
        let deepBioTable = <TableContainer component={Paper}>
            <Table sx={{ width: '95%' }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {/*<TableCell>Property</TableCell>*/}
                        {/*<TableCell align="right">Value</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData}
                </TableBody>
            </Table>
        </TableContainer>

        return deepBioTable;
    }

    wholeSlideStatus = () => {
        this.props.getWholeSlideAIStatus();
    }

    getWholeSlideAnnoComponent = () => {
        let checkbox_;
        let wholeSlideAiResult = this.props.wholeSlideAi;
        if(wholeSlideAiResult.status == "NOT_RUN"){
            // checkbox_ =  <Checkbox
            //     edge={"start"}
            //     checked={this.state.checkBoxSelected["ALL"]}
            //     disableRipple
            //     size={'small'}
            //     key={0}
            //     icon={<CheckBoxOutlineBlankIcon/>}
            //     checkedIcon={<CheckBoxIcon color={"secondary"}/>}
            //     onChange={(e) => this.handleListSelect(e, "ALL")}
            // />
            if(this.props.uploaded==2){
                checkbox_ = <IconButton color={"secondary"} edge={"start"} onClick={()=>{runAIOnWholeSlide(this.slideState.slide_data.morphle_id,this.slideState.slide_data.id, this.wholeSlideStatus)}}>
                    <PlayArrowIcon/>
                </IconButton>
            }else{
                checkbox_ = <Tooltip title={"Upload Slide To Run AI"} placement={"top"}>
                                <IconButton color={"secondary"} edge={"start"} disabled>
                                    <PlayArrowIcon/>
                                </IconButton>
                            </Tooltip>
            }
        }else if (wholeSlideAiResult.status === "FINISHED"){
            checkbox_ = <Checkbox
                edge={"start"}
                checked={this.props.deepBioData.ai_results_displayed.indexOf("ALL") !== -1}
                disableRipple
                size={'small'}
                key={"ALL"}
                icon={<RemoveRedEyeIcon/>}
                checkedIcon={<VisibilityOffIcon color={"secondary"}/>}
                onChange={(e) => this.handleWholeSlideAIDisplay(e, "ALL")}
            />
        }else{
            // running / progress
            checkbox_ = <Tooltip title={this.props.wholeSlideAi.status} placement="top-start">
                    <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft:"-14px" }}>
                        <CircularProgress variant="determinate" value={this.props.wholeSlideAi.progress} color={"secondary"} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="caption" component="div" color="text.secondary">
                                {`${Math.round(this.props.wholeSlideAi.progress)}%`}
                            </Typography>
                        </Box>
                    </Box>
                </Tooltip>
        }
        return <div>
            <ListItem
                // key = {annotation.id+Math.random()}
                // disablePadding
                // disableGutters={true}
                divider={true}
                id={`li-001`}
            >
                {/*<ListItemIcon>*/}
                {/*<Checkbox*/}
                {/*    edge={"start"}*/}
                {/*    checked={this.state.checkBoxSelected[annotation.id]}*/}
                {/*    disableRipple*/}
                {/*    size={'small'}*/}
                {/*    key={annotation.id}*/}
                {/*    icon={<CheckBoxOutlineBlankIcon/>}*/}
                {/*    checkedIcon={<CheckBoxIcon color={"secondary"}/>}*/}
                {/*    onChange={(e) => this.handleListSelect(e, annotation.id)}*/}
                {/*/>*/}
                {/*</ListItemIcon>*/}
                {/*// shape icon*/}
                {checkbox_}
                <ListItemButton sx={{alignContent: 'right', width: '65%'}}>
                    <BiotechIcon sx={{color:morphleBaseTheme1.palette.headBarIcon.primary}}/>
                    <ListItemText
                        id={`li-001-`}
                        primary="Whole Slide"
                        sx={{marginLeft: '8px'}}
                    />
                </ListItemButton>
                <ListItemButton onClick={(e) => this.handleExpansionAndReverse(e, "ALL")} sx={this.props.wholeSlideAi.result==""?{display:'none'}:{}}>
                    {
                        this.state.expanded.indexOf("ALL") === -1
                            ? <ExpandMore/>
                            : <ExpandLess/>
                    }
                </ListItemButton>
            </ListItem>
            <UnmountClosed key={"ALL"} isOpened={this.state.expanded.indexOf("ALL") !== -1}>
                {
                    this.getWholeSlideExtendedComponent()
                }
            </UnmountClosed>
        </div>
    }

    getAnnotationListDeepBio = (annotations_) => {
        let listItems = [];
        let annotations = annotations_;
        listItems.push(this.getWholeSlideAnnoComponent())
        for (let i = 0; i < annotations.length; i++) {
            let annotation = annotations[i];
            let deep_bio_result = null;
            if (annotation.deep_bio_result != null) {
                deep_bio_result = annotation.deep_bio_result[0];
            }
            console.log("deepbioresult", deep_bio_result)
            let checkbox_;
            if(deep_bio_result == null){
                checkbox_ =  <Checkbox
                                edge={"start"}
                                checked={this.state.checkBoxSelected[annotation.id]}
                                disableRipple
                                size={'small'}
                                key={annotation.id}
                                icon={<CheckBoxOutlineBlankIcon/>}
                                checkedIcon={<CheckBoxIcon color={"secondary"}/>}
                                onChange={(e) => this.handleListSelect(e, annotation.id)}
                            />
            }else if(((deep_bio_result['fields']||{})||{})['status']=="FINISHED"){
                // show eye icon according to dikh rha ya nhi
                // console.log("result__x", annotation['stored_result']['heatmap'])
                // if(this.state.showing_deepbio_results[annotation.id]==true){
                //     checkbox_ = <VisibilityOffIcon />
                // }else{
                //     checkbox_ = <Checkbox><RemoveRedEyeIcon color={"secondary"} fontSize={'2px'} /></Checkbox>
                // }
                    checkbox_ = <Checkbox
                                    edge={"start"}
                                    checked={this.props.deepBioData.ai_results_displayed.indexOf(annotation.id)!=-1}
                                    disableRipple
                                    size={'small'}
                                    key={annotation.id}
                                    icon={<RemoveRedEyeIcon/>}
                                    checkedIcon={<VisibilityOffIcon color={"secondary"}/>}
                                    onChange={(e) => this.handleDeepBioDisplaySelect(e, annotation.id)}
                                />
            }else{
                // running / progress
                checkbox_ = <Tooltip title={deep_bio_result['fields']['status']} placement="top-start">
                                <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft:"-14px" }}>
                                    <CircularProgress variant="determinate" value={deep_bio_result['fields']['progress']} color={"secondary"} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary">
                                            {`${Math.round(deep_bio_result['fields']['progress'])}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Tooltip>
            }
            listItems.push(
                <div>
                    <ListItem
                        // key = {annotation.id+Math.random()}
                        // disablePadding
                        // disableGutters={true}
                        divider={true}
                        id={`li-${annotation.id}`}
                    >
                        {/*<ListItemIcon>*/}
                        {/*<Checkbox*/}
                        {/*    edge={"start"}*/}
                        {/*    checked={this.state.checkBoxSelected[annotation.id]}*/}
                        {/*    disableRipple*/}
                        {/*    size={'small'}*/}
                        {/*    key={annotation.id}*/}
                        {/*    icon={<CheckBoxOutlineBlankIcon/>}*/}
                        {/*    checkedIcon={<CheckBoxIcon color={"secondary"}/>}*/}
                        {/*    onChange={(e) => this.handleListSelect(e, annotation.id)}*/}
                        {/*/>*/}
                        {/*</ListItemIcon>*/}
                        {/*// shape icon*/}
                        {checkbox_}
                        <ListItemButton sx={{alignContent: 'right', width: '65%'}}
                                        onClick={(e) => this.locateOnMap(e, annotation)}>
                            {getAnnotationListIcon(annotation.tool_type, true)}
                            <ListItemText
                                id={annotation.id}
                                primary={annotation.title.substring(0, 7) + `${annotation.title.length > 7 ? ".." : ""}`}
                                sx={{marginLeft: '8px'}}
                            />
                        </ListItemButton>
                        <ListItemButton onClick={(e) => this.handleExpansionAndReverse(e, annotation.id)} >
                            {
                                this.state.expanded.indexOf(annotation.id) === -1
                                    ? <ExpandMore/>
                                    : <ExpandLess/>
                            }
                        </ListItemButton>
                    </ListItem>


                    <UnmountClosed key={annotation.id} isOpened={this.state.expanded.indexOf(annotation.id) !== -1}>
                        {
                            this.props.getExpandedComponent !== undefined ?
                                this.props.getExpandedComponent(annotation) :
                                this.getExpandedComponent(annotation)
                        }
                    </UnmountClosed>
                </div>
            )
        }
        console.log("listitem", listItems)
        return listItems;
    }

    changeFilterColor = (e) => {
        this.setState({
            colorFilter: e.target.value,
        })
    }

    getDefaultTopComponent = () => {
        let colors = AllAnnotationsConstants.COLORS_LIST;
        let menuItems = [];
        menuItems.push(
            <MenuItem value={"ALL"}>
                <Typography>ALL</Typography>
            </MenuItem>
        )
        colors.forEach(color =>
            menuItems.push(
                <MenuItem value={color.toLowerCase()}>
                    <CircleIcon fontSize="small" sx={{color:color.toLowerCase()}}/>
                </MenuItem>
            )
        )
        return <FormControl color="secondary" size="small" variant="filled" sx={{height:'3vh'}}>
            <FormGroup row={true} sx={{paddingTop:'0.2vh', marginLeft:'auto', marginRight:'auto', gap: 19}}>
                <Select value = {this.state.colorFilter} onChange={(e)=> {this.changeFilterColor(e)}}>
                    {menuItems}
                </Select>
                <AnnotationsDownload slideId={this.slideState.slide_data.id} annoDrawer={0} />
            </FormGroup>
        </FormControl>
    }

    resetCheckboxSelected = () => {
        this.setState({
            checkBoxSelected: {},
        })
    }

    getVirtualisedList = (annotations, openMultiSelectComp) => {

        let topBar;
        if(this.props.showTopBar){
            topBar = <Grid>
                {
                    openMultiSelectComp ?
                        this.props.getMultipleSelectComponent !== undefined ?
                            this.props.getMultipleSelectComponent(this.state.checkBoxSelected, this.resetCheckboxSelected) ://here add this.props.getMultipleSelectComponent with whatever args you need
                            this.getMultipleSelectComponent(annotations)
                        :
                        this.props.getDefaultTopComponent !== undefined ?
                            this.props.getDefaultTopComponent() :
                            this.getDefaultTopComponent()
                }
            </Grid>

        }else{
            topBar = null;
            if(this.props.app === "DeepBio"){
                topBar = this.props.uploadSlideComponent();
            }
        }

        return (
            <Box>
                {/*// top bar when any of the anno is selected*/}
                {topBar}
                <List>
                    {this.props.app === "DeepBio"? this.getAnnotationListDeepBio(annotations)
                        :this.getAnnotationsList(annotations)}
                </List>
            </Box>
        );
    }

    getFilteredAnnotationList = (annotations) => {
        if(this.state.colorFilter === "ALL"){
            return annotations;
        }
        else{
            return annotations.filter(anno => anno.color.toLowerCase() === this.state.colorFilter);
        }
    }

    render() {
        this.initState();

        if(this.state.annotations_list.length === 0 && this.props.app !== "DeepBio") {
            return <Grid>
                No Annotations Available
            </Grid>
        }

        let openMultiSelectComp = false
        let checkBoxSelected = this.state.checkBoxSelected;
        Object.entries(checkBoxSelected).forEach(([key, val]) => {
            if(val){
                openMultiSelectComp = true;
            }
        })
        let annotation_list = this.state.annotations_list;
        let filtered_annotation_list = this.getFilteredAnnotationList(annotation_list)
        let listDiv = this.getVirtualisedList(filtered_annotation_list, openMultiSelectComp);
        return <Grid>
            {listDiv}
        </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
        deepBioData: state.deepBioReducer,
    }
}

export default connect(mapStateToProps)(AnnotationsListGeneric)
