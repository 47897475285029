import React, {Component, useCallback} from 'react';
import {Col, Row, Input, Icon, Divider, Table, message} from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import {connect} from 'react-redux'
import { updateSlide } from '../../../action/slide.update.action';
import { updateZStackParams } from '../../../action/morpheus.state.action';
import EditableTagGroup from '../../viewer/viewer.tags'
import axios from 'axios';
import cookie from "react-cookies";
import { Link } from 'react-router-dom';

import '../../../asset/style/neoviewer/slide_info.css'
import { globalUrlPrefix, slideViewerType } from '../../../utils/const';
import { getPrefixedUrl } from '../../../utils/utils';
import { AuthHeader } from '../../../helper/auth.token';

const { TextArea } = Input;

class SlideInfoApp extends Component {
    constructor(props){
        super(props);
        this.state = {
            data :  this.props.slideData,
            edit : false,
            name : this.props.slideData.name,
            specimen_type :this.props.slideData.specimen_type,
            case_details : (this.props.slideData.case_details === null || this.props.slideData.case_details === "")?"NIL":this.props.slideData.case_details,
            scan_time: 0.0,
            slide_meta: {}, 
            size_meta: {},
            slide_time: new Date(this.props.slideData.date_time),
            slide_details : this.props.slideData.slide_details,
            cpu_wait_ms: null
        }
        this.editFields = this.editFields.bind(this)
        this.saveFields = this.saveFields.bind(this)
        this.onChangeCaseDetails = this.onChangeCaseDetails.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeSpecimenType = this.saveFields.bind(this)
    }

    fetchCpuWait = () => {
        let url = getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/cpu_wait.json', this.state.data);
        axios.get(url).then(response => {
            if (response.data !== undefined && response.data["cpu_wait_time_ms"] !== undefined) {
                this.setState({
                    cpu_wait_ms: response.data["cpu_wait_time_ms"]
                });
            }
        })
        .catch(err => {
          console.log("Failed request for cpu_wait.json", err);
        });
    }

    fetchScanMeta = () => {
        let url = getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/slide_meta.json', this.state.data);
        axios.get(url)
        .then(response => {
            this.setState({
                slide_meta: response.data
            });
            this.props.dispatch(updateZStackParams(response.data.takeZStack, response.data.numZLevels, response.data.takeBidirectionalZStack));
        })
        .catch(err => {
          console.log("Failed request", err);
        });
    }

    fetchSizeMeta = () => {
        let url = getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/size_meta.json', this.state.data);
        axios.get(url)
        .then(response => {
            this.setState({
                size_meta: response.data
            });
        })
        .catch(err => {
          console.log("Failed request", err);
        });
    }

    componentDidMount = () => {
        if (cookie.loadAll().is_staff === "true") {
            this.fetchSizeMeta();
            this.fetchCpuWait();
        }
        this.fetchScanMeta();
    }

    editFields(){
        this.setState({
            edit : true,
            tagedit : true
        })
    }


    saveFields() {
        let val = {
            "morphle_id" : this.props.slideData.morphle_id,
            "path": this.props.slideData.path,
            "date": this.props.slideData.date,
            "specimen_type" : this.props.slideData.specimen_type,
            "name" : this.state.name, 
            "case_details" : this.state.case_details, 
            "slide_details": this.state.slide_details
        };
        this.props.dispatch(updateSlide(this.props.slideData.id ,val ))
        this.setState({
            edit : false,
            tagedit : false
        })
    }

    onChangeName(e){
        this.setState({
            name : e.target.value
        })
    }

    onChangeSpecimenType(e){
        this.setState({
            specimen_type : e.target.value
        })
    }

    onChangeCaseDetails(e){
        this.setState({
            case_details : e.target.value
        })
    }

    redoStitching = () => {
        axios.get('/server/redo_stitching/?morphle_id=' + this.state.data.morphle_id)
        .then(response => {
            message.success("Scheduled slide for Stitching")
        })
        .catch(err => {
            message.error("Could not schedule slide for Stitching")
        })
    }

    changeSlideDetails = (event) => {
        this.setState({
            slide_details: event.target.value
        });
    }

    render() {

        const columns = [
            {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: text => <b>{text}</b>,
            },
            {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            }
        ];

        let slide_info = [];
        
        if (this.state.slide_meta.scan_speed !== undefined) {
            slide_info.push({
                key: 'Scan Speed',
                value: this.state.slide_meta.scan_speed,
            });
        }

        if (cookie.loadAll().is_staff === "true") {
            slide_info.push(
                {
                key: 'Scan Time',
                value: this.state.slide_meta.scan_time,
                },
                // {
                // key: 'Total Scan Size (MB)',
                // value: (this.state.size_meta.total_size / (1024 * 1024)).toFixed(0),
                // },
                {
                key: 'Archived Full Size (MB)',
                value: (this.state.size_meta.size_40x / (1024 * 1024)).toFixed(0),
                },
                {
                key: 'Archived Half Size (MB)',
                value: (this.state.size_meta.size_20x / (1024 * 1024)).toFixed(0),
                },
                {
                key: 'Area of Box Scanned',
                value: parseFloat((this.state.slide_meta.scan_area_mm2 * 100 / this.state.slide_meta.scannable_percentage).toFixed(2)),
                }
            )
        }

        if (cookie.loadAll().superuser === "true") {
            slide_info.push(
                {
                    key: 'Morphle ID', 
                    value: this.props.slideData.morphle_id
                },
                {
                    key: 'CPU Wait (in ms)', 
                    value: (this.state.cpu_wait_ms !== null ? this.state.cpu_wait_ms.toString() : "Failed to Load")
                },
                {
                    key: 'Scan Time without CPU waiting time', 
                    value: ((this.state.slide_meta.scan_time_raw - this.state.slide_meta.cpu_waiting_time)/60000).toFixed(2).toString()  + '/' + 
                    ((this.state.slide_meta.scan_time_raw)/60000).toFixed(2).toString() ,
                },
                {
                    key: 'Strip Hertz', 
                    value: this.state.slide_meta.scan_strip_hertz,
                },
                {
                    key: 'Estimated Time for 15x15', 
                    value: this.state.slide_meta['estimated_time_for_15x15 (225mm2)']
                }, 
                {
                    key: 'Scan Area (mm2)', 
                    value: this.state.slide_meta.scan_area_mm2
                }, 
                {
                    key: 'Focus Time', 
                    value: this.state.slide_meta.total_focus_time
                }, 
                {
                    key: 'Translation Time', 
                    value: this.state.slide_meta.total_translation_time
                }, 
                {
                    key: 'Compensation Time', 
                    value: this.state.slide_meta.total_compensation_time
                }, 
                {
                    key: 'Scannable Percentage', 
                    value: this.state.slide_meta.scannable_percentage
                }, 
                {
                    key: 'Total Images', 
                    value: this.state.slide_meta.total_images
                }, 
                {
                    key: 'Swap Count', 
                    value: this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
                        'See Tile Viewer' : this.props.tileViewerSlideMetaData != undefined ? 
                            this.props.tileViewerSlideMetaData.swap_meta_count : ""
                }, 
                {
                    key: 'Swap Percent', 
                    value: this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
                        'See Tile Viewer' : this.props.tileViewerSlideMetaData != undefined ? 
                            this.props.tileViewerSlideMetaData.swap_meta_percent : ""
                }, 
                {
                    key: 'Rescan Time', 
                    value: this.state.slide_meta.rescan_time
                }, 
                {
                    key: 'Rescan Translation Time', 
                    value: this.state.slide_meta.rescan_translation_time
                }, 
                {
                    key: 'Rescan Focus Time', 
                    value: this.state.slide_meta.rescan_focus_time
                }, 
                {
                    key: 'Rescan Percent', 
                    value: this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
                        'See Tile Viewer' : this.props.tileViewerSlideMetaData != undefined ? 
                            this.props.tileViewerSlideMetaData.rescan_percent : ""
                },
                {
                    key: 'Tilt Angle', 
                    value: this.state.slide_meta.tilt_angle
                }, 
                {
                    key: 'Tile Detection Time', 
                    value: this.state.slide_meta.tilt_detection_time
                },  
            )
        }

        let slide_info_card = [
            cookie.loadAll().superuser === "true" || cookie.loadAll().staff === "true"?(
                <Row key="slide-info-details">
                    <h4 className='slide-info-mp' style={{textAlign : 'center', margin: "5px"}}>Scan Details</h4>
                    <Divider />
                    <Table className="slide-info-table scrollbar" columns={columns} dataSource={slide_info} pagination={false} showHeader={false}/>
                </Row>
            ):<div></div>
        ];

        let slide_links_card = [
            cookie.loadAll().superuser === "true"?(
            <Row key={0}>
                <h4 className='slide-info-mp' style={{textAlign : 'center', margin: "5px"}}>Important Links</h4>
                <Divider />
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/slide_meta.json', this.state.data)}>Slide Meta</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/device_settings.json', this.state.data)}>Settings</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/autofocus_metrics.json', this.state.data)}>Autofocus Metrics</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/scan_metrics.json', this.state.data)}>Scan Metrics</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/stage_metrics.json', this.state.data)}>Stage Metrics</a></Row>
                {/* <Row><a href={'/v' + this.state.data.viewer_flag + '/tissue/' + this.state.data.morphle_id}>Old Viewer</a></Row> */}
                {this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ?
                    <Row><a href={'/v' + this.state.data.viewer_flag + '/tissue/' + this.state.data.morphle_id}>Old Viewer</a></Row>
                    : null
                }
                {this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ?
                    <Row><a href={'/neo/tileviewer/' + this.props.slideData.id + '/'}>Tile Viewer</a></Row>
                    :null
                }
                {this.props.viewerType == slideViewerType.TILE_SLIDE_VIEWER ?
                    <Row><a href={'/neo/viewer/' + this.props.slideData.id + '/'}>Slide Viewer</a></Row>
                    :null    
                }
                <Row onClick={this.redoStitching}><a>Redo Stitching</a></Row>
            </Row>):<div></div>
        ];

        let slide_description = [
            <Row key="slide-description">
                <h4 className='slide-info-mp' style={{textAlign : 'center', margin: "5px"}}>Description</h4>
                <Divider />
                {this.state.edit ?
                    <TextArea 
                        autosize={cookie.loadAll().superuser === "true" || cookie.loadAll().staff === "true" ? { minRows: 6, maxRows: 6 } : { minRows: 14, maxRows: 14 }} 
                        placeholder="Enter the slide description" 
                        value={this.state.slide_details} 
                        onChange={this.changeSlideDetails} 
                    />
                    : 
                    <div className={cookie.loadAll().superuser === "true" || cookie.loadAll().staff === "true" ? "slide-description-scroll-superuser" : "slide-description-scroll"}>
                        {this.state.slide_details == "" || this.state.slide_details == null ?
                            "No Description Added" :
                            this.state.slide_details
                        }
                    </div>
                }
                <Divider />
            </Row>
        ];

        return (
            <div>
            <Row>
                <Col span={24} className={cookie.loadAll().superuser === "true" ? "slide-info-app-parent-superuser overlayed-component app-parent" : "slide-info-app-parent overlayed-component app-parent"}>
                    {(this.props.urlState || {}).presentCode != undefined || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience ?
                        <br/> :
                        <span style={{float :'right'}}>{!this.state.edit ? <EditOutlined className='icon-mp' onClick={this.editFields} /> : <SaveOutlined className='icon-mp' onClick={this.saveFields} />}</span>
                    }
                    <div className="slide-info-text" >
                        <Col className={cookie.loadAll().superuser === "true" ? "scroll" : "scroll-slide-info"} sm={{ span: 24, offset: 0 }} mg={{ span: 24, offset: 0}} lg={{ span: 24, offset: 0 }}>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Name</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>{!this.state.edit ? <span>{this.state.name}</span> : <Input defaultValue={this.state.name} onChange={this.onChangeName} /> }</Col>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Scan Type</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>{this.state.specimen_type}</Col>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Tags</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>
                                <EditableTagGroup tag={this.props.slideData.tags} 
                                    edit={this.state.tagedit} 
                                    morphle_id={this.props.slideData.morphle_id} 
                                    path={this.props.slideData.path}
                                    date={this.props.slideData.date}
                                    specimen_type={this.props.slideData.specimen_type}
                                    name={this.state.name}
                                    case_details={this.state.case_details}
                                    slide_id={this.props.slideData.id}
                                />
                            </Col>
                            <Divider style={{top: "5px"}}/>
                            {slide_description}
                            {slide_links_card}
                            {slide_info_card}
                        </Col>
                    </div>
                </Col>
            </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus,
        urlState: state.viewerUrlReducer
    }
}

export default connect(mapStateToProps)(SlideInfoApp, EditableTagGroup)
