import React, {Component} from 'react';
import {message} from 'antd';
import {initSlideData, updateSlideData} from "../../../action/maps.state.action";
import EditableTagGroup from '../../viewer/viewer.tags';
import axios from 'axios';
import cookie from "react-cookies";

import '../../../asset/style/neoviewer/slide_info.css';
import {globalUrlPrefix, slideViewerType} from '../../../utils/const';
import { getPrefixedUrl } from '../../../utils/utils';
import {
	Divider,
	Grid,
	Input,
	Paper,
	Table,
	TableBody, TableCell,
	TableContainer,
	TableRow,
	TextField
} from "@mui/material";
import {connect} from "react-redux";
import {ModeEditOutlined, SaveOutlined} from "@mui/icons-material";

class SlideInfoApp extends Component {
	constructor(props){
		super(props);
		this.initState();
		this.state = this.getInitialState();
		this.editFields = this.editFields.bind(this)
		this.saveFields = this.saveFields.bind(this)
		this.onChangeCaseDetails = this.onChangeCaseDetails.bind(this)
		this.onChangeName = this.onChangeName.bind(this)
		this.onChangeSpecimenType = this.saveFields.bind(this)
	}

	initState = () => {
		let prevActiveMapId = this.activeMapId;
		let prevSlideState = this.slideState;
		this.activeMapId = this.props.activeMapId;
		this.slideState = this.props.mapsState[this.activeMapId].slideState;
		if (this.slideState && this.activeMapId !== prevActiveMapId)
			this.props.dispatch(initSlideData(this.activeMapId, this.slideState.slide_data.id));

		if (this.slideState && this.slideState !== prevSlideState)
			this.setState(this.getInitialState());
	}

	getInitialState = () => ({
		data: this.slideState.slide_data,
		edit: false,
		name: this.slideState.slide_data.name,
		specimen_type: this.slideState.slide_data.specimen_type,
		case_details: (this.slideState.slide_data.case_details === null ||
			this.slideState.slide_data.case_details === "") ? "NIL" : this.slideState.slide_data.case_details,
		scan_time: 0.0,
		size_meta: {},
		slide_time: new Date(this.slideState.slide_data.date_time),
		slide_details: this.slideState.slide_data.slide_details,
	});

	editFields(){
		this.setState({
			edit : true,
			tagedit : true
		})
	}


	saveFields() {
		this.props.dispatch(updateSlideData(this.props.activeMapId, this.slideState.slide_data.id, {
			name : this.state.name,
			case_details : this.state.case_details,
			slide_details: this.state.slide_details
		}));
		this.setState({
			edit : false,
			tagedit : false
		});
	}

	onChangeName(e){
		this.setState({
			name : e.target.value
		})
	}

	onChangeSpecimenType(e){
		this.setState({
			specimen_type : e.target.value
		})
	}

	onChangeCaseDetails(e){
		this.setState({
			case_details : e.target.value
		})
	}

	redoStitching = () => {
		axios.get('/server/redo_stitching/?morphle_id=' + this.state.data.morphle_id)
			.then(response => {
				message.success("Scheduled slide for Stitching")
			})
			.catch(err => {
				message.error("Could not schedule slide for Stitching")
			})
	}

	changeSlideDetails = (event) => {
		this.setState({
			slide_details: event.target.value
		});
	}



	render() {
		this.initState();
		console.log(this.slideState.slide_data.gamma)
		if (!this.slideState) return <div />;
		const createTableRow = (key, value) =>
			<TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell component={"th"} scope={"row"}>{key}</TableCell>
				<TableCell align={"right"}>{value}</TableCell>
			</TableRow>;

		let slide_info_card = [
			cookie.loadAll().superuser === "true" || cookie.loadAll().staff === "true"?(
				<Grid container key="slide-info-details">
					<Grid item xs={12}>
						<Divider style={{margin: "10px"}} />
						<h4 style={{color: 'white', textAlign : 'center', margin: "5px"}}>Scan Details</h4>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Paper}>
							<Table>
								<TableBody>
									{this.slideState.slide_data.exposure ?
										createTableRow("Exposure", this.slideState.slide_data.exposure) :
										undefined
									}{this.slideState.slide_data.gamma ?
										createTableRow("Gamma", this.slideState.slide_data.gamma) :
										undefined
									}
									{this.slideState.slide_meta.scan_speed ?
										createTableRow("Scan Speed", this.slideState.slide_meta.scan_speed) :
										undefined
									}
									{cookie.loadAll().is_staff === "true" ? [
										createTableRow("Scan Time", this.slideState.slide_meta.scan_time),
										createTableRow(
											"Archived Full Size (MB)",
											(this.state.size_meta.size_40x / (1024 * 1024)).toFixed(0)
										),
										createTableRow(
											'Archived Half Size (MB)',
											(this.state.size_meta.size_20x / (1024 * 1024)).toFixed(0)
										),
										createTableRow(
											'Area of Box Scanned',
											parseFloat(
												(this.slideState.slide_meta.scan_area_mm2 * 100 /
													this.slideState.slide_meta.scannable_percentage).toFixed(2)
											)
										)] : undefined
									}
									{cookie.loadAll().superuser === "true" ? [
										createTableRow('Morphle ID', this.slideState.slide_data.morphle_id),
										createTableRow('CPU Wait Time', this.slideState.slide_meta.cpu_waiting_time),
										createTableRow(
											'Scan Time without CPU waiting time',
											((this.slideState.slide_meta.scan_time_raw -
												this.slideState.slide_meta.cpu_waiting_time) / 60000).toFixed(2)
												.toString() + '/' + ((this.slideState.slide_meta.scan_time_raw) / 60000)
												.toFixed(2).toString()
										),
										createTableRow('Strip Hertz', this.slideState.slide_meta.scan_strip_hertz),
										createTableRow(
											'Estimated Time for 15x15',
											this.slideState.slide_meta['estimated_time_for_15x15 (225mm2)']
										),
										createTableRow('Scan Area (mm2)', this.slideState.slide_meta.scan_area_mm2),
										createTableRow('Focus Time', this.slideState.slide_meta.total_focus_time),
										createTableRow(
											'Translation Time',
											this.slideState.slide_meta.total_translation_time
										),
										createTableRow(
											'Compensation Time',
											this.slideState.slide_meta.total_compensation_time
										),
										createTableRow(
											'Scannable Percentage',
											this.slideState.slide_meta.scannable_percentage
										),
										createTableRow('Total Images', this.slideState.slide_meta.total_images),
										createTableRow(
											'Swap Count',
											this.props.viewerType === slideViewerType.GAMMA_SLIDE_VIEWER ?
												'See Tile Viewer' :
												this.props.tileViewerSlideMetaData ?
													this.props.tileViewerSlideMetaData.swap_meta_count :
													""
										),
										createTableRow(
											'Swap Percent',
											this.props.viewerType === slideViewerType.GAMMA_SLIDE_VIEWER ?
												'See Tile Viewer' : this.props.tileViewerSlideMetaData !== undefined ?
													this.props.tileViewerSlideMetaData.swap_meta_percent :
													""
										),
										createTableRow('Rescan Time', this.slideState.slide_meta.rescan_time),
										createTableRow(
											'Rescan Translation Time',
											this.slideState.slide_meta.rescan_translation_time
										),
										createTableRow('Rescan Focus Time', this.slideState.slide_meta.rescan_focus_time),
										createTableRow(
											'Rescan Percent',
											this.props.viewerType === slideViewerType.GAMMA_SLIDE_VIEWER ?
												'See Tile Viewer' :
												this.props.tileViewerSlideMetaData ?
													this.props.tileViewerSlideMetaData.rescan_percent :
													""
										),
										createTableRow('Tilt Angle', this.slideState.slide_meta.tilt_angle),
										createTableRow(
											'Tile Detection Time',
											this.slideState.slide_meta.tilt_detection_time
										)] : undefined
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			) : undefined
		];

		let slide_links_card = [
			cookie.loadAll().superuser === "true" ? (
				<Grid container key={"important-links"}>
					<Grid item xs={12}>
						<Divider style={{margin: "10px"}} />
						<h3 style={{color:"white", textAlign : 'center', marginTop: "10px"}}>Important Links</h3>
					</Grid>
					<Grid container>
						<Grid item xs={6}><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/slide_meta.json', this.state.data)}>Slide Meta</a></Grid>
						<Grid item xs={6}><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/device_settings.json', this.state.data)}>Settings</a></Grid>
						<Grid item xs={6}><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/autofocus_metrics.json', this.state.data)}>Autofocus Metrics</a></Grid>
						<Grid item xs={6}><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/scan_metrics.json', this.state.data)}>Scan Metrics</a></Grid>
						<Grid item xs={6}><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/stage_metrics.json', this.state.data)}>Stage Metrics</a></Grid>
						{this.props.viewerType === slideViewerType.GAMMA_SLIDE_VIEWER ?
							<Grid item xs={6}>
								<a href={'/v' + this.state.data.viewer_flag + '/tissue/' + this.state.data.morphle_id}>
									Old Viewer
								</a>
							</Grid>
							: undefined
						}
						{this.props.viewerType === slideViewerType.GAMMA_SLIDE_VIEWER ?
							<Grid item xs={6}>
								<a href={'/' + globalUrlPrefix + '/tileviewer/' + this.slideState.slide_data.id + '/'}>
									Tile Viewer
								</a>
							</Grid>
							: null
						}
						{this.props.viewerType === slideViewerType.GAMMA_SLIDE_VIEWER ?
							<Grid item xs={6}>
								<a href={'/' + globalUrlPrefix + '/viewer/' + this.slideState.slide_data.id + '/'}>
									Neo Viewer
								</a>
							</Grid>
							: null
						}
						<Grid item xs={6}><a onClick={this.redoStitching}>Redo Stitching</a></Grid>
					</Grid>

				</Grid>
			) : undefined
		];

		let slide_description = [
			<Grid container key="slide-description">
				<Grid item xs={12}>
					<Divider style={{margin: "10px"}} />
					<h3 style={{color: "white", textAlign : 'center', marginTop: '10px'}}>Description</h3>
				</Grid>
				<Grid item xs={12}>
					{this.state.edit ?
						<TextField
							multiline
							placeholder="Enter the slide description"
							value={this.state.slide_details}
							rows={3}
							color='secondary'
							margin='normal'
							variant="filled"
							onChange={this.changeSlideDetails}
							sx={{my: 1, backgroundColor:'#0b1c3b', justifyContent:'center', width:'100%'}}
						/>
						:
						<div className={cookie.loadAll().superuser === "true" || cookie.loadAll().staff === "true" ? "slide-description-scroll-superuser" : "slide-description-scroll"}>
							{this.state.slide_details === "" || this.state.slide_details === null ?
								"No Description Added" :
								this.state.slide_details
							}
						</div>
					}
				</Grid>
			</Grid>
		];

		return (
			<Grid container spacing={1}>
				<Grid flex={1}>
					{(this.props.urlState || {}).presentCode !== undefined || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience ?
						<br/> :
						<span style={{float :'right'}}>{!this.state.edit ? <ModeEditOutlined onClick={this.editFields} /> : <SaveOutlined onClick={this.saveFields} />}</span>
					}
				</Grid>

				<Grid container>
					<Grid item xs={5} style={{fontWeight : 700}}>Name</Grid>
					<Grid item xs={7} style={{fontWeight : 300}}>{!this.state.edit ? <span>{this.state.name}</span> :
						<Input defaultValue={this.state.name} color='secondary' margin='normal' variant="filled"
						       size="small" onChange={this.onChangeName}
						       sx={{my: 1, backgroundColor:'#0b1c3b', justifyContent:'center'}} /> }
					</Grid>
					<Grid item xs={5} style={{fontWeight : 700}}>Scan Type</Grid>
					<Grid item xs={7} style={{fontWeight : 300}}>{this.state.specimen_type}</Grid>
					<Grid item xs={5} style={{fontWeight : 700}}>Tags</Grid>
					<Grid item xs={7} style={{fontWeight : 300}}>
						<EditableTagGroup tag={this.slideState.slide_data.tags}
						                  edit={this.state.tagedit}
						                  morphle_id={this.slideState.slide_data.morphle_id}
						                  path={this.slideState.slide_data.path}
						                  date={this.slideState.slide_data.date}
						                  specimen_type={this.slideState.slide_data.specimen_type}
						                  name={this.state.name}
						                  case_details={this.state.case_details}
						                  slide_id={this.slideState.slide_data.id}
						/>
					</Grid>
				</Grid>
				{slide_description}
				{slide_links_card}
				{slide_info_card}
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return{
		urlState: state.viewerUrlReducer,
		activeMapId: state.gammaStateReducer.activeMapId,
		mapsState: state.mapsStateReducer,
	}
}

export default connect(mapStateToProps)(SlideInfoApp, EditableTagGroup)
