import {
    CLEAR_NOTIFICATION,
    PUSH_NOTIFICATION,
    UPDATE_APP_INSTALLED_STATE,
    UPDATE_GAMMA_STATE,
    UPDATE_LAST_MAP_COUNT,
} from "../actionTypes/gamma.state.const";
import {updateAppsInstalledinDB} from "../action/gamma.state.action";

const InitialState = {
    notifications: [],
    appsInstalled: {},
    keyboardShortcuts: {},
    keyboardShortcutsEnable: false,
    morpheusSettingInitialised: false,
    activeMapId: '0',
    activeAnnoDrawer: 0,
    sync: false,
    syncId: -1,
    syncQueue: 0,
    lastMapCount: 0,
}

export const gammaStateReducer = (state = InitialState, action) => {
    switch (action.type) {

        case UPDATE_GAMMA_STATE:
            return {
                ...state,
                ...action.state,
            }

        case PUSH_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    action.notification,
                    ...state.notifications.filter(notification => notification.props.id !== action.notification.id),
                ],
            }

        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications.filter(notification => notification.props.id !== action.id)],
            }

        case UPDATE_APP_INSTALLED_STATE:
            let newAppsInstalled = {
                ...state.appsInstalled,
                [action.appKey]: action.installedStatus,
            };
            updateAppsInstalledinDB(newAppsInstalled);
            return {
                ...state,
                appsInstalled: newAppsInstalled,
            };

        case UPDATE_LAST_MAP_COUNT:
            return {
                ...state,
                lastMapCount: state.lastMapCount + 1,
            }

        default:
            return state;
    }
}
