import { CheckCircleOutlined, LoadingOutlined, WarningOutlined, WarningTwoTone } from '@ant-design/icons';
import { Col, Row, Tooltip, Spin, Button } from 'antd';
import React, { Component } from 'react';
import { initPreviewForScan } from "../../../action/preview_status.action";
import { connect } from "react-redux";
import { deviceType, cassetteType } from '../../../utils/const';
import Title from 'antd/lib/typography/Title';


class LoadSlidesPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        if (this.props.cassetteSize == 1 || this.props.cassetteSize == 2) {
            this.props.handleProgressChange(1);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.previews == undefined && this.props.previews) {
            this.props.dispatch(initPreviewForScan());
        }
    }

    render() {
        let _this = this;
        return (
                <Row key={0} className="rounded-container load-slides-message-div" style={{height: '500px'}}>
                    {this.props.deviceType === deviceType.MAXIMUS && this.props.detectingCassette ?
                        <Col className="scan-workflow-init" span={24}>
                            <Row style={{ fontSize: 20 }} className="arial-font">
                                <div>
                                    <Row><LoadingOutlined /></Row>
                                    <Row>
                                        <Title level={3}>Detecting Cassette</Title>
                                    </Row>
                                    <br />
                                    <Row>
                                        Please wait for a few seconds.
                                    </Row>
                                </div>
                            </Row>
                        </Col>
                        : this.props.deviceType === deviceType.MAXIMUS && this.props.askUserCassetteType ? 
                        <Col className="scan-workflow-init" span={24}>
                            <Row style={{ fontSize: 20 }} className="arial-font">
                                <div>
                                    <Row>
                                        <Title level={3}>Which Cassette have you inserted?</Title>
                                    </Row>
                                    <br />
                                    <Row>Please make sure the Cassette Type is correct.</Row>
                                    <Row><WarningTwoTone twoToneColor="#FFA400" /> A mismatch can lead to slide, cassette or scanner damage.</Row>
                                    <br />
                                    <Row>
                                        <Button onClick={(e) => {e.stopPropagation(); this.props.setCassetteType(cassetteType.SIX_1_BY_3_SLIDES);}}>1x3 Slides Cassette</Button>
                                        <Button onClick={(e) => {e.stopPropagation(); this.props.setCassetteType(cassetteType.FOUR_2_BY_3_SLIDES);}}>2x3 Slides Cassette</Button>
                                        <Button onClick={(e) => {e.stopPropagation(); this.props.setCassetteType(cassetteType.ONE_6_BY_8_SLIDE);}}>6x8 Slide Cassette</Button>
                                    </Row>
                                </div>
                            </Row>
                        </Col>
                        : 
                        <Col className="scan-workflow-init" span={24}>
                            <Row style={{ fontSize: 20 }} className="arial-font">
                                {(this.props.cassetteSize == 1 || this.props.cassetteSize == 2) ? 
                                    "Please click done to view preview." :
                                    <div>
                                        <Row>
                                            Please insert slides in the Cassette
                                        </Row>
                                        <br />
                                        <Row>
                                            and
                                        </Row>
                                        <br />
                                        <Row>
                                            Place the Cassette back in the slot.
                                        </Row>
                                    </div>
                                }
                            </Row>
                            <br></br>
                            <br></br>
                            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Tooltip title="Click here when done" placement="rightTop">
                                    <Col span={4} className="promt-done-button" onClick={(e) => {
                                        e.stopPropagation(); 
                                        if (_this.props.deviceType === deviceType.MAXIMUS) {
                                            // this.props.setDetectingCassette(true);
                                        } 
                                        this.props.handleProgressChange(1);
                                        if (_this.props.deviceType === deviceType.MAXIMUS) {
                                            // this.props.setDetectingCassette(false);
                                        }
                                        }}>
                                        <Row style={{ color: "#26A65B" }}>
                                            <CheckCircleOutlined style={{ fontSize: 50 }}/>
                                        </Row>
                                        <Row className="arial-font">Done</Row>
                                    </Col>
                                </Tooltip>
                            </Row>
                        </Col>
                     }
                </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews : state.previewStatusReducer,
    };
};

export default connect(mapStateToProps)(LoadSlidesPrompt);