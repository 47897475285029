import React, {Component} from  "react";
import {connect} from 'react-redux';
import {BottomNavigation, BottomNavigationAction, Box, Checkbox, Grid, Stack} from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import {getAllLayers} from "../utils/layers_info";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import {styleFunction} from '../utils/annotations_app_utils'
import Select from "ol/interaction/Select.js";
import {click} from 'ol/events/condition.js';
import {reloadMagicToolData, updateCurrentLevel} from "../../../action/magic_tool.action";
import {deleteAnnotation} from "../../../action/maps.state.action";

class MapLayersMaker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openLayersPanel: false,
            layers_list: [],
        }
        this.initVectorFlag = true;
        this.initState();
    }

    initState() {
        if (this.activeMapId && this.activeMapId !== this.props.activeMapId)
            this.removeSelectInteractions();

        this.activeMapId = this.props.gammaState.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
        this.annotationState = this.mapState.annotationState;
        this.deepBioState = this.mapState.deepBioState;
    }

    componentDidMount() {
        this.initialiseVectorLayersAndStore();
        document.addEventListener("keydown", this.keyDownListener, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDownListener, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // TODO: move it to slidemap
        if (prevProps.lastMapCount !== this.props.lastMapCount || prevProps.activeMapId !== this.activeMapId)
            this.initVectorFlag = true;

        if (this.initVectorFlag && this.slideState) {
            this.initVectorFlag = false;
            this.initialiseVectorLayersAndStore();
        }

        let prevAnnotationState = prevProps.mapsState[this.activeMapId].annotationState;
        if (this.annotationState && (prevAnnotationState || {}).features !== this.annotationState.features) {
            let features = this.annotationState.features;
            if (this.selectedAnnotationId && this.selectAnnotationOnMap &&
                !features.map(feature => feature.getId()).includes(this.selectedAnnotationId))
                this.selectAnnotationOnMap.getFeatures().clear();
            this.annotationState.vector.setSource(
                new VectorSource({
                    features: features,
                    wrapX: false,
                })
            );
            this.annotationState.vector.setStyle(styleFunction);
        }

        let prevDeepBioState = prevProps.mapsState[this.activeMapId].deepBioState;
        if (this.deepBioState && (prevDeepBioState || {}).features !== this.deepBioState.features) {
            let features = this.deepBioState.features;
            this.deepBioState.vector.setSource(
                new VectorSource({
                    features: features,
                    wrapX: false
                })
            );
            this.deepBioState.vector.setStyle(styleFunction)
        }

        if (!prevProps.magicToolData.reload && this.props.magicToolData.reload) {
            let features = this.annotationState.features;
            let start = this.props.magicToolData.start_point;
            let current = this.props.magicToolData.curr_point;
            let delta = Math.abs(Math.pow((current[0]-start[0]),2)+Math.pow((current[1]-start[1]),2))
            let index = parseInt(delta/150)
            index = Math.min(index, Math.max(this.props.magicToolData.unsaved_magic_annotations.length-1,0))
            let temp_features = this.props.magicToolData.unsaved_magic_annotations[index];
            let all_feats = features
                // .concat(temp_features);
            if(temp_features !== undefined) {
                all_feats = all_feats.concat(temp_features);
            }
            this.annotationState.vector.setSource(
                new VectorSource({
                    features: all_feats,
                    wrapX: false
                })
            );
            this.annotationState.vector.setStyle(styleFunction)
            this.props.dispatch(reloadMagicToolData(false));
            this.props.dispatch(updateCurrentLevel(index));
        }
    }

    keyDownListener = (event) => {
        if (event.target.tagName === 'INPUT' || event.shiftKey || event.ctrlKey || event.altKey)
            return;
        switch (event.key) {
            case "Escape":
                if (this.selectAnnotationOnMap)
                    this.selectAnnotationOnMap.getFeatures().clear();
                break;
            case "Delete":
                if (this.selectedAnnotationId)
                    this.props.dispatch(deleteAnnotation(this.activeMapId, this.selectedAnnotationId));
                break;
            default:
                break;
        }
    }

    // TODO: Move it to slidemap
    initialiseVectorLayersAndStore = () => {
        let slidemap = this.slideState.slidemap;
        if ((this.annotationState || {}).vector)
            slidemap.removeLayer(this.annotationState.vector);
        if ((this.deepBioState || {}).vector)
            slidemap.removeLayer(this.deepBioState.vector);
        let layers = getAllLayers(this.props.gammaState).filter(layer => layer.permission);
        let layers_list= [];
        for(let i=0; i<layers.length; i++){
            let vector = new VectorLayer({
                source: new VectorSource({}),
                name: layers[i].map_name,
            });
            let x_fields = this.slideState.slide_data.x_fields;
            let y_fields = this.slideState.slide_data.y_fields;
            let zIndex = (x_fields * 10000) + y_fields + 1000000000;
            vector.setZIndex(zIndex);
            slidemap.addLayer(vector);

            this.props.dispatch(layers[i].store(this.activeMapId, vector));
            if(layers[i].loadDataOnRender)
                this.props.dispatch(layers[i].dataLoad(this.activeMapId, this.slideState.slide_data.id));

            let selectAnnotationOnMap = new Select({
                    condition: click,
                    layers: [vector,],
                    wrapX: false,
                    hitTolerance: 5,
            })
            if(layers[i].selectInteraction)
                slidemap.addInteraction(selectAnnotationOnMap);

            selectAnnotationOnMap.on("select", e=> {
                if (e.selected[0] && (!e.deselected[0] || e.selected[0].getId() !== e.deselected[0].getId() ||
                        e.selected[0].getId() !== this.selectedAnnotationId)) {
                    let args = JSON.parse(JSON.stringify(layers[i].onSelectAnnotationOnMapArgs));
                    args.push(e.selected[0].getId());
                    this.selectedAnnotationId = e.selected[0].getId();
                    layers[i].onSelectAnnotationOnMap.apply(null, args);
                }
                else {
                    selectAnnotationOnMap.getFeatures().clear();
                }
                this.selectAnnotationOnMap = selectAnnotationOnMap;
            });

            layers_list.push(
                <Grid container sx={{height:'20px'}} sm={12}>
                    <Grid item>
                        <Checkbox
                            icon={<VisibilityIcon sx={{color: '#8a8a8a'}} fontSize={'small'}/>}
                            checkedIcon={<VisibilityIcon color={'secondary'} fontSize={'small'}/>}
                            onChange={(e) => {
                                this.handleVectorLayerChange(e, vector)
                            }}
                            defaultChecked
                        />
                    </Grid>
                    <Grid item>
                        <Typography sx={{marginTop:'7px'}}>
                            {layers[i].name}
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
        this.setState({
            layers_list: layers_list,
        })
    }

    removeSelectInteractions = () => {
        this.slideState.slidemap.getInteractions().forEach(interaction => {
            if (interaction instanceof Select)
                this.slideState.slidemap.removeInteraction(interaction);
        });
    }

    handleVectorLayerChange = (e, vector) => {
        let slidemap = this.slideState.slidemap
        if(e.target.checked) {
            slidemap.addLayer(vector)
        }
        else {
            slidemap.removeLayer(vector)
        }
    }

    render() {
        this.initState();
        if (!this.slideState) return <div />;

        let layersOverlayComponent;
        if(this.state.openLayersPanel) {
            layersOverlayComponent = <div>

                <Box sx={{height:'20vh', width:'10vw', border:'5px',bgcolor: "rgba(0,60,136,0.8)",
                    display: "flex", overflowY: 'scroll'}}>
                    <Typography sx={{width:'100%'}}>
                        <Stack direction="column" spacing={2} justifyContent={"flex-start"} marginTop={1} marginBottom={1}>
                            {this.state.layers_list}
                        </Stack>
                    </Typography>

                </Box>
            </div>
        }
        return(
                <Stack direction={"column-reverse"} alignItems={"flex-end"} >
                    <BottomNavigation sx={{borderRadius:0, bgcolor:"rgba(0,60,136,0.8)"}} value={this.state.app}
                                      onChange={() => this.setState({openLayersPanel : !this.state.openLayersPanel,})}>
                        <BottomNavigationAction
                            icon={this.state.openLayersPanel ? <LayersIcon color="secondary" /> : <LayersIcon />}
                            sx={{padding: 0}} />
                    </BottomNavigation>
                    {layersOverlayComponent}
                </Stack>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        lastMapCount: state.gammaStateReducer.lastMapCount,
        mapsState: state.mapsStateReducer,
        magicToolData: state.MagicToolReducer,
    }
}

export default connect(mapStateToProps)(MapLayersMaker);
