import React, { Component } from 'react';
import cookie from "react-cookies";
import { FolderOpenOutlined, FolderOutlined, PlusOutlined, SmileOutlined} from '@ant-design/icons';
import {
  Col,
  Spin,
  Divider,
  Table,
  Input,
  Button,
  Row,
  Tooltip,
  Empty,
  Pagination,
  Tag,
  Select,
  Popconfirm,
} from 'antd';
import { globalUrlPrefix, openInNewTabType } from '../utils/const';
import { updateUrlStateInReducer, updateCaseSearch, changePage } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert, markCaseUnread, markCaseAndSlidesRead } from '../action/cases.action'
import { makeMenuVisible } from "../action/context.menu.action";
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import { historyMP } from '../helper/history';
import FilterComp from "../component/case/filter";
import '../asset/style/custom_antd_table_style.css';
import axios from 'axios';
import {Alert, Result } from 'antd';

const { Search } = Input;
const ButtonGroup = Button.Group;
const Option = Select.Option;


const pageSize = 15;

class CliaBarrier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token:"",
      tokenVerified:false,
      processing: true,
    }

    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
  }

  componentWillMount() {
    this.props.dispatch(openDrawer());
    this.getToken();
  }

  openCliaPage = () => {
    window.location.href = "/" + globalUrlPrefix + "/clia/";
  }

  openDashboard = () => {
    window.location.href = "/";
  }

  verifyToken = (token) =>{
    let url = `/api/token_verifier/?token=${token}`
    axios.get(url).then(res => {
      console.log(res);
      if(res.data["status"]=="CORRECT"){
        this.setState({tokenVerified:true})
        let token = res.data["token"]
        cookie.save('screen_supported', token, { path: '/'});
        // alert("cookie",cookie.loadAll().screen_supported)
      }
      else{
        cookie.save('screen_supported', false, { path: '/'});
      }
      this.setState({processing:false})
    })
  }

  getToken = () =>{
    let url = window.location.href.split('?')[1];
    let token = '';
    if (url != undefined) {
      var partsOfUrl = url.split('&');
      partsOfUrl.map((part) => {
          var key = part.split('=')[0];
          var value = part.split('=')[1];
          if (key === 'token') {
              token = value;
              this.setState({token:token})
              this.verifyToken(token)
            }
      });
    }
  }

  render() {
    var verified = cookie.loadAll().screen_supported
    console.log("Cookie verified ", verified);
    if(this.state.tokenVerified === true){
      this.openCliaPage();
    }
    
    var processing = this.state.processing
    return (
      <Row>
      {processing?
        null
    :
    <div>
    <Result
      title="Your Screen Isnt Supported"
      extra={
        <Button type="primary" key="console" onClick={this.openDashboard}>
          Go To Dashboard
        </Button>
      }
    />
  </div>}
    </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allCases: state.casesReducer,
    allCaseStatus: state.caseStatusReducer.case_status,
    allUsers: state.caseStatusReducer.users,
    numPages: state.caseStatusReducer.numPages,
    pageNumber: state.caseStatusReducer.pageNumber,
    total: state.caseStatusReducer.total,
    urlState: state.searchCaseReducer,
    isFetching: state.searchCaseReducer.isFetching
  };
};

export default connect(mapStateToProps)(CliaBarrier);