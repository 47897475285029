import cookie from "react-cookies";
import {AllAnnotationsConstants} from "../../../consts/anno.const";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import Circle from "ol/geom/Circle";
import {Fill, Stroke, Style, Text as OlText} from "ol/style";
import {getFormattedArea, getFormattedLength} from "../../../utils/utils";
import * as keys from '../drawer/draw_tool_keys';
import {openAppSidebarTop,openAnnotationAppAnno} from "../../../action/triggers.action";
import {store} from "../../../helper/store";
import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";
import {isResponseOk} from "../../../helper/response.status.check";
import {displayError} from "../../../helper/display.error";
import {message} from "antd";


const annoColor = AllAnnotationsConstants.DEFAULT_ANNO_COLOR;

const baseText = new OlText({
    font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
    placement: AllAnnotationsConstants.LINE,
    textBaseline: 'top',
    fill: new Fill({
        color: annoColor
    }),
    backgroundFill: new Fill({
        color: "#ffffff"
    }),
    backgroundStroke: new Fill({
        color: "#ffffff"
    })
})

const baseStyle = new Style({
    stroke: new Stroke({
        color: annoColor,
        width: 2
    }),
    fill: new Fill({
        color: 'rgba(255, 0, 0, 0.0)'
    })
});

const selectedStyle = new Style({
    stroke: new Stroke({
        color: "#00b3ff",
        width: 8
    })
})

const otherStyle = new Style({
    stroke: new Stroke({
        color: "white",
        width: 5
    })
})

export const styleFunction = (feature, resolution) => {
    let baseStyleWithText = baseStyle;
    baseText.setText(feature.get('title'));
    if (feature.getProperties().color != undefined && feature.getProperties().color != "") {
        baseStyleWithText.getStroke().setColor(feature.getProperties().color);
        baseText.getFill().setColor(feature.getProperties().color);
    }
    baseStyleWithText.setText(baseText);
    if (feature.getProperties().selected) {
        return [
            selectedStyle, baseStyleWithText
        ]
    } else {
        return [
            otherStyle, baseStyleWithText
        ]
    }
}

export const getFeatureFromAnnotation = (annotation) => {
    let feature;
    if (annotation.new_bounds !== null) {
        if (annotation.shape === AllAnnotationsConstants.POLYGON) {
            feature = new Feature({
                geometry: new Polygon(JSON.parse(annotation.new_bounds)),
                id: annotation.id,
                name: annotation.shape,
                title: annotation.title,
            });
        }
        else if (annotation.shape === AllAnnotationsConstants.LINE) {
            feature = new Feature({
                geometry: new LineString(JSON.parse(annotation.new_bounds)),
                id: annotation.id,
                name: annotation.shape,
                title: annotation.title,
            });
        }
        else if (annotation.shape === AllAnnotationsConstants.CIRCLE) {
            let bounds = JSON.parse(annotation.new_bounds);
            let center = bounds[0];
            let radius = bounds[1];
            feature = new Feature({
                geometry: new Circle(center, radius),
                id: annotation.id,
                name: annotation.shape,
                title: ""
            });
        }
        feature.setId(annotation.id);
        feature.set('creator', annotation.anno_drawer);
        feature.set('color', annotation.color);
    }
    return feature;
}

export const getAnnotationAppFeatures = (annotations) => {
    let allFeatures = [];
    annotations.map((annotation, index) => {
        let feature = getFeatureFromAnnotation(annotation);
        allFeatures.push(feature);
        return feature;
    });
    return allFeatures;
}

export const getAnnotationFromPoints = (color, area, perimeter, key, coord, center,
    taggerType, z, slide_id) => {
    return {
        area: area,
        perimeter: perimeter,
        color: color,
        new_bounds: coord,
        slide: slide_id,
        creator: parseInt(cookie.load('user_id')),
        shape: key.db_key,
        tool_type: key.name,
        center: center.toString(),
        anno_drawer: taggerType,
        created_z_level: z,
        title: key.db_key === AllAnnotationsConstants.LINE ? "R" + " " + getFormattedLength(perimeter) : "R" + " " + getFormattedArea(area),
    }
}

export const getAnnotationListIcon = (toolType, verticalAlign) => {
    if (toolType === keys.lineDrawingKey.name)
        return keys.lineDrawingKey.icon;
    if (toolType === keys.freelineDrawingKey.name)
        return keys.freelineDrawingKey.icon;
    if (toolType === keys.freehandDrawingKey.name)
        return keys.freehandDrawingKey.icon;
    if (toolType === keys.circleDrawingKey.name)
        return keys.circleDrawingKey.icon;
    if (toolType === keys.rectangleDrawingKey.name)
        return keys.rectangleDrawingKey.icon;
    if (toolType === keys.polygonDrawingKey.name)
        return keys.polygonDrawingKey.icon;
    if (toolType === keys.magicToolDrawingKey.name)
        return keys.magicToolDrawingKey.icon;
    if (toolType === keys.magicToolResultDrawingKey.name)
        return keys.magicToolResultDrawingKey.icon;
    return null;
}

export const onSelectAnnotationOnMap = (appId, annoId) => {
    if(annoId === undefined || annoId == null){
        return;
    }
    store.dispatch(openAppSidebarTop(appId))
    store.dispatch(openAnnotationAppAnno(annoId))
}

export const downloadAnnotations = (slideId, annoDrawer, extension) =>
    axios
        .get(
            `/api/annotation_download?slide=${slideId}&anno_drawer=${annoDrawer}`,
            {
                headers: {
                    Authorization: AuthHeader(),
                }
            }
        )
        .then(response => {
            if (isResponseOk(response))
                if (response.data.length > 0) {
                    const link = document.createElement('a');
                    link.href = extension === 'json' ?
                        URL.createObjectURL(new Blob(
                        [JSON.stringify(response.data)],
                        {
                            type:'application/json'}
                        )) :
                        // written by priyanshu - using keys as headers -
                        // values object (will convert to string and double quotes replaced to none) -
                        // each value can contain comma (that's why enclosed in double quotes)
                        URL.createObjectURL(new Blob(
                            [[Object.keys(response.data[0]).join(), ...response.data].reduce((res, json) =>
                                `${res}\n${['', ...Object.keys(json)].reduce((res, key) => 
                                    `${res}"${typeof json[key] === 'object' ? 
                                        JSON.stringify(json[key]).split(`"`).join('') : 
                                        json[key]}",`)}`)],
                            {
                                type: 'text/csv',
                            }
                        ))
                    link.download = `${annoDrawer === 0 ? "annotations" : "deep_bio_annotations"}.${extension}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else message.info("No annotations available");
            else displayError("Failed to download annotations");
        })
        .catch(error => displayError("Failed to download annotations", error));
