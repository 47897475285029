import React, {Component} from  "react";
import {Row, Col, Tabs, Input, Spin, Button, message, Switch } from 'antd';
import {connect} from 'react-redux';
import cookie from "react-cookies";
import { Map, View, Feature } from "ol";
import LayerGroup from "ol/layer/Group";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import Projection from "ol/proj/Projection";
import { DoubleClickZoom } from "ol/interaction";
import { getCenter } from "ol/extent";
import { pointSelectionModeVals, scanSettingsTypeVals, scanWorkflowLoadingMessages } from "../../../utils/const";
import Point from "ol/geom/Point";
import { Style, Fill, Stroke } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { setDeviceBusy, setDeviceIdle } from "../../../action/device_status.action";
import axios from "axios";
import { AuthHeader } from "../../../helper/auth.token";
import ModalImage from "./react-modal-image/src/index.js";
import { setDropDistancePointOnly, setDropDistancePointLength, setFocusPoints, updateActionOngoing } from "../../../action/preview_status.action";
import CameraSettings from "./cameraSettings";
import ImageSettings from "./imageSettings";

import '../../../asset/style/workflow/advanced-preview-settings-component.css';

const { Search } = Input;
const { TabPane } = Tabs;

class AdvancedPreviewSettingsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pointSelectionMode: pointSelectionModeVals.COARSE,
            showLatestImage: false,
            currImagePos: [],
            pointAlreadyPresent: false,
            isFetching: false,
            fetchingMessage: "",
            getImageOnEveryClick: false,
            currResponse: this.props.currResponse,
            livemodeConfig: {},
            isFirstTime: true,
            move: {x:0, y:0}
        }

        this.image = new Image();

        this.image.onload = this.onloadRunner;

        this.onloadRunner = this.onloadRunner.bind(this);
    }

    componentDidMount = () => {
        this.image.src = '/dev-ssd/' + this.props.preview.response.uploadLocation + '?time=' + Date.now();
        document.addEventListener("keypress", this.onKeyPress, false);

        this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.LOADING));
        let partsOfUrl = "api~scan~get_camera_info";
        let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?objectiveType=" + this.props.preview.objectiveType;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    console.log(response);
                    this.props.setValues(response.data.livemodeConfig.minExposure, response.data.livemodeConfig.maxExposure, response.data.livemodeConfig.minGamma, response.data.livemodeConfig.maxGamma, response.data.viewerConfig.brightness, response.data.viewerConfig.contrast, response.data.cameraConfig.exposureTime, response.data.cameraConfig.gamma);
                    this.setState({
                        livemodeConfig: response.data.livemodeConfig,
                    });
                }
                else {
                    console.log(response);
                    message.error("Not able to get camera settings!!", 2.5);
                }
                this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to get camera settings!!", 2.5);
                this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
            })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.scanSettingsType != this.props.scanSettingsType && this.props.scanSettingsType == scanSettingsTypeVals.ADVANCED) {
            this.image.src = '/dev-ssd/' + this.props.preview.response.uploadLocation + '?time=' + Date.now();
            this.setState({
                pointSelectionMode: pointSelectionModeVals.COARSE,
                showLatestImage: false,
                currImagePos: [],
                pointAlreadyPresent: false,
                currResponse: {},
            });
        }

        if (prevProps.currResponse != this.props.currResponse) {
            this.setState({
                currResponse: this.props.currResponse,
            });
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener("keypress", this.onKeyPress, false);
    }

    onKeyPress = (e) => {
        if (e.keyCode == 127 && this.state.pointSelectionMode == pointSelectionModeVals.FINE && this.state.pointAlreadyPresent) {
            this.deleteFineFocusPoint();
        }
    }

    drawMarker = (x, y, vectorSource, type) => {
        let feature = new Feature({
            type: type,
            geometry: new Point([x, y]),
        });
        vectorSource.addFeature(feature);
    }

    onloadRunner = () => {
        let extent = [0, 0, this.image.width, this.image.height];
        let projection = new Projection({
            code: 'preview-image',
            units: 'pixels',
            extent: extent,
        });

        let styles = {
            'coarseFocusMarker': new Style({
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({color: 'red'}),
                    stroke: new Stroke({
                        color: 'white',
                        width: 2,
                    }),
                }),
            }),
            'fineFocusMarker': new Style({
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({color: 'blue'}),
                    stroke: new Stroke({
                        color: 'white',
                        width: 2,
                    }),
                }),
            }),
            'provisionalPointMarker': new Style({
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({color: '#7CFC00'}),
                    stroke: new Stroke({
                        color: 'white',
                        width: 2,
                    }),
                }),
            }),
            'selectedFineFocusMarker': new Style({
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({color: 'yellow'}),
                    stroke: new Stroke({
                        color: 'white',
                        width: 2,
                    }),
                }),
            }),
        };

        let coarseFocusVectorSource = new VectorSource({});
        let coarseFocusVectorLayer = new VectorLayer({
            source: coarseFocusVectorSource,
            visible: true,
            zIndex: 10,
            style: function (feature) {
                return styles[feature.get('type')];
            },
        });

        this.drawMarker(this.props.preview.dropDistancePoint[0].x, this.image.height - this.props.preview.dropDistancePoint[0].y, 
            coarseFocusVectorSource, 'coarseFocusMarker');

        let fineFocusVectorSource = new VectorSource({});
        let fineFocusVectorLayer = new VectorLayer({
            source: fineFocusVectorSource,
            visible: false,
            zIndex: 5,
            style: function (feature) {
                return styles[feature.get('type')];
            },
        });

        for (let island in this.props.preview.focusPoints) {
            for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
                let focusUnit = this.props.preview.focusPoints[island][i];
                if (focusUnit.length > 0) {
                    this.drawMarker(focusUnit[0].x, this.image.height - focusUnit[0].y, 
                        fineFocusVectorSource, 'fineFocusMarker');
                }
            }
        }

        let provisionalPointVectorSource = new VectorSource({});
        let provisionalPointVectorLayer = new VectorLayer({
            source: provisionalPointVectorSource,
            visible: true,
            zIndex: 20,
            style: function (feature) {
                return styles[feature.get('type')];
            },
        });

        let previewUrl = '/dev-ssd/' + this.props.preview.response.uploadLocation + '?time=' + Date.now();
        let thresholdUrl = '/dev-ssd/' + this.props.preview.response.maskLocation + '?time=' + Date.now();

        if (this.state.map instanceof Map) {
            const layers = [...this.state.map.getLayers().getArray()];
            layers.forEach((layer) => this.state.map.removeLayer(layer));
            this.state.map.setLayerGroup(new LayerGroup({
                layers: [
                    new ImageLayer({
                        source: new Static({
                            url: previewUrl,
                            projection: projection,
                            imageExtent: extent,
                        }),
                        zIndex: 1,
                    }), 
                    new ImageLayer({
                        source: new Static({
                            url: thresholdUrl,
                            projection: projection,
                            imageExtent: extent,
                        }),
                        zIndex: 2,
                        opacity: 0.1,
                    }),
                    coarseFocusVectorLayer,
                    fineFocusVectorLayer,
                    provisionalPointVectorLayer
                ],
            }));
            this.state.map.setView(new View({
                projection: projection,
                center: getCenter(extent),
                zoom: 1,
                maxZoom: 8,
                rotation: ((90 * Math.PI) / 180)
            }));

            this.setState({
                coarseFocusVectorSource,
                coarseFocusVectorLayer,
                fineFocusVectorSource,
                fineFocusVectorLayer,
                provisionalPointVectorSource,
                provisionalPointVectorLayer
            });
        } else {
            let map = new Map({
                controls: [],
                layers: [
                    new ImageLayer({
                        source: new Static({
                            url: previewUrl,
                            projection: projection,
                            imageExtent: extent,
                        }),
                        zIndex: 1,
                    }), 
                    new ImageLayer({
                        source: new Static({
                            url: thresholdUrl,
                            projection: projection,
                            imageExtent: extent,
                        }),
                        zIndex: 2,
                        opacity: 0.2,
                    }),
                    coarseFocusVectorLayer,
                    fineFocusVectorLayer,
                    provisionalPointVectorLayer
                ],
                target: 'map-advanced-scan-settings',
                view: new View({
                    projection: projection,
                    center: getCenter(extent),
                    zoom: 1,
                    maxZoom: 8,
                    rotation: ((90 * Math.PI) / 180)
                }),
            });

            let dblClickInteraction;
            map.getInteractions().getArray().forEach(function (interaction) {
                if (interaction instanceof DoubleClickZoom) {
                    dblClickInteraction = interaction;
                }
            });

            map.removeInteraction(dblClickInteraction);

            var self = this;

            map.on('click', this.getFortyXImage);

            this.setState({
                map,
                coarseFocusVectorSource,
                coarseFocusVectorLayer,
                fineFocusVectorSource,
                fineFocusVectorLayer,
                provisionalPointVectorSource,
                provisionalPointVectorLayer
            });
        }
    }

    getFortyXImage = (e) => {
        let x = Math.trunc(e.coordinate[0]);
        let y = Math.trunc(e.coordinate[1]);

        // @Spandan check for coordinate inside selected region removed for demo purpose

        let isInsideRegion = (x >= this.props.preview.actualRegion[0] && 
                                x <= this.props.preview.actualRegion[2] && 
                                (this.image.height - y) >= this.props.preview.actualRegion[1] && 
                                (this.image.height - y) <= this.props.preview.actualRegion[3])

        if (isInsideRegion) {
            if (this.state.getImageOnEveryClick) {
                this.getImage(x, y, false, true);
                this.addCoarseFocusPoint(x, this.image.height - y)
            } else {
                if (this.state.pointSelectionMode == pointSelectionModeVals.COARSE) {
                    this.addCoarseFocusPoint(x, this.image.height - y);
                } else if (this.state.pointSelectionMode == pointSelectionModeVals.FINE) {
                    this.checkIfFineFocusPointAlreadyExists(x, y);
                }
            }
        } else {
            message.error("Point not inside the selected region.", 2.5);
        }
    }

    getFortyXImageDDPoint = (requiredDD, requiredFF) => {
        let coarseFocusPointCoordinate = this.state.coarseFocusVectorSource.getFeatures()[0].getGeometry().getCoordinates();
        this.getImage(coarseFocusPointCoordinate[0], coarseFocusPointCoordinate[1], requiredDD, requiredFF);
    }

    validateExposure = () => {
        return ((this.props.exposureInputValue < this.props.exposureMinValue) || (this.props.exposureInputValue > this.props.exposureMaxValue))
    }

    validateGamma = () => {
        return ((this.props.gammaInputValue < this.props.gammaMinValue) || (this.props.gammaInputValue > this.props.gammaMaxValue))
    }

    getImage = (x, y, requiredDD, doFF) => {
        if (this.validateExposure()){
            message.error("Exposure cannot exceed "+this.props.exposureMinValue+" and " + this.props.exposureMaxValue);
            return;
        }
        if (this.validateGamma()){
            message.error("Gamma cannot exceed "+this.props.gammaMinValue+" and " + this.props.gammaMaxValue);
            return;
        }
        this.props.dispatch(setDeviceBusy(this.props.device_id));
        this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.GETTING_IMAGE));

        let doDD = this.state.isFirstTime || requiredDD;

        let partsOfUrl = "api~scan~get_forty_x_image_from_scan_preview";
        let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?slot_id=" + this.props.id + "&x=" + x + "&y=" + (this.image.height - y) + "&exp=" + (this.props.exposureInputValue) + "&gamma=" + (this.props.gammaInputValue) + "&dd=" + (doDD)+ "&ff=" + (doFF);
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    // Enable image showing
                    let currImagePos = [x, this.image.height - y];
                    let pointAlreadyPresent = false;
                    if (this.state.pointSelectionMode == pointSelectionModeVals.COARSE) {
                        let coarseFocusPointCoordinate = this.state.coarseFocusVectorSource.getFeatures()[0].getGeometry().getCoordinates();
                        for (let xVal = x - 15; xVal <= x + 15; xVal++) {
                            for (let yVal = y - 15; yVal <= y + 15; yVal++) {
                                if (xVal == coarseFocusPointCoordinate[0] && yVal == coarseFocusPointCoordinate[1]) {
                                    pointAlreadyPresent = true;
                                    break;
                                }
                            }
                            if (pointAlreadyPresent) {
                                break;
                            }
                        }
                    } else if (this.state.pointSelectionMode == pointSelectionModeVals.FINE) {
                        let fineFocusPointCoordinate = this.state.fineFocusVectorSource.getFeatures().map((feature, index) => {
                            return [feature.getGeometry().getCoordinates()[0], feature.getGeometry().getCoordinates()[1]];
                        });
                        for (let xVal = x - 15; xVal <= x + 15; xVal++) {
                            for (let yVal = y - 15; yVal <= y + 15; yVal++) {
                                if (this.checkValuesInCoordinateArray(xVal, yVal, fineFocusPointCoordinate)) {
                                    pointAlreadyPresent = true;
                                    currImagePos = [xVal, this.image.height - yVal];
                                    break;
                                }
                            }
                            if (pointAlreadyPresent) {
                                break;
                            }
                        }
                    }
                    this.state.provisionalPointVectorSource.clear();
                    if (!pointAlreadyPresent) {
                        this.drawMarker(x, y, this.state.provisionalPointVectorSource, 'provisionalPointMarker');
                    } else {
                        this.plotFinePoints(this.state.currResponse, true, currImagePos);
                    }
                    this.setState({
                        showLatestImage: true,
                        currImagePos,
                        pointAlreadyPresent,
                        isFirstTime:false,
                    });
                } else {
                    this.setState({
                        showLatestImage: false,
                        currImagePos: [],
                        pointAlreadyPresent: false,
                    });
                    message.error("Not able to get image. Please try some different point.", 2.5);
                }
                this.props.dispatch(setDeviceIdle(this.props.device_id));
                this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
            })
            .catch(err => {
                this.setState({
                    showLatestImage: false,
                    currImagePos: [],
                    pointAlreadyPresent: false,
                });
                this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
                message.error("Not able to get image. Please try some different point.", 2.5);
            })
    }

    // TODO : this logic should be handled in backend scano
    addCoarseFocusPoint = (xPixel, yPixel) => {
        this.props.dispatch(setDeviceBusy(this.props.device_id));
        // this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.SET_COARSE_FOCUS_POINT));
        console.log("new dd point ", xPixel, yPixel);
        console.log("old list: ", this.props.preview.dropDistancePoint)
        console.log("DD points count: ", this.props.preview.dropDistancePointLength);

        let dropDistancePoints = [...this.props.preview.dropDistancePoint];
        if (dropDistancePoints.length == this.props.preview.dropDistancePointLength) {
            dropDistancePoints.unshift({x: xPixel, y: yPixel});
            console.log('added point for first time');
        } else {
            dropDistancePoints[0] = {x: xPixel, y: yPixel};
            console.log("just updating dd  point");
        } 

        let url = '/server/devices/' + this.props.device_id + "/set_drop_distance_point/?slot_id=" + this.props.id;
        axios({
            method:"post",
            url : url, 
            data: {
                points: dropDistancePoints
            }
        })
        .then(response => {
            if (response.status === 200) {
                this.state.coarseFocusVectorSource.clear();
                this.state.provisionalPointVectorSource.clear();
                this.drawMarker(xPixel, this.image.height - yPixel, 
                    this.state.coarseFocusVectorSource, 'coarseFocusMarker');
                this.props.dispatch(setDropDistancePointOnly(this.props.id, dropDistancePoints));
                this.setState({
                    pointAlreadyPresent: true,
                    currResponse: response,
                });
            }
            else {
                message.error("Not able to add coarse focus point. Please contact admin.", 2.5);
            }
            this.props.dispatch(setDeviceIdle(this.props.device_id));
            // this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
        })
        .catch(err => {
            this.props.dispatch(setDeviceIdle(this.props.device_id));
            // this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
            message.error("Not able to add coarse focus point. Please contact admin.", 2.5);
        })
    }

    ignoreFocusPoint = () => {
        this.state.provisionalPointVectorSource.clear();
        this.setState({
            showLatestImage: false,
            currImagePos: [],
            pointAlreadyPresent: false,
        });
    }

    deleteFineFocusPoint = () => {

        let islandID = -1, index = -1;

        for (let island in this.props.preview.focusPoints) {
            for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
                let focusUnit = this.props.preview.focusPoints[island][i];
                if (focusUnit.length > 0 && focusUnit[0].x == this.state.currImagePos[0] && focusUnit[0].y == this.state.currImagePos[1]) {
                    islandID = island;
                    index = i;
                    break;
                }
            }
        }

        if (islandID == -1 || index == -1) {
            message.error("Not able to delete point. Please contact admin.", 2.5);
        } else {

            this.props.dispatch(setDeviceBusy(this.props.device_id));
            // this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.REMOVE_FINE_FOCUS_POINT));

            let partsOfUrl = "api~scan~delete_focus_point";
            let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?slot_id=" + this.props.id + "&island_id=" + islandID + "&index=" + index;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                        this.plotFinePoints(response, false, []);
                        this.setState({
                            currResponse: response,
                        });
                    }
                    else {
                        message.error("Not able to delete point. Please contact admin.", 2.5);
                    }
                    this.props.dispatch(setDeviceIdle(this.props.device_id));
                    // this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
                })
                .catch(err => {
                    this.props.dispatch(setDeviceIdle(this.props.device_id));
                    // this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
                    message.error("Not able to delete point. Please contact admin.", 2.5);
                })
        }
    }

    checkIfFineFocusPointAlreadyExists = (xPixel, yPixel) => {
        let currImagePos = [xPixel, this.image.height - yPixel];
        let pointAlreadyPresent = false;
        let fineFocusPointCoordinate = this.state.fineFocusVectorSource.getFeatures().map((feature, index) => {
            return [feature.getGeometry().getCoordinates()[0], feature.getGeometry().getCoordinates()[1]];
        });
        for (let xVal = xPixel - 15; xVal <= xPixel + 15; xVal++) {
            for (let yVal = yPixel - 15; yVal <= yPixel + 15; yVal++) {
                if (this.checkValuesInCoordinateArray(xVal, yVal, fineFocusPointCoordinate)) {
                    pointAlreadyPresent = true;
                    currImagePos = [xVal, this.image.height - yVal];
                    break;
                }
            }
            if (pointAlreadyPresent) {
                break;
            }
        }
        if (pointAlreadyPresent) {
            this.plotFinePoints(this.state.currResponse, true, currImagePos);
        }
        this.setState({
            currImagePos,
            pointAlreadyPresent,
        });
        if(!pointAlreadyPresent) {
            this.addFineFocusPoint(xPixel, this.image.height - yPixel);
        }
    }

    addFineFocusPoint = (xPixel, yPixel) => {

        this.props.dispatch(setDeviceBusy(this.props.device_id));
        // this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.ADD_FINE_FOCUS_POINT));

        let partsOfUrl = "api~scan~add_focus_point";
        let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?slot_id=" + this.props.id + "&x=" + xPixel +
            "&y=" + yPixel;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.plotFinePoints(response, false, []);
                    this.setState({
                        currResponse: response,
                    });
                }
                else {
                    message.error("Not able to add fine focus point. Please contact admin.", 2.5);
                }
                this.props.dispatch(setDeviceIdle(this.props.device_id));
                // this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
            })
            .catch(err => {
                this.props.dispatch(setDeviceIdle(this.props.device_id));
                // this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
                message.error("Not able to add fine focus point. Please contact admin.", 2.5);
            })
    }

    plotFinePoints = (response, markCurrPoint, currPoint) => {
        this.state.fineFocusVectorSource.clear();
        this.state.provisionalPointVectorSource.clear();

        for (let island in response.data.focusPoints) {
            for (let i = 0; i < response.data.focusPoints[island].length; i++) {
                let focusUnit = response.data.focusPoints[island][i];
                if (focusUnit.length > 0) {
                    if (markCurrPoint && currPoint[0] == focusUnit[0].x && currPoint[1] == focusUnit[0].y) {
                        this.drawMarker(focusUnit[0].x, this.image.height - focusUnit[0].y, 
                            this.state.fineFocusVectorSource, 'selectedFineFocusMarker');
                    } else {
                        this.drawMarker(focusUnit[0].x, this.image.height - focusUnit[0].y, 
                            this.state.fineFocusVectorSource, 'fineFocusMarker');
                    }
                }
            }
        }
        this.props.dispatch(setFocusPoints(this.props.id, response.data.focusPoints));
        this.setState({
            showLatestImage: false,
            currImagePos: [],
            pointAlreadyPresent: false,
        });
    }

    changePointSelectionMode = (key) => {
        if (key == pointSelectionModeVals.COARSE) {
            this.state.fineFocusVectorLayer.setVisible(false);
            this.state.coarseFocusVectorLayer.setVisible(true);
        } else if (key == pointSelectionModeVals.FINE) {
            this.state.coarseFocusVectorLayer.setVisible(false);
            this.state.fineFocusVectorLayer.setVisible(true);
        } else if (key == pointSelectionModeVals.CAMERA_SETTINGS) {
            this.state.coarseFocusVectorLayer.setVisible(false);
            this.state.fineFocusVectorLayer.setVisible(false);
        }
        this.state.provisionalPointVectorSource.clear();
        this.setState({
            pointSelectionMode: key,
            showLatestImage: false,
            currImagePos: [],
            pointAlreadyPresent: false,
        });
    }

    checkValuesInCoordinateArray = (x, y, coordinateArray) => {
        for (let i = 0; i < coordinateArray.length; i++) {
            if (coordinateArray[i][0] == x && coordinateArray[i][1] == y) {
                return true;
            }
        }
        return false;
    }

    onGetImageToggle = (checked) => {
        if (this.state.pointSelectionMode == pointSelectionModeVals.FINE) {
            this.plotFinePoints(this.state.currResponse, false, []);
        }
        this.setState({
            getImageOnEveryClick: checked && !(((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {})['busy']
            && !(((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {})['takingPreviews'],
            showLatestImage: false,
            currImagePos: [],
            pointAlreadyPresent: false,
        });
    }

    updateImageCoordinates = (move) => {
        console.error(this.state.move)
        this.setState({
            move: move
        })
        console.error(this.state.move)
    }
    

    render() {
        let busy = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {})['busy'];
        console.log("busy_in_advanced",busy)
        let is_superuser = cookie.loadAll().superuser === "true";
        
        return(
            <div>
                <Row>
                    <Col span={5} offset={1}>
                        <div tabIndex="0" id={"map-advanced-scan-settings"} className="advanced-settings-preview-map-container"></div>
                    </Col>
                    <Col span={16} offset={1}>
                        <Tabs activeKey={this.state.pointSelectionMode} onChange={this.changePointSelectionMode}>
                            <TabPane tab="Coarse Focus Point" key={pointSelectionModeVals.COARSE}>
                                <Row>
                                    <Col span={5}>
                                        Get image on every click
                                    </Col>
                                    <Col span={2}>
                                        <Switch checked={this.state.getImageOnEveryClick && !busy} onChange={this.onGetImageToggle} />
                                    </Col>
                                    {/* {this.state.getImageOnEveryClick?
                                    <Col span={4} style={{marginLeft:'20px'}}>
                                        <Button type="primary" onClick={() => {this.getFortyXImageDDPoint(false)}}>Get Live Image</Button>
                                    </Col>
                                    :null} */}
                                </Row>
                                {this.state.getImageOnEveryClick && !busy?
                                    this.state.showLatestImage ? 
                                        <div style={{marginTop: 10}}>
                                            <Row>
                                                <Col span={16}>
                                                    <div className="forty-x-image" style={{
                                                        filter: `brightness(${this.props.brightnessInputValue * 2}%) contrast(${this.props.contrastInputValue * 2}%)`
                                                        }}>
                                                        <ModalImage
                                                            small={'/dev-ssd/last_preview/last_preview_40x_image/image.jpg?time=' + Date.now()}
                                                            large={'/dev-ssd/last_preview/last_preview_40x_image/image.jpg?time=' + Date.now()}
                                                            alt="Image!"
                                                            hideDownload
                                                            move = {this.state.move}
                                                            updateMove = {this.updateImageCoordinates}
                                                        />
                                                    </div>
                                                    </Col>
                                                    <Col span={8}>
                                    <Row style={{marginTop: 20}} span={16}>
                                                <ImageSettings
                                                    brightnessInputValue={this.props.brightnessInputValue}
                                                    contrastInputValue={this.props.contrastInputValue}
                                                    brightnessValueChange={this.props.brightnessValueChange}
                                                    contrastValueChange={this.props.contrastValueChange}
                                                    />
                                                    </Row>
                                    <Row style={{marginTop: 20}} span={16}>
                                        <CameraSettings device_id={this.props.device_id} id={this.props.id} 
                                            exposureInputValue={this.props.exposureInputValue}
                                            gammaInputValue={this.props.gammaInputValue}
                                            livemodeConfig={this.state.livemodeConfig}
                                            exposureValueChange={this.props.exposureValueChange}
                                            gammaValueChange={this.props.gammaValueChange} 
                                            callToAutoUpdate={this.getFortyXImageDDPoint}
                                            />
                                    </Row>
                                            </Col>
                                            </Row>
                                        </div>
                                        :
                                        <Row>
                                                <Col span={16}>
                                                    <div className="blank-forty-x-image">
                                                        Click on the preview image to get Live image of that location.
                                                    </div>
                                                </Col>
                                                    <Col span={8}>
                                    <Row style={{marginTop: 20}} span={16}>
                                                <ImageSettings
                                                    brightnessInputValue={this.props.brightnessInputValue}
                                                    contrastInputValue={this.props.contrastInputValue}
                                                    brightnessValueChange={this.props.brightnessValueChange}
                                                    contrastValueChange={this.props.contrastValueChange}
                                                    />
                                                    </Row>
                                    <Row style={{marginTop: 20}} span={16}>
                                        <CameraSettings device_id={this.props.device_id} id={this.props.id} 
                                            exposureInputValue={this.props.exposureInputValue}
                                            gammaInputValue={this.props.gammaInputValue}
                                            livemodeConfig={this.state.livemodeConfig}
                                            exposureValueChange={this.props.exposureValueChange}
                                            gammaValueChange={this.props.gammaValueChange} 
                                            callToAutoUpdate={this.getFortyXImageDDPoint}
                                            />
                                    </Row>
                                            </Col>
                                        </Row>
                                    : <Row style={{marginLeft: "65%", marginTop: 20}} span={16}>
                                    <CameraSettings device_id={this.props.device_id} id={this.props.id} 
                                        exposureInputValue={this.props.exposureInputValue}
                                        gammaInputValue={this.props.gammaInputValue}
                                        livemodeConfig={this.state.livemodeConfig}
                                        exposureValueChange={this.props.exposureValueChange}
                                        gammaValueChange={this.props.gammaValueChange}
                                        />
                                </Row>
                                }
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return{
		preview: state.previewStatusReducer[ownProps.id],
        deviceStatus: state.deviceStatusReducer,
    }
}

export default connect(mapStateToProps)(AdvancedPreviewSettingsComp);
