import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { AuthHeader } from "../../../../../helper/auth.token";
import _ from 'lodash';
import { Col, Row, Spin, Radio, Table, Button, message } from 'antd';
import { initialiseMap, styleFunction, drawMarkers, findClosestFeature } from './annotation_map_utils'
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';

import "../../../../../asset/style/neoviewer/annotation_map.css"
import { convertSettingsToFilter, getPrefixedUrl } from '../../../../../utils/utils';

const mapOperation = {
  ADD: "Add",
  ADD_POSITIVE: "AddPositive",
  ADD_NEGATIVE: "AddNegative",
  EDIT: "Edit",
  DELETE: "Delete"
}

const columns = [
  {
    title: 'Positive',
    dataIndex: 'positive',
    key: 'positive',
  },
  {
    title: 'Negative',
    dataIndex: 'negative',
    key: 'negative',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

class AnnotationMap extends Component {

  constructor(props) {
    super(props);

    this.state = {
      slide: -1,
      isFetching: true,
      isErrored: false,
      mapId: this.props.map_id,
      positiveLabels: [], 
      negativeLabels: [], 
      selectedMapOperation: mapOperation.EDIT
    };


    this.vector = new VectorLayer({
      style: styleFunction, 
      source: new VectorSource({})
    });

    this.timer = null;
  }

  componentDidMount = () => {
    this.getAnnotationMarkersAndCreateMap(this.props.annotation);
  }

  createMap = (annotation, positiveLabels, negativeLabels) => {
    initialiseMap(this.props.slide, annotation, positiveLabels, negativeLabels, this.vector, this.onMapClick, (obj) => this.setState(obj));
  }

  onMapClick = (event) => {
      let resolution = this.state.annomap.getView().getResolution();
      let distance = 75 * resolution; 
      let closestFeature = findClosestFeature(this.vector, event.coordinate, distance);
      let selected;
      if ([mapOperation.ADD_POSITIVE, mapOperation.ADD_NEGATIVE].includes(this.state.selectedMapOperation)){
          let x = event.coordinate[0] / this.props.slide.uperpixel;
          let y = event.coordinate[1] / this.props.slide.uperpixel;
          let size = 3 / this.props.slide.uperpixel;
          selected = [[x, y-size], [x-size, y], [x, y+size], [x+size, y], [x-size, y], [x, y-size], [x, y+size], [x+size, y], [x, y-size]];
          if (this.state.selectedMapOperation == mapOperation.ADD_POSITIVE) {
            this.setState({
              positiveLabels: this.state.positiveLabels.concat([selected])
            }, this.redrawMarkers)
          } else {
            this.setState({
              negativeLabels: this.state.negativeLabels.concat([selected])
            }, this.redrawMarkers)
          }
      } else if(closestFeature != null){
        let newNegativeLabels, newPositiveLabels;
        if (closestFeature.getProperties().positive) {
              let indexToSearch = closestFeature.getId().split('p')[1];
              if (this.state.selectedMapOperation == mapOperation.EDIT) {
                selected = this.state.positiveLabels[indexToSearch];
                newNegativeLabels = this.state.negativeLabels.concat([selected]);
              } else {
                newNegativeLabels = this.state.negativeLabels;
              }
              newPositiveLabels = this.state.positiveLabels.filter((positiveLabel, index) => index != indexToSearch);
          } else {
              let indexToSearch = closestFeature.getId().split('n')[1];
              if (this.state.selectedMapOperation == mapOperation.EDIT) {
                selected = this.state.negativeLabels[indexToSearch];
                newPositiveLabels = this.state.positiveLabels.concat([selected]);
              } else {
                newPositiveLabels = this.state.positiveLabels;
              }
              newNegativeLabels = this.state.negativeLabels.filter((negativeLabel, index) => index != indexToSearch);
          }
          this.setState({
            negativeLabels: newNegativeLabels, 
            positiveLabels: newPositiveLabels
          }, this.redrawMarkers)
      }
  }

  redrawMarkers = () => drawMarkers(this.vector, this.props.slide, this.props.annotation, this.state.positiveLabels, this.state.negativeLabels)

  getAnnotationMarkersAndCreateMap = (annotation) => {
    let url = getPrefixedUrl(this.props.slide.bucket_name + '/' + this.props.slide.path + '/ugc/annotations/' + annotation.id + '_markers.json', this.props.slide);
    let date = new Date();
    clearTimeout(this.timer);
    axios
      .get(url + "?d=" + date.getTime(), { headers: { Authorization: AuthHeader() } })
      .then(response => {
        try {
          let length = response.data.pos.length;
          this.setState({
            positiveLabels: response.data.pos, 
            negativeLabels: response.data.neg
          });
          this.createMap(annotation, response.data.pos, response.data.neg);
          this.vector.getSource().changed();
        } catch (err) {
          this.timer = setInterval(() => this.getAnnotationMarkersAndCreateMap(annotation), 2000);
          console.log("Analysis is Pending");
        }
      })
      .catch(err => {
        if (err.response.status == 404) {
          this.timer = setInterval(() => this.getAnnotationMarkersAndCreateMap(annotation), 2000);
          console.log("Analysis is Pending");
        } else {
          console.log("Failed Loading Annotation", err);
        }
      });
  }

  saveAnnotationData = () => {
    let data = {
      'pos': this.state.positiveLabels,
      'neg': this.state.negativeLabels, 
      'slide': this.props.slide.id,
      'annotation': this.props.annotation.id
    };
    let url = '/api/save_ihc_counts/';
    axios.post(url, data, { headers: { Authorization: AuthHeader() } })
    .then(response => {
        message.success("Saved Successfully")
    }).catch(err => {
        message.error("Saving Failed")
    });
  }

  componentWillReceiveProps = (nextProps, nextState) => {
    if (nextProps.annotation.id != this.props.annotation.id) {
      this.setState({
        isFetching: true,
        isErrored: false,
        loadedMap: false
      }, () => this.getAnnotationMarkersAndCreateMap(nextProps.annotation));
    }
  }

  componentDidUpdate = () => {
    if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
      this.state.annomap.setTarget("anno-map-" + this.props.annotation.id);
      this.state.annomap.getView();
      this.state.annomap.addLayer(this.vector);
      this.setState({
        loadedMap: true
      })
    }
  }

  onMapOperationChange = (event) => {
    let changeTo = event.target.value;
    if (changeTo == mapOperation.ADD) {
      changeTo = mapOperation.ADD_POSITIVE;
    }
    this.setState({selectedMapOperation: changeTo});
  }

  render() {

    if (this.state.isErrored) {
      console.log(this.state.errMessage);
    }

    let mapDiv;

    if (this.state.isFetching || this.state.isErrored) {
      mapDiv = undefined
    } else {
      mapDiv = <Col tabIndex="0" id={"anno-map-" + this.props.annotation.id} className="anno-map-container" style={{filter: convertSettingsToFilter(this.props.viewerSettingData)}}>
               </Col>
    }

    let posCounts = this.state.positiveLabels.length;
    let negCounts = this.state.negativeLabels.length;

    let dataSource = [
      {
        key: 0,
        positive: posCounts,
        negative: negCounts,
        total: posCounts + negCounts
      }, 
      {
        key: 1,
        positive: Math.ceil(posCounts * 100.0 / (posCounts + negCounts)) + "%",
        negative: Math.ceil(negCounts * 100.0 / (posCounts + negCounts)) + "%",
        total: 100.0 + "%"
      }
    ]

    return (
      <Spin spinning={this.state.isFetching || this.state.isErrored} tip="Analysing">
        <Row className="anno-map-row" >
          <Col className="anno-map-col">
            <Row>
              <Radio.Group className="anno-map-operation-chooser" defaultValue={mapOperation.EDIT} value={this.state.selectedMapOperation} buttonStyle="solid" onChange={this.onMapOperationChange}>
                  { [mapOperation.ADD_POSITIVE, mapOperation.ADD_NEGATIVE].includes(this.state.selectedMapOperation) ? [
                    <Radio.Button key={0} value={mapOperation.ADD_POSITIVE}>Add Positive</Radio.Button>, 
                    <Radio.Button key={1} value={mapOperation.ADD_NEGATIVE}>Add Negative</Radio.Button>
                  ] : <Radio.Button value={mapOperation.ADD}>Add</Radio.Button>}
                  <Radio.Button value={mapOperation.EDIT}>Edit</Radio.Button>
                  <Radio.Button value={mapOperation.DELETE}>Delete</Radio.Button>
              </Radio.Group>
            </Row>
            <Row>
              {mapDiv}
            </Row>
            <Row className="anno-map-counts-table overlayed-component">
              <Col>
                <Table 
                  dataSource={dataSource} 
                  columns={columns} 
                  pagination={false} />
                  <Row>
                    <Col span={12} offset={6}>
                      <Button
                        className="anno-map-counts-save"
                        onClick={this.saveAnnotationData}
                        size="small">
                        Save
                      </Button>
                    </Col>
                  </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  return {
    urlState: state.viewerUrlReducer,
    viewerSettingData: state.viewerSettingReducer,
  };
};

export default connect(mapStateToProps)(AnnotationMap);
