import React from  "react";
import SettingsIcon from '@mui/icons-material/Settings'
import GestureIcon from '@mui/icons-material/Gesture';
import SlideInfoApp from "./sidebarTop_apps/slide_info";
import GradingIcon from '@mui/icons-material/Grading';
import {Class, Grid3x3, Info, ScreenshotTwoTone, Crop169, KeyboardAltRounded, GradientTwoTone} from "@mui/icons-material";
import CaseInfoApp from "./sidebarTop_apps/case_info";
import {slideViewerType} from "../../utils/const";

import AnnotationsApp from "./sidebarTop_apps/annotationsApp";
import SettingsApp from "./sidebarTop_apps/settings";
import PreviewMap from "./sidebarBottom_apps/preview";
import PreviewIcon from '@mui/icons-material/Preview';
import Barcode from "./sidebarBottom_apps/barcode";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import GridOverlay from "./sidebarTop_apps/GridOverlay";
import Screenshot from "./sidebarTop_apps/Screenshot";
import Thumbnail from "./sidebarBottom_apps/thumbnail";
import DeepBioApp from "./sidebarTop_apps/deepBioApp";
import BackgroundBlendApp from "./sidebarTop_apps/backgroundBlendApp"

import {DeepSvg} from "./consts/deepBioConstants";
import {SvgIcon} from "@mui/material";
import CliaApp from "./sidebarTop_apps/cliaApp";
import ZStackControl from "./rightBottom_apps/ZStackControlApp";
import ZoomControl from "./rightBottom_apps/zoomControl";
import MapLayersMaker from "./rightBottom_apps/mapLayersMaker";
import ShortcutsApp from "./sidebarTop_apps/Shortcuts";

// Side Bar Apps

const getAnnotationsApp = (gammaState) => {
    const id = "annotations";
    return {
        id: id,
        component:<AnnotationsApp />,//send your component
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],    // send boolean permission logic here
        makeSideBarIcon: true,// show user without clicking
        icon: <GestureIcon sx={{fontSize:'inherit'}}/>,// custom mui icon
        title: "Annotations", //will be shown inside all apps section only
        showTitleOnHover: true,//only works if "makeSideBarIcon=true" and title is given
        appId: 1 // keep it unique for all the apps for triggering side bar top apps 0 reserved for allApps
    }
}

const getSlideInfoApp = (gammaState) => {
    const id = "slide_info";
    return {
        id: id,
        component: <SlideInfoApp viewerType={slideViewerType.GAMMA_SLIDE_VIEWER}/>,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon: <Info sx={{fontSize: 'inherit'}} />,
        title: "Slide Info",
        showTitleOnHover: true,
        appId: 2
    }
}

const getCaseInfoApp = (gammaState) => {
    const id = "case_info";
    return {
        id: id,
        component: <CaseInfoApp />,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon: <Class sx={{fontSize: 'inherit'}} />,
        title: "Case Info",
        showTitleOnHover: true,
        appId: 3,
    }
}

const getSettingsApp = (gammaState) => {
    const id = "settings";
    return {
        id: id,
        component: <SettingsApp />,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon : <SettingsIcon sx={{fontSize:'inherit'}}/>,
        title: "Settings",
        showTitleOnHover: true,
        appId: 4,
    }
}

const getGridOverlayApp = (gammaState) => {
    const id = "grid-overlay";
    return {
        id: id,
        component: <GridOverlay />,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: false,
        icon: <Grid3x3 sx={{fontSize: 'inherit'}}/>,
        title: "Grid",
        showTitleOnHover: true,
        appId: 5,
    }
}

const getScreenshotApp = (gammaState) => {
    const id = "screenshot";
    return {
        id: id,
        component: <Screenshot />,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: false,
        icon: <ScreenshotTwoTone sx={{fontSize: 'inherit'}} />,
        title: "Screenshot",
        showTitleOnHover: true,
        appId: 6,
    }
}

const getPreviewApp = (gammaState) => {
    const id = "preview-controls";
    return {
        id: id,
        component:<PreviewMap />,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon: <PreviewIcon sx={{fontSize:'inherit'}}/>,
        title: "Preview",
        showTitleOnHover: true,
        appId: 7,
    }
}

const getThumbnailViewerApp = (gammaState) => {
    const id = "thumbnail";
    return {
        id: id,
        title: "Thumbnail",
        component: <Thumbnail/>,
        icon: <Crop169 sx={{fontSize: 30}}/>,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        appId: 8,
    }
}

const getBarcodeApp = (gammaState) => {
    const id = "barcode";
    return {
        id: id,
        component:<Barcode />,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon: <QrCode2Icon sx={{fontSize:'inherit'}}/>,
        title: "Barcode",
        showTitleOnHover: true,
        appId: 9,
    }
}


const getDeepBioApp = (gammaState) => {
    const id = "deep_bio";
    const DeepDxIcon = (props) => <SvgIcon component={DeepSvg} {...props} htmlColor={"green"}/>;
    return {
        id: id,
        component: <DeepBioApp/>,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon: <DeepDxIcon sx={{fontSize:'inherit'}}/>,
        title: "DeepBio",
        showTitleOnHover: true,
        appId: 10,
    }
}

const getCliaApp = (gammaState) => {
    const id = "clia";
    return {
        id: id,
        component: <CliaApp />,
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon: <GradingIcon sx={{fontSize: 'inherit'}} />,
        title: "Clia",
        showTitleOnHover: true,
        appId: 11,
    }
}

const getLayersApp = (gammaState) => {
    const id = "layers";
    return {
        id: id,
        component: <MapLayersMaker opacity={1}/>,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        title: "Layers",
        showTitleOnHover: true,
        appId: 12,
    }
}


const getZoomControlApp = (gammaState) => {
    const id = "zoomControl";
    return {
        id: id,
        component: <ZoomControl opacity={1}/>,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        title: "Zoom Control",
        showTitleOnHover: true,
        appId: 13,
    }
}


const getZStackControlApp = (gammaState) => {
    const id = "zStackControl";
    return {
        id: id,
        component: <ZStackControl opacity={1}/>,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        title: "Z Stack Control",
        showTitleOnHover: true,
        appId: 14,
    }
}

const getShortcutsApp = (gammaState) => {
    const id = "shortcuts";
    return {
        id: id,
        component: <ShortcutsApp />,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        makeSideBarIcon: true,
        icon : <KeyboardAltRounded sx={{fontSize:'inherit'}}/>,
        title: "Shortcuts",
        showTitleOnHover: true,
        appId: 15,
    }
}

// made for HTIC (Maximus scanners) wanted the scan boundary to blend with the background
const getBackgroundBlendApp = (gammaState) => {
    const id = "background_blend";
    return {
        id: id,
        component: <BackgroundBlendApp />, // send your component
        defaultPermission: false,
        permission: gammaState.appsInstalled[id + "_installed"], // send boolean permission logic here
        makeSideBarIcon: true, // show user without clicking
        icon: <GradientTwoTone sx={{fontSize:'inherit'}}/>, // custom mui icon
        title: "Background Blend", // will be shown inside all apps section only
        showTitleOnHover: true, // only works if "makeSideBarIcon=true" and title is given
        appId: 16 // keep it unique for all the apps for triggering side bar top apps 0 reserved for allApps
    }
}

export const getAllSideBarTopApps = (gammaState) => {
    // now this should return a list of app components
    return [
        getAnnotationsApp(gammaState),
        getSettingsApp(gammaState),
        getSlideInfoApp(gammaState),
        getCaseInfoApp(gammaState),
        getGridOverlayApp(gammaState),
        getScreenshotApp(gammaState),
        getBackgroundBlendApp(gammaState),
        getCliaApp(gammaState),
        getDeepBioApp(gammaState),
        getShortcutsApp(gammaState),
    ];
}

export const getAllSideBarBottomApps = (gammaState) => {
    return [
        getPreviewApp(gammaState),
        getThumbnailViewerApp(gammaState),
        getBarcodeApp(gammaState),
    ];
}

export const getAllRightBottomApps = (gammaState) => {
    // app make in row reverse
    return [
        getZStackControlApp(gammaState),
        getZoomControlApp(gammaState),
        getLayersApp(gammaState),
    ];
}
