import React, { Component } from 'react';
import { Row, Col, Input, Checkbox, Divider, Button, message, Slider, InputNumber } from 'antd';
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from 'axios';
import { AuthHeader } from '../../../helper/auth.token';
import { scanWorkflowLoadingMessages } from '../../../utils/const';
import { updateActionOngoing } from '../../../action/preview_status.action';
import { SearchOutlined } from '@ant-design/icons';

class CameraSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exposureInputValue: 20,
            gammaInputValue: 1,
            inputFieldInFocus: false,
            livemodeConfig: {},
        }
        this.Timer = null;
    }

    componentDidMount = () => {
        
    }

    componentDidUpdate = (prevProps, prevState) => {
        if((prevProps.exposureInputValue !== this.props.exposureInputValue) || (prevProps.gammaInputValue !== this.props.gammaInputValue)) {
            this.handleCheck();
        }
    }

    handleCheck = () => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.Timer);
        this.Timer = setTimeout(() => {
            this.props.callToAutoUpdate(false, false)
        }, 500);
    }

    exposureValueChange = (value) => {
        this.setState({
            exposureInputValue: value
        });
    }

    gammaValueChange = (value) => {
        this.setState({
            gammaInputValue: value
        });
    }

    onFocusInputField = () => {
        this.setState({
            inputFieldInFocus: true,
        });
    }

    onBlurInputField = () => {
        this.setState({
            inputFieldInFocus: false,
        });
    }

    getCameraSliderRow = (haveRowStyle, heading, slider, buttons) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
            <Col span={5} style={{marginTop: "15px"}}>
                <b>{heading}</b>
            </Col>
            <Col span={10} style={{marginRight: 5}}>
                {slider}
            </Col>
            <Col span={8}>
                {buttons}
            </Col>
            </Row>
        )
    }

    render() {

        let minExposureValue = this.state.livemodeConfig.minExposure != undefined ? this.state.livemodeConfig.minExposure : 5;
        let maxExposureValue = this.state.livemodeConfig.maxExposure != undefined ? this.state.livemodeConfig.maxExposure : 25;

        let minGammaValue = this.state.livemodeConfig.minGamma != undefined ? this.state.livemodeConfig.minGamma : 0.1;
        let maxGammaValue = this.state.livemodeConfig.maxGamma != undefined ? this.state.livemodeConfig.maxGamma : 1;

        return (
            <Row>
                <Col span={18}>
                    {this.getCameraSliderRow(false, 'Exposure',
                        <Row>
                            {/* <Col span={18}>
                                <Slider value={this.props.exposureInputValue} min={minExposureValue} max={maxExposureValue} //onAfterChange={this.setExposureValue} 
                                    onChange={this.props.exposureValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}}  />
                            </Col> */}
                            <Col span={5} offset={1}>
                                <InputNumber min={minExposureValue} max={maxExposureValue} style={{ margin: '10px 16px' }} 
                                    value={this.props.exposureInputValue} onChange={this.props.exposureValueChange}/>
                            </Col>
                        </Row>,
                    <>
                        <Row><Button type="primary" size={"small"} style={{marginRight: 5}} onClick={() => {this.props.exposureValueChange(this.props.exposureInputValue+(5))}}>+5</Button></Row>
                        <Row><Button type="primary" size={"small"} style={{marginRight: 5, marginTop: 5}} onClick={() => {this.props.exposureValueChange(this.props.exposureInputValue-(5))}}>-5</Button></Row>
                    </>
                    )}
                    {this.getCameraSliderRow(true, 'Gamma',
                    <Row>
                        <Col span={5} offset={1}>
                        <InputNumber min={minGammaValue} max={maxGammaValue} style={{ margin: '10px 16px' }} step={0.1} 
                                        value={this.props.gammaInputValue} onChange={this.props.gammaValueChange}/>
                        </Col>
                    </Row>,null
                    )}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
		preview: state.previewStatusReducer[ownProps.id],
    };
};

export default connect(mapStateToProps)(CameraSettings);
