import React, {Component} from  "react";
import {connect} from 'react-redux';
import cookie from "react-cookies";
import axios from "axios";
import { AuthHeader } from "../../../../helper/auth.token";
import {Row, Col, Switch, Divider, message, Spin, Radio, Select as SelectAntd, Button} from 'antd';
import { Fill, Stroke, Style } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import {click} from 'ol/events/condition.js';
import Select from 'ol/interaction/Select.js';
import { updateAppClosedStatus, updateShape, updateIsDrawing } from '../../../../action/tile.viewer.state.action';
import { getDrawingTools } from './drawing/draw_tool_maker';
import { CirclePicker } from 'react-color';
import { getAnnotationList, addAnnotation, drawAnnotations, getAnnotationsInListView, getAnnotationsInGridView } from './annotations_app_utils';
import { AllAnnotationsConstants } from '../../../../consts/anno.const';
import { mapLayerTypes } from "../../../../utils/const";
import "../../../../asset/style/neoviewer/annotations_app.css";

const { Option } = SelectAntd;

class AbstractAnnotator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTool: null,
            selectedColor: "#7CFC00",
            continuousMode: false, 
            isFetching: false, 
            isDrawing: false,
            annotations: [], 
            busyMessage: "...", 
            selectedAnnotationId: null,
            gridView: false,
            imgsPerColInGridView: 3,
            filteredColor: AllAnnotationsConstants.ALL_COLORS,
            showAnnoLayer: true,
            numTimesCalled: 1,
        }
        
        this.timer = null;
        this.vector = new VectorLayer({
            source: new VectorSource({}),
            name: mapLayerTypes.ANNOTATION_LAYER_NAME,
        });
        this.vector.set('annotationLayer', true);
        this.selectHover = new Select({
            condition: click, 
            layers: [this.vector], 
            wrapX: false,
            hitTolerance: 5,
            style: new Style({
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.0)',
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.0)'
                })
            })
        });
    }

    handleAnnoColorChange = (color) => {
        this.setState({ selectedColor: color.hex });
    };

    onSelected = (newSelection) => {
        let newTool = newSelection === this.state.selectedTool ? null : newSelection;
        this.setState({
            selectedTool: newTool
        })
        this.props.dispatch(updateShape(newTool));
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextState.isDrawing) return false;
        return true;
    }

    setDrawing = (value) => {

        if (value) {
            this.removeSelectInteraction();
        } else if (!this.state.continuousMode) {
            this.onSelected(null);
            this.addSelectInteraction();
        }

        this.setState({isDrawing: value});
        this.props.dispatch(updateIsDrawing(value));
    }
    
    redrawAnnotations = () => drawAnnotations(this.vector, this.state.annotations, this.state.selectedAnnotationId);

    removeSelectInteraction = () => {
        this.props.slidemap.removeInteraction(this.selectHover);
    }

    changeSelectedAnnotation = (selectedId) => {    
        this.setState({
            selectedAnnotationId: selectedId
        }, this.redrawAnnotations)
    }

    addSelectInteraction = () => {
        this.props.slidemap.addInteraction(this.selectHover);
        this.selectHover.on("select", e => {
            let selectedFeature = undefined;
            if (e.deselected.length > 0) {
                if (e.selected.length > 0) {
                    if (e.selected[0].getId() !== e.deselected[0].getId() || e.selected[0].getId() != this.state.selectedAnnotationId) {
                        selectedFeature = e.selected[0];
                    } 
                }
            } else if (e.selected.length > 0) {
                selectedFeature = e.selected[0];
            }
            // if (selectedFeature.getProperties().creator != this.props.creator) selectedFeature = undefined;
            if (selectedFeature != undefined) {
                this.changeSelectedAnnotation(selectedFeature.getId());
                if (this.props.openAppOnAnnoClick && this.props.urlState.app_state[this.props.id].closed) {
                    this.props.dispatch(updateAppClosedStatus(this.props.id, false, this.props.urlState));
                }
            } else {
                this.selectHover.getFeatures().clear();
                this.setState({
                    selectedAnnotationId: null
                }, this.redrawAnnotations);
            }
        });
    }

    componentWillMount = () => {
        let zIndex = (this.props.slide.x_fields * 10000) + this.props.slide.y_fields + 1000000000;
        this.vector.setZIndex(zIndex);
        this.props.slidemap.addLayer(this.vector);
        this.addSelectInteraction();
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.selectHover.getFeatures().clear();
            this.setState({
                selectedAnnotationId: null
            }, this.redrawAnnotations)
        }
    }

    doRefreshAnnotationsFromBackend = () => getAnnotationList(this.props.slide.id, this.props.creator, (annotations) => {
        this.setState({
            annotations
        }, this.redrawAnnotations);
    })

    handleScroll = () => {
        var element = document.querySelector('.scroll-row');
        if(element != null) {
            element.scrollIntoView();
        }
    }

    componentDidMount = () => {
        this.doRefreshAnnotationsFromBackend();
        this.timer = setInterval(this.doRefreshAnnotationsFromBackend, 2000);
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate = (prevProps, prevState) => {
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer);
        this.timer = null;
    }

    switchContinuousMode = (value) => {
        this.setState({
            continuousMode: value, 
            selectedTool: (value) ? this.state.selectedTool : null
        }, () => this.setDrawing(false));
    }

    switchGridView = (e) => {
        this.setState({
            gridView: e.target.value == 1
        })
    }

    onChangeImgsPerColInGridView = (val) => {
        this.setState({
            imgsPerColInGridView: val
        })
    }

    onSelectAnnotationFromList = (annotation) => {
        let selectedFeature = this.vector.getSource().getFeatures().find((feature) => {
            return feature.getId() == annotation.id;
        });
        this.props.slidemap.getView().fit(selectedFeature.getGeometry());
        this.setState({
            selectedAnnotationId: annotation.id
        }, this.redrawAnnotations);
    }

    onDeleteAnnotation = (e, id) => {
        this.setState({
            isFetching: true, 
        })
        let url = `/api/tile_viewer_annotation/${id}/ `;
        axios.delete(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 204 || response.status === 200 || response.status === 301 || response.status === 302) {
                let features = this.vector.getSource().getFeatures();
                let result = features.filter(index => index.getId() === id);
                this.vector.getSource().removeFeature(result[0]);
                let currentAnnotations = this.state.annotations;
                for (let i = 0; i < currentAnnotations.length; i++) {
                    if (currentAnnotations[i].id == id) {
                        currentAnnotations.splice(i, 1);
                        break;
                    }
                }
                this.setState({
                    annotations: currentAnnotations,
                    selectedAnnotationId: (this.state.selectedAnnotationId == id) ? null : this.state.selectedAnnotationId
                }, this.redrawAnnotations)
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                message.error('Annotation Delete Failed', 2.5);
            }
            this.setState({
                isFetching: false
            })
        })
        .catch(err => {
            this.setState({
                isFetching: false
            })
        });
        e.stopPropagation();
    }

    getAllColorOptions = (colors) => {
        let colorOptions = colors.map((color, index) => {
            return <Option value={color.toLowerCase()} key={index}>
                <div className="color-icon-option" style={{backgroundColor: color.toLowerCase()}}></div>
            </Option>
        });
        colorOptions.unshift(<Option value={AllAnnotationsConstants.ALL_COLORS} key={colors.length + 1}>All Colors</Option>);
        return colorOptions;
    }

    changeColorFilter = (value) => {
        if(value.toLowerCase() != this.state.filteredColor.toLowerCase()) {
            this.setState({
                selectedAnnotationId: null,
            });
        }
        this.setState({
            filteredColor: value.toLowerCase(),
        });
    }

    toggleAnnotationLayer = () => {
        let layers = this.props.slidemap.getLayers().getArray();
        if (this.state.showAnnoLayer) {
            for (let i = 0; i < layers.length; i++) {
                let layer = layers[i];
                if (layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME) {
                    layer.setVisible(false);
                }
            }
        } else {
            for (let i = 0; i < layers.length; i++) {
                let layer = layers[i];
                if (layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME) {
                    layer.setVisible(true);
                }
            }
        }
        this.setState({
            showAnnoLayer: !this.state.showAnnoLayer,
        });
    }

    render(){

        let currentAnnotations = this.state.annotations;

        if(this.state.filteredColor != AllAnnotationsConstants.ALL_COLORS) {
            currentAnnotations = currentAnnotations.filter((value, index) => {
                return value.color.toLowerCase() == this.state.filteredColor.toLowerCase();
            });
        }

        if((this.props.urlState || {}).presentCode != undefined) {
            this.handleScroll();
        }

        let drawToolParams = {
            slidemap: this.props.slidemap,
            vector: this.vector, 
            onSelected: this.onSelected, 
            currentSelectedKey: this.state.selectedTool, 
            continuousMode: this.state.continuousMode,
            setDrawing: this.setDrawing, 
            addSelectInteraction: this.addSelectInteraction, 
            removeSelectInteraction: this.removeSelectInteraction, 
            addAnnotation: (area, perimeter, key, coord, center) => {
                addAnnotation(this.state.selectedColor, area, perimeter, key, coord, center, this.props.creator, this.props.urlState.z, this.props.slide.id, this.doRefreshAnnotationsFromBackend,this.state.numTimesCalled);
                this.state.numTimesCalled +=1;
            }
        }

        let drawingTools = getDrawingTools(drawToolParams, this.props.drawToolKeys);

        let colSpan = 24 / drawingTools.length;

        let annotationsDiv;
        
        if (this.props.annotationsViewCreator == undefined) { // Use Default View if not supplied a function
            if (this.state.gridView && cookie.loadAll().deployment_mode === 'offline') {
                annotationsDiv = getAnnotationsInGridView(currentAnnotations, this.state.selectedAnnotationId, this.props.zoomScale, 
                    this.props.slide, this.onSelectAnnotationFromList, this.onDeleteAnnotation, 
                    this.doRefreshAnnotationsFromBackend, this.props.viewerSettingData, this.state.imgsPerColInGridView, 
                    this.onChangeImgsPerColInGridView, this.props.urlState);
            } else {
                annotationsDiv = getAnnotationsInListView(currentAnnotations, this.props.slide, this.state.selectedAnnotationId, 
                    this.onSelectAnnotationFromList, this.props.viewerSettingData, this.props.zoomScale,
                    this.onDeleteAnnotation, this.doRefreshAnnotationsFromBackend, this.props.urlState);
            }
        } else {
            annotationsDiv = this.props.annotationsViewCreator(currentAnnotations, this.state.selectedAnnotationId, this.onSelectAnnotationFromList,
                    this.onDeleteAnnotation, this.doRefreshAnnotationsFromBackend);
        }

        let colors = AllAnnotationsConstants.COLORS_LIST;

        return (
            <Row className={(this.props.extraClass === undefined ? "" : this.props.extraClass) + " annotations-app-parent overlayed-component app-parent"} id="annotations-app-parent-id">
                <Col>
                    <Divider />
                    <Row>
                        <Col span={10} offset={6}>
                        <CirclePicker width={'400px'} circleSize={18} colors={colors} 
                                      color={ this.state.selectedColor }
                                      onChangeComplete={(this.props.urlState || {}).presentCode != undefined ? null : this.handleAnnoColorChange }/>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                    {
                        drawingTools.map((drawingTool, index) => {
                            return <Col key={index} span={colSpan}>
                                {drawingTool}
                            </Col>
                        })
                    }
                    </Row> 
                    <Divider /> 
                    <Spin spinning={this.state.isFetching} tip={this.state.busyMessage}>
                        {this.props.showOptions ? 
                            <Row>
                                <Col>
                                    <Row>
                                        {/* <Col span={6}>
                                            {cookie.loadAll().deployment_mode === 'offline' ? 
                                                <Radio.Group 
                                                    size="small" 
                                                    className="grid-switch" 
                                                    value={this.state.gridView ? 1 : 0} 
                                                    onChange={(this.props.urlState || {}).presentCode != undefined ? null : this.switchGridView}>
                                                    <Radio.Button className={(this.props.urlState || {}).presentCode != undefined ? "grid-switch-radio-present" : "grid-switch-radio"} value={0}>List</Radio.Button>
                                                    <Radio.Button className={(this.props.urlState || {}).presentCode != undefined ? "grid-switch-radio-present" : "grid-switch-radio"} value={1}>Grid</Radio.Button>
                                                </Radio.Group>  
                                                : undefined
                                            }
                                        </Col> */}
                                        <Col span={6} offset={3}>
                                            <SelectAntd value={this.state.filteredColor.toLowerCase()} style={{ width: 120 }} onChange={this.changeColorFilter} size="small">
                                                {this.getAllColorOptions(colors)}
                                            </SelectAntd>
                                        </Col>
                                            <Switch 
                                                className="continuous-mode-switch"
                                                defaultChecked={false} 
                                                size="small"
                                                checked={this.state.continuousMode}
                                                onChange={this.switchContinuousMode}
                                                disabled={(this.props.urlState || {}).presentCode != undefined}
                                            />
                                            <span className="continuous-mode-text unselectable">Continuous Drawing Mode</span> 
                                    </Row> 
                                    <Row style={{marginBottom: 5, marginTop: 5}}>
                                        <Col span={24} style={{textAlign: 'center'}}>
                                            <Button size="small" onClick={this.toggleAnnotationLayer}>
                                                {this.state.showAnnoLayer ? "HIDE ANNOTATIONS" : "SHOW ANNOTATIONS"}
                                            </Button>
                                        </Col>
                                        <Col span={8} style={{textAlign: 'left'}}>
                                            <Button size="small">
                                                Download CSV
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>  
                            </Row>
                            : undefined 
                        }
                        <Row>
                            {annotationsDiv}
                        </Row>
                    </Spin>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.tileViewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    }
}

export default connect(mapStateToProps)(AbstractAnnotator);
