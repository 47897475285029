import React from "react";

import ReactDOM from "react-dom";


export const DeepBioConstants = {
    ALL_LABELS : "all_labels",
    LABELS_LIST : ["Pattern3","Pattern4","Pattern5","IDC-P"],
    LABEL_COLORS : ["rgba(255, 255, 0, 0.8)","rgba(255, 165, 0, 0.8)","rgba(255, 0, 0, 0.8)","rgba(0, 0, 255, 0.7)"]
}


export const DeepSvg = (props) => (
    <svg width="28px" height="28px" viewBox="0 0 228.000000 225.000000" xmlns="http://www.w3.org/2000/svg">

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"  stroke="none">

            <path d="M0 1125 l0 -1125 1140 0 1140 0 0 1125 0 1125 -1140 0 -1140 0 0

-1125z m745 622 c19 -19 37 -47 41 -62 l7 -29 -28 -52 -28 -52 6 -16 6 -16 28

0 27 0 131 130 132 130 42 0 c23 0 55 -7 71 -15 52 -27 75 -102 47 -151 -7

-12 -87 -95 -179 -184 l-168 -162 0 -28 0 -29 20 -6 20 -6 216 208 c119 114

228 216 242 225 34 22 76 23 112 2 37 -22 60 -64 60 -108 l0 -36 -40 -44 -40

-43 0 -32 0 -31 28 0 28 0 74 76 c41 42 90 83 108 91 l34 14 34 -11 c18 -6 45

-25 59 -41 l25 -31 0 -34 c0 -20 -5 -45 -11 -57 -6 -12 -65 -76 -131 -143

l-120 -121 4 -24 3 -24 24 -3 24 -4 56 55 c31 30 66 60 78 66 30 15 64 14 107

-4 l36 -15 15 -30 c8 -16 15 -44 15 -63 l0 -35 -17 -19 c-10 -11 -88 -88 -174

-171 l-157 -152 -42 0 -42 0 -39 39 -39 39 0 33 c0 19 9 49 20 67 11 18 20 44

20 57 l0 25 -28 0 -27 0 -115 -116 c-63 -63 -127 -122 -142 -130 -15 -8 -43

-14 -63 -14 l-37 0 -30 21 c-17 12 -37 37 -45 56 l-14 35 11 33 11 32 175 168

175 168 -3 26 -3 26 -32 0 -33 0 -220 -213 -220 -214 -38 -5 -37 -4 -31 19

c-17 11 -38 36 -48 55 l-17 36 7 28 c4 15 24 49 46 76 l39 47 -3 23 -3 22 -23

3 -23 4 -88 -85 -88 -85 -31 -7 -32 -7 -44 23 -45 22 -16 33 c-17 37 -19 58

-5 83 5 10 68 76 140 147 l131 128 -3 26 -3 25 -28 3 -29 4 -68 -66 -68 -66

-39 0 -38 0 -33 20 -33 20 -15 36 -15 36 6 27 6 28 174 166 175 167 41 0 41 0

33 -33z m493 -1282 c16 -13 35 -38 42 -55 l12 -30 -6 -26 c-4 -15 -22 -42 -41

-61 l-33 -33 -43 0 c-53 1 -84 16 -110 56 -33 51 -22 108 29 151 l27 23 47 0

46 0 30 -25z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#979797" stroke="none">

            <path d="M445 1623 l-175 -167 -6 -33 -6 -34 14 -37 14 -37 33 -22 34 -23 49

0 50 0 68 66 68 66 19 -4 c10 -2 19 -10 21 -19 l3 -15 -131 -128 c-72 -71

-135 -137 -140 -147 -14 -26 -12 -66 5 -103 l16 -33 43 -21 c79 -40 111 -29

222 80 l83 81 15 -6 c9 -4 16 -11 16 -17 0 -6 -18 -32 -39 -58 l-39 -47 -7

-37 -7 -36 18 -37 c9 -21 31 -46 48 -56 l31 -19 35 1 c19 0 42 3 51 7 27 11

383 362 375 369 -4 4 -16 2 -26 -5 l-20 -12 0 139 0 140 10 6 c16 10 12 35 -6

35 -9 0 -73 -56 -142 -124 l-127 -123 -12 4 c-7 3 -13 13 -13 23 l0 18 157

153 157 153 -12 4 -12 4 0 84 0 84 15 0 c8 0 15 -7 15 -15 0 -8 7 -15 15 -15

8 0 15 5 15 10 0 6 -7 13 -15 16 -8 4 -15 12 -15 18 0 20 -46 36 -102 36 l-51

0 -132 -130 c-72 -71 -138 -130 -147 -130 -29 0 -30 18 -4 63 15 24 26 56 26

71 0 36 -23 79 -56 105 l-27 21 -49 0 -48 0 -175 -167z" />

            <path d="M1230 1665 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25

-5 0 -10 -11 -10 -25z" />

            <path d="M1205 1599 c-9 -29 4 -38 21 -15 18 25 18 36 0 36 -8 0 -18 -9 -21

-21z" />

            <path d="M1280 1563 l-35 -38 3 -167 3 -168 13 0 c8 0 19 7 26 15 7 8 21 15

31 15 l19 0 0 15 c0 8 -7 15 -15 15 l-15 0 0 149 0 149 15 12 c8 7 15 19 15

26 0 25 -25 15 -60 -23z" />

            <path d="M1292 1125 l-42 -46 0 -174 0 -175 -15 0 c-8 0 -15 -7 -15 -15 l0

-15 -20 0 -20 0 0 138 0 138 30 29 c28 27 40 55 22 55 -4 0 -58 -48 -119 -106

l-113 -105 -11 -38 -11 -38 12 -37 12 -36 32 -25 33 -25 47 0 c26 0 57 5 69

12 12 6 46 35 76 64 l55 53 -3 167 -3 166 21 15 c21 14 29 43 13 43 -5 0 -27

-20 -50 -45z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#949494" stroke="none">

            <path d="M1180 1771 l25 -21 -17 6 -18 6 0 -100 0 -100 13 -5 12 -5 -12 -1

c-7 -1 -13 -6 -13 -13 0 -6 14 1 30 17 l31 29 -6 14 -5 13 10 -6 c10 -6 12 -2

15 55 3 43 -4 72 -15 65 -4 -3 -7 3 -6 12 l1 16 -35 20 -35 19 25 -21z" />

            <path d="M1354 1631 c-58 -43 -191 -181 -174 -181 l14 0 -12 -12 -12 -12 0

-157 c0 -87 4 -160 8 -163 5 -3 37 24 72 59 36 36 60 65 55 65 -4 0 -16 -7

-26 -16 l-18 -16 -3 165 c-2 91 -1 165 2 164 3 -1 5 1 5 4 0 6 56 59 63 59 3

0 -2 -8 -11 -18 l-17 -19 0 -156 0 -157 17 0 c9 0 13 -3 10 -7 -4 -3 1 -11 10

-18 l16 -12 -8 -19 c-4 -10 -8 -22 -7 -26 1 -3 -6 -12 -16 -20 l-17 -13 14 20

c8 11 -23 -17 -70 -62 l-84 -83 3 -159 3 -159 15 -6 15 -6 -7 12 -7 11 16 -6

16 -6 10 13 c6 7 9 16 6 19 -3 4 -2 5 2 2 10 -8 33 16 26 28 l-6 10 10 -6 c5

-3 47 32 94 79 l84 84 17 0 18 0 0 -15 c0 -8 -9 -29 -20 -47 l-20 -33 0 -44 0

-43 39 -39 39 -39 52 0 52 0 157 152 c86 83 164 160 174 171 l17 19 0 45 c0

24 -7 57 -15 73 l-15 30 -36 15 c-46 19 -97 19 -132 1 -15 -8 -49 -37 -77 -65

-27 -28 -55 -51 -62 -51 -8 0 -16 7 -20 16 l-6 16 120 121 c67 67 126 132 132

144 6 12 11 42 11 67 l0 44 -26 31 -25 31 -38 11 -38 11 -34 -11 -35 -11 -79

-80 -80 -80 -17 0 -18 0 0 23 0 23 35 34 c19 19 39 49 44 67 l9 33 -9 33 c-11

38 -25 57 -59 80 l-24 17 -42 0 -41 0 -39 -29z m-134 -484 c0 -2 -10 -12 -22

-23 l-23 -19 19 23 c18 21 26 27 26 19z m73 -374 c-6 -7 -16 -13 -22 -13 l-11

0 0 153 0 153 18 24 17 25 5 -165 5 -165 -12 -12z m-63 274 c0 -2 -15 -16 -32

-33 l-33 -29 29 33 c28 30 36 37 36 29z" />

            <path d="M905 1640 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0

-8 -4 -11 -10z" />

            <path d="M845 1580 c-22 -22 -35 -40 -31 -40 11 0 79 70 74 75 -2 3 -22 -13

-43 -35z" />

            <path d="M1125 1420 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0

-8 -4 -11 -10z" />

            <path d="M1095 1390 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0

-8 -4 -11 -10z" />

            <path d="M1065 1360 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0

-8 -4 -11 -10z" />

            <path d="M906 1208 c3 -5 10 -6 15 -3 13 9 11 12 -6 12 -8 0 -12 -4 -9 -9z" />

            <path d="M760 1069 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10

-5 -10 -11z" />

            <path d="M775 790 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4 -10

-10z" />

            <path d="M825 790 c-8 -13 5 -13 25 0 l15 10 -17 0 c-9 0 -20 -4 -23 -10z" />

            <path d="M1093 653 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />

            <path d="M1136 502 c-63 -8 -106 -60 -106 -128 l0 -37 20 -32 c23 -38 65 -58

120 -58 l39 0 26 17 c35 23 65 74 65 111 0 17 -9 44 -20 62 -31 52 -81 74

-144 65z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#ebebeb" stroke="none">

            <path d="M700 1786 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z" />

            <path d="M1044 1774 l-19 -15 25 12 c31 14 36 19 24 19 -6 0 -19 -7 -30 -16z" />

            <path d="M1208 1752 c9 -10 19 -17 21 -16 6 5 -20 34 -30 34 -5 0 -1 -8 9 -18z" />

            <path d="M786 1697 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z" />

            <path d="M1385 1650 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />

            <path d="M1475 1648 c11 -6 28 -18 38 -26 9 -8 17 -11 17 -6 0 10 -52 44 -67

44 -4 0 1 -6 12 -12z" />

            <path d="M786 1633 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M1134 1422 c-116 -114 -159 -157 -95 -96 64 60 127 119 141 130 30

24 177 174 170 174 -3 0 -100 -94 -216 -208z" />

            <path d="M1216 1583 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M746 1563 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M1556 1523 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M1753 1523 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1546 1483 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M1845 1470 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M275 1459 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />

            <path d="M1471 1374 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />

            <path d="M280 1331 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M1730 1205 l-115 -120 120 117 c65 65 117 119 115 120 -3 2 -57 -51

-120 -117z" />

            <path d="M440 1280 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z" />

            <path d="M1320 1223 c8 -3 22 -11 30 -17 l15 -11 -14 18 c-7 9 -21 17 -30 16

l-16 -1 15 -5z" />

            <path d="M1785 1160 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />

            <path d="M1860 1166 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z" />

            <path d="M1695 1088 l-60 -63 65 60 c35 33 66 61 69 63 2 1 0 2 -5 2 -5 0 -36

-28 -69 -62z" />

            <path d="M1928 1132 c9 -10 19 -17 21 -16 6 5 -20 34 -30 34 -5 0 -1 -8 9 -18z" />

            <path d="M355 1079 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />

            <path d="M729 1023 l-24 -26 27 19 c15 10 28 22 28 27 0 12 -5 8 -31 -20z" />

            <path d="M1951 983 l-11 -28 15 19 c8 11 15 23 15 28 0 15 -7 8 -19 -19z" />

            <path d="M360 991 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M685 969 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />

            <path d="M410 938 l35 -22 47 -1 47 0 13 13 13 13 -33 -12 -33 -11 -33 6 c-19

4 -39 13 -46 21 -7 8 -20 15 -29 15 l-16 0 35 -22z" />

            <path d="M1448 913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z" />

            <path d="M670 890 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1470 865 c-7 -9 -11 -17 -9 -20 3 -2 10 5 17 15 14 24 10 26 -8 5z" />

            <path d="M717 809 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M860 800 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z" />

            <path d="M983 794 c-7 -18 13 -90 27 -99 5 -3 2 11 -6 31 -9 21 -14 48 -11 61

5 26 -1 31 -10 7z" />

            <path d="M757 789 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M1259 735 c-37 -36 -64 -65 -60 -65 4 0 36 29 71 65 35 36 62 65 60

65 -3 0 -35 -30 -71 -65z" />

            <path d="M1440 741 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M1620 665 c-14 -8 -20 -14 -14 -15 5 0 19 7 30 15 24 18 16 19 -16 0z" />

            <path d="M1057 659 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M1163 503 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1078 479 c-16 -11 -28 -26 -28 -32 l0 -11 13 16 c6 8 23 22 37 32

13 9 20 16 15 16 -6 0 -22 -10 -37 -21z" />

            <path d="M1201 496 c2 -2 15 -9 29 -15 l25 -12 -19 15 c-11 9 -24 16 -30 16

-5 0 -7 -2 -5 -4z" />

            <path d="M1035 419 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />

            <path d="M1287 399 l7 -23 4 13 c2 7 -1 18 -6 23 l-11 11 6 -24z" />

            <path d="M1287 351 l-6 -24 11 11 c5 5 8 16 6 23 l-4 13 -7 -23z" />

            <path d="M1030 341 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M1067 279 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M1220 265 c-14 -8 -20 -14 -14 -15 5 0 19 7 30 15 24 18 16 19 -16 0z" />

            <path d="M1163 243 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#e3e3e3" stroke="none">

            <path d="M623 1783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M720 1776 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z" />

            <path d="M1019 1753 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z" />

            <path d="M766 1737 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z" />

            <path d="M1240 1700 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M792 1665 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />

            <path d="M1356 1635 c-11 -8 -16 -15 -10 -15 5 0 18 7 28 15 11 8 16 15 10 15

-5 0 -18 -7 -28 -15z" />

            <path d="M1240 1630 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1546 1577 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z" />

            <path d="M1046 1419 l-166 -161 1 -17 0 -16 9 20 c4 12 81 92 170 178 89 86

160 157 157 157 -3 0 -80 -73 -171 -161z" />

            <path d="M768 1523 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />

            <path d="M1723 1523 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1665 1500 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />

            <path d="M1536 1463 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M264 1426 c-3 -8 -4 -25 -2 -38 l4 -23 5 38 c5 38 2 47 -7 23z" />

            <path d="M598 1403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z" />

            <path d="M1470 1390 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M270 1351 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M305 1300 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M460 1290 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z" />

            <path d="M1303 1223 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M893 1213 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1704 1178 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1674 1148 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M375 1119 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />

            <path d="M1644 1118 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M645 1018 l-80 -83 83 80 c76 74 87 85 79 85 -1 0 -38 -37 -82 -82z" />

            <path d="M749 1088 l13 -17 -6 -23 -6 -23 12 23 11 23 -18 17 -19 17 13 -17z" />

            <path d="M1609 1083 l-14 -18 18 14 c16 13 21 21 13 21 -2 0 -10 -8 -17 -17z" />

            <path d="M350 1041 c0 -17 4 -32 8 -35 4 -2 6 11 4 30 -2 19 -6 34 -8 34 -2 0

-4 -13 -4 -29z" />

            <path d="M1600 1056 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z" />

            <path d="M672 910 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />

            <path d="M483 913 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1380 853 l-65 -68 68 65 c62 60 72 70 64 70 -1 0 -32 -30 -67 -67z" />

            <path d="M1481 884 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />

            <path d="M680 861 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M1000 839 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z" />

            <path d="M705 820 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M1440 810 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M823 783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1294 768 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1264 738 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1450 721 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z" />

            <path d="M1234 708 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M1160 660 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z" />

            <path d="M1517 659 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M1290 370 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#ababab" stroke="none">

            <path d="M1185 1770 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M510 1683 l-85 -88 88 85 c81 79 92 90 84 90 -1 0 -41 -39 -87 -87z" />

            <path d="M1225 1730 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M1240 1690 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1423 1653 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 l-30 0 18 -5z" />

            <path d="M1240 1640 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1354 1628 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M1521 1614 c13 -17 26 -30 28 -27 3 2 -8 16 -24 31 l-28 27 24 -31z" />

            <path d="M1224 1598 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M404 1578 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1550 1560 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1551 1504 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />

            <path d="M1820 1496 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z" />

            <path d="M284 1468 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M270 1440 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1494 1418 l-29 -33 33 29 c30 28 37 36 29 36 -2 0 -16 -15 -33 -32z" />

            <path d="M530 1353 l-55 -58 60 55 c33 30 62 56 64 58 2 1 0 2 -5 2 -5 0 -34

-26 -64 -57z" />

            <path d="M625 1375 c0 -13 3 -21 8 -18 4 2 7 10 7 18 0 8 -3 16 -7 18 -5 3 -8

-5 -8 -18z" />

            <path d="M1510 1350 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z" />

            <path d="M1844 1328 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M383 1273 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 l-30 0 18 -5z" />

            <path d="M1346 1183 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />

            <path d="M1803 1163 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1853 1163 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1764 1148 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1883 1153 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1945 1110 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M364 1098 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1960 1070 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M360 1000 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M400 956 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z" />

            <path d="M443 923 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M523 923 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1463 913 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1454 838 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1465 700 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M1497 669 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M1533 653 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1593 653 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1113 493 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1084 478 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M1045 439 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />

            <path d="M1030 390 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1030 360 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1040 320 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1085 270 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M1123 253 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#bbbbbb" stroke="none">

            <path d="M1138 1783 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />

            <path d="M594 1768 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M755 1530 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M794 1528 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1595 1428 l-70 -73 73 70 c67 64 77 75 69 75 -1 0 -34 -33 -72 -72z" />

            <path d="M1524 1448 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M615 1400 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M474 1298 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M418 1273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z" />

            <path d="M1175 1013 l-170 -166 140 131 c139 129 213 202 204 202 -2 -1 -80

-76 -174 -167z" />

            <path d="M1145 1074 l-60 -56 63 52 c54 45 69 61 60 60 -2 0 -30 -26 -63 -56z" />

            <path d="M360 1090 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M728 1093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z" />

            <path d="M1633 1033 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M899 833 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z" />

            <path d="M990 810 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1442 780 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />

            <path d="M788 783 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />

            <path d="M990 740 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1138 653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#d2d2d2" stroke="none">

            <path d="M693 1783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1073 1783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M745 1760 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M905 1638 l-110 -113 113 110 c104 102 117 115 109 115 -1 0 -52 -51

-112 -112z" />

            <path d="M776 1717 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z" />

            <path d="M764 1588 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M504 1240 c-66 -66 -118 -120 -115 -120 4 0 60 54 126 120 66 66 118

120 115 120 -3 0 -59 -54 -126 -120z" />

            <path d="M295 1310 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M353 1273 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M350 1040 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M874 808 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M737 799 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M773 783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M813 783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1010 696 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z" />

            <path d="M1180 670 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z" />

            <path d="M1073 653 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#c3c3c3" stroke="none">

            <path d="M755 1750 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M1403 1653 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1453 1653 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1860 1440 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1524 1358 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1493 1343 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1350 1200 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1314 1148 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1284 1118 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1254 1088 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1601 1064 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />

            <path d="M1623 1033 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1960 1010 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1781 813 l-146 -147 150 144 c83 80 152 146 154 147 2 2 1 3 -4 3

-4 0 -73 -66 -154 -147z" />

            <path d="M939 873 l-24 -28 28 24 c15 14 27 26 27 28 0 8 -8 1 -31 -24z" />

            <path d="M1440 750 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1255 460 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23

20 -3 0 3 -9 13 -20z" />

            <path d="M1259 293 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z" />

            <path d="M1113 253 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1193 253 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#b4b4b4" stroke="none">

            <path d="M633 1783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M780 1620 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M754 1578 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M1815 1500 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

            <path d="M1860 1360 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M363 1273 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1250 1173 l-55 -58 58 55 c53 50 62 60 54 60 -1 0 -27 -26 -57 -57z" />

            <path d="M1897 1149 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z" />

            <path d="M1960 1080 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1143 1073 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1114 1048 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1025 958 l-70 -73 73 70 c67 64 77 75 69 75 -1 0 -34 -33 -72 -72z" />

            <path d="M694 978 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

            <path d="M1280 420 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1030 400 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1030 350 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1280 330 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

            <path d="M1045 310 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0

-2 -4 1 -10z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#cdcdcd" stroke="none">

            <path d="M1738 1523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z" />

            <path d="M1783 1513 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1934 958 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1864 898 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1834 868 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1804 838 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1774 808 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1744 778 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />

            <path d="M1244 278 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#d8d8d8" stroke="none">

            <path d="M1685 1510 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />

            <path d="M1773 1153 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />

            <path d="M1290 380 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />

        </g>

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#a6a6a6" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#00ffff" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#ff00ff" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" />

    </svg>

)