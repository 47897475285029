import React, {Component} from "react";
import {connect} from 'react-redux';
import {
    Box,
    Grid,
    Button
} from "@mui/material";
import Draw, { createBox } from 'ol/interaction/Draw.js';
import { Snap, Modify } from 'ol/interaction.js';
import {Overlay} from "ol";
import {Style} from "ol/style";
import { rectangleDrawingKey } from "../drawer/draw_tool_keys";
import { useCropToBlendBackground } from "../../../action/maps.state.action";
import blend_app_wlkthru_img from "../../../asset/img/bg_blend_walkthrough.jpg";

class BackgroundBlendAppView extends Component {
    constructor(props) {
        super(props);

        // openlayer objects for drawing
        this.modify = null;
        this.draw = null;
        this.snap = null;
    }

    initState = () => {
        this.gammaState = this.props.gammaState;
        this.activeMapId = this.props.activeMapId;
        this.slideState = this.props.mapsState[this.activeMapId].slideState;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.vector = (this.mapState.annotationState || {}).vector;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
        // component is unmounting
        this.stopDrawing();
    }

    enableRectDrawForBlending = () => {
        this.modify = new Modify({
            source: this.vector.getSource(),
            snapToPointer: true,
            pixelTolerance : 20,
        });
        this.slideState.slidemap.addInteraction(this.modify)
        this.modify.setActive(true);

        this.draw = new Draw({
            source: this.vector.getSource(),
            type: rectangleDrawingKey.shape,
            geometryFunction: createBox(),
        });
        this.slideState.slidemap.addInteraction(this.draw);

        this.snap = new Snap({
            source: this.vector.getSource(),
            pixelTolerance : 20,
            snapToEdges: true, 
            snapToVertices: true
        });
        this.slideState.slidemap.addInteraction(this.snap);

        this.draw.on("drawstart", this.onDrawStart, this);
        this.draw.on("drawend", this.onDrawEnd, this);
        document.addEventListener('contextmenu', () => {
            this.slideState.slidemap.getOverlays().clear();
        }, false);
    }

    onDrawStart = (e) => {
        this.overlay = new Overlay({
            element: document.getElementById('popup'),
            offset: [0, 5],
            positioning: 'bottom-center',
            className : 'ol-tooltip-measure ol-tooltip .ol-tooltip-static',
        })
        this.totalAreaDistanceOverlay = this.slideState.slidemap.addOverlay(this.overlay);
    }

    onDrawEnd = (e) => {
        let feature = e.feature;
        this.stopDrawing();

        feature.setStyle(new Style({})); // hide for screenshot
        this.getRectCropAndScheduleBlending(feature);
    }

    getRectCropAndScheduleBlending = (feature) => {
        let geometry = feature.getGeometry();

        const map = this.slideState.slidemap;

        let _this = this;
    
        map.once('rendercomplete', function() {
            console.debug("rendercomplete");

            const size = map.getSize();

            let minI = size[0] - 1;
            let maxI = 0;
            let minJ = size[1] - 1;
            let maxJ = 0;

            if (geometry.intersectsExtent(map.getView().calculateExtent(size))) {
                for (var i=0; i<size[0]; i++) {
                    for (var j=0; j<size[1]; j++) {
                        var coordinate = map.getCoordinateFromPixel([i, j]);
                        if (geometry.intersectsCoordinate(coordinate)) {
                            if (i < minI) {minI = i;}
                            if (i > maxI) {maxI = i;}
                            if (j < minJ) {minJ = j;}
                            if (j > maxJ) {maxJ = j;}
                        }
                    }
                }
            }

            const mapCanvas = document.createElement('canvas');
            const canvas = document.querySelector('#viewer canvas.ol-unselectable');
            mapCanvas.width = maxI - minI;
            mapCanvas.height = maxJ - minJ;
            const mapContext = mapCanvas.getContext('2d');
            const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
                // Get the transform parameters from the style's transform matrix
                matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
            } else {
                matrix = [
                parseFloat(canvas.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.height) / canvas.height,
                0,
                0,
                ];
            }
            console.debug("matrix", matrix);

            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
                mapContext.fillStyle = backgroundColor;
                mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            
            // draw selected region to our canvas
            mapContext.drawImage(canvas, minI, minJ, maxI - minI, maxJ - minJ, 0, 0, maxI - minI, maxJ - minJ);
            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);
            const cropDataURL = mapCanvas.toDataURL();

            _this.scheduleBlending(cropDataURL);

            // feature.setStyle(null); // show again
        })
    }

    scheduleBlending = (cropDataURL) => {
        this.props.dispatch(useCropToBlendBackground(this.activeMapId, this.slideState.slide_data.id, cropDataURL));
    }

    stopDrawing = () => {
        this.slideState.slidemap.removeInteraction(this.draw);
        this.slideState.slidemap.removeInteraction(this.modify);
        // this.slideState.slidemap.removeInteraction(this.snap);
        this.slideState.slidemap.getOverlays().clear();
    }

    render() {
        this.initState();
        
        return <Grid>
            <Box sx={{ width: '100%', marginBottom:'2vh'}}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Grid style={{color: "white", fontWeight : 700}}>Usage Guide</Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>1. Zoom to Max Level</Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>2. Use the button below to draw a rectangle enclosing the desired background (as shown)</Grid>
                        <Grid item sx={{width: '100%', height: 'auto', marginTop:'1vh', marginBottom:'1vh'}}>
                            <img src={blend_app_wlkthru_img} style={{width: "100%", height: "auto"}}></img>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>3. Wait for 5-15 minutes and open the Scan again</Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>4. Once blended the slide's tag will update to <span style={{color: "white", fontWeight : 500}}>#done-blending</span></Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid sx={{height:'3vh', textAlign:'center', marginBottom:'2vh'}}>
                <Button variant={"contained"} color={"secondary"} onClick={this.enableRectDrawForBlending}>Draw Rectangle</Button>
                <a id="image-download" download="map.png"></a>
            </Grid>
        </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(BackgroundBlendAppView)
