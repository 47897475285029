import React, { Component } from 'react';
import cookie from "react-cookies";
import axios from 'axios';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import { watchPollStatus } from "../action/device_status.action";
import { sagaMiddleware } from "../helper/store";
import { AuthHeader } from '../helper/auth.token';
import { closeDrawer } from "../action/dashboard.action";
import { ExclamationOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Button, message } from 'antd';
import { globalUrlPrefix } from '../utils/const';
import ManualModeApps from '../component/manualmode/apps_pane';
import { loadAdminSettings, ongoingReloadSettingsAction, closeAllAdminApps, arrangeAppsLiveMode, updateCameraConfig } from "../action/admin.state.action";
import { updateLiveImagePolling } from "../action/liveview.action";
import { getAllApps } from '../component/manualmode/app_maker';
import CommonlyUsedActionsManualMode from '../component/manualmode/commonlyUsedActionsManualMode';

import "../asset/style/manualmode/manualmode.css";


export class ManualMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            appsPanelOpen: cookie.loadAll().superuser === 'true' ? true : false,
            deviceId: this.props.match.params.id,
        }

        this.props.dispatch(closeDrawer());

        this.props.dispatch(loadAdminSettings());

    }

    componentDidMount = () => {
        sagaMiddleware.run(watchPollStatus, this.props.dispatch, this.props.match.params.id);
        this.getLiveImagePollingStatus();
        this.getCameraConfig();
    }

    getCameraConfig = () => {
        let partsOfUrl = "api~camera~get_objective_config";
        let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl + `?objective_type=` + this.props.app_state.objectiveType;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateCameraConfig(response.data.rectWidth, response.data.rectHeight, response.data.uperPixel));
                }
                else {
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    goToDashboard = (e) => {
        window.location.href = "/" + globalUrlPrefix + "/dashboard/";
    }

    toggleAppsPanel = (e) => {
        this.setState({
            appsPanelOpen: !this.state.appsPanelOpen
        });
    }

    getLiveImagePollingStatus = () => {
        let partsOfUrl = "api~camera~get_live_image_polling_status";
        let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateLiveImagePolling(response.data));
                }
                else {
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    reloadSettings = (e) => {
        this.props.dispatch(ongoingReloadSettingsAction(this.props.app_state, true));
        let partsOfUrl = "api~settings~reload";
        let url = `/server/scano/` + this.state.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    message.success("Successfully reloaded settings.", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Failed to reload settings!!", 2.5);
                }
                this.props.dispatch(ongoingReloadSettingsAction(this.props.app_state, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to reload settings!!", 2.5);
                this.props.dispatch(ongoingReloadSettingsAction(this.props.app_state, false));
            })
    }

    closeAllApps = () => {
        this.props.dispatch(closeAllAdminApps());
    }

    arrangeAppsInLiveMode = () => {
        this.setState({
            appsPanelOpen: false,
        });
        this.props.dispatch(arrangeAppsLiveMode());
    }

    render() {

        let apps = [];

        if(this.props.app_state.apps_initialised) {
            apps = getAllApps(this.state, this.props.app_state, false);
        }
        
        return (
            <div className="manual-mode-container">
                <Spin spinning={this.props.app_state.reload_settings_action || (cookie.loadAll().superuser !== 'true' && this.props.app_state.live_mode_action)} tip={this.props.app_state.loadingMessage}>
                    <Row style={{position: 'relative'}}>
                        {(((this.props.device || {}).scanner_health || {}).scanner_response || {}).qcSettingsChanged ?
                            <div style={{position: 'absolute', top: '1%', left: '1%', zIndex: 1000}}>
                                <Link to={'/' + globalUrlPrefix + '/scanners/' + this.props.match.params.id}>
                                    <Button type='danger'>
                                        <ExclamationOutlined style={{color:'white'}} /> QC Settings have changed. Please restart the scanner.
                                    </Button>
                                </Link>
                            </div> : null
                        }
                        <Col span={this.state.appsPanelOpen ? 20 : 24}>
                            <Row className="manual-mode-container">
                                <br/>
                                {this.state.appsPanelOpen ?
                                    null :
                                    <Button onClick={this.toggleAppsPanel} 
                                        className="border-button" 
                                        style={{padding: '5px', float: 'right', zIndex: 200}}
                                    > 
                                        <LeftOutlined />
                                    </Button>
                                }
                            </Row>
                        </Col>
                        {this.state.appsPanelOpen ?
                            <Col span={this.state.appsPanelOpen ? 4 : 1} className="border" style={{zIndex: 200, background: 'white'}}>
                                <div className="manual-mode-container">
                                    <br/>
                                    <Row>
                                        <Col span={1} style={{left: -26}}>
                                            <Button onClick={this.toggleAppsPanel} 
                                                className="border-button" 
                                                style={{padding: '5px'}}
                                            > 
                                                <RightOutlined />
                                            </Button>
                                        </Col>
                                        {this.state.appsPanelOpen ?
                                            <Col offset={7}>
                                                <Button onClick={this.goToDashboard} type="primary" size="small"> Go to Dashboard </Button>
                                            </Col> :
                                            null
                                        }
                                    </Row>
                                    {this.state.appsPanelOpen ?
                                        <div>
                                            <Row style={{marginTop: 5}}>
                                                {cookie.loadAll().superuser === 'true' ?
                                                    <Col offset={1} span={12}>
                                                        <Button onClick={this.reloadSettings} type="primary" size="small" style={{marginLeft: 4}}> Reload Settings </Button>
                                                    </Col> : 
                                                    <Col offset={1} span={12}>
                                                        <Button onClick={this.arrangeAppsInLiveMode} type="primary" size="small" style={{marginLeft: 4}}> Live Mode </Button>
                                                    </Col>
                                                }
                                                <Col span={11}>
                                                    <Button onClick={this.closeAllApps} type="primary" size="small" style={{marginLeft: 4}}> Close All Apps </Button>
                                                </Col>
                                            </Row>
                                            <CommonlyUsedActionsManualMode deviceId={this.state.deviceId} />
                                            <br/>
                                            <Row>
                                                <ManualModeApps />
                                            </Row>
                                        </div> : null
                                    }
                                </div>
                            </Col> :
                            null
                        }
                    </Row>
                    {apps}   
                </Spin> 
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.adminUrlReducer,
        device: state.deviceStatusReducer[ownProps.match.params.id]
    };
};

export default connect(mapStateToProps)(ManualMode);
