import React, {Component} from "react";
import {connect} from 'react-redux';
import AnnotationsListGeneric from "./annotations_list_generic";
import {
    Button,
    CircularProgress,
    FormControl,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {getAnnotationAppLayer, getDeepBioAppDrawerLayer} from "../utils/layers_info";
import RefreshIcon from '@mui/icons-material/Refresh';
import {runAIOnSelectedAnnotations, runAIOnWholeSlide} from "../utils/deepBioAppUtils";
import {getAnnotationFromPoints, getFeatureFromAnnotation} from "../utils/annotations_app_utils";
import {polygonDrawingKey} from "../drawer/draw_tool_keys";
import VectorSource from "ol/source/Vector";
import axios from "axios";
import {message, Progress} from "antd";
import {Fill, Stroke, Style, Text as OlText} from "ol/style.js";
import {AllAnnotationsConstants} from "../../../consts/anno.const";
import {Tooltip} from "@mui/material";
import {DeepBioConstants} from "../consts/deepBioConstants";
import CircleIcon from "@mui/icons-material/Circle";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {updateActiveFilterColor} from "../../../action/deepbio.action";
import {updateActiveAnnoDrawer} from "../../../action/gamma.state.action";
import {retrieveDeepBioAnnotations} from "../../../action/maps.state.action";
import AnnotationsDownload from "./annotationsDownload";
let wholeSlideAIStates = {
    "NOT_RUN": 0,
    "RUNNING": 1,
    "DONE": 2,
}

class DeepBioApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            selectedAnnoMap: "",
            slide_uploaded: true,
            whole_slide_ai_run: 2,
            running_status_whole_slide: "",
            deep_bio_results_visible: [],
            unlock: false,
            status: "",
            upload_not_started: false,
            whole_slide_ai_obj: null,
            uploaded: false,
            upload_status: "",
            whole_slide_ai_status:"NOT_RUN",
            whole_slide_ai_result:"",
            whole_slide_ai_progress:0,
            showing_whole_slide_results: false,
            selected_annos: [],
            annos_in_queue:{},
        }
        this.timer = null;
    }

    initState = () => {
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
        this.deepBioState = this.mapState.deepBioState;
    }

    getSlideUploadStatus = () => {
        console.log("slide_status", this.slideState.slide_data.id)
        this.setState({
            fetching: true,
        })
        let url = `/api/get_deep_bio_upload_stage/?id=${this.slideState.slide_data.id}`
        axios.get(url).then(res => {
            let uploaded = res.data['uploaded']
            console.log("res-upload", res.data)
            if(uploaded != 1){
                this.setState({
                    uploaded: uploaded,
                })
            }else{
                this.setState({
                    uploaded: uploaded,
                    upload_status: res.data['status'],
                })
            }
        })
    }

    updateSelectedAnnos = (annos) => {
        this.setState({
            selected_annos: annos,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    getWholeSlideAIStatus = () => {
        let url = `/api/get_deep_bio_whole_slide_run_status/?id=${this.slideState.slide_data.id}`
        axios.get(url).then(res=>{
            let result = res.data['result']
            let status = res.data['status']
            let progress = res.data['progress']
            console.log("response*",res.data['result'])
            this.setState({
                whole_slide_ai_status:status,
                whole_slide_ai_result:result,
                whole_slide_ai_progress:progress,
            })
        }).catch(err=>{
            console.log("error--", err)
        })
    }

    componentDidMount() {
        // here we get the status of the slide i.e in what state the slide currently is
        // assume its uploaded
        // in our backend api before returning whether slide is already present or not we will check time too and return accordingly no uploading afterwards

        this.getSlideUploadStatus();
        this.getWholeSlideAIStatus();
        //change anno drawer on mounting only
        let deepBioAppLayer = getDeepBioAppDrawerLayer(this.props.gammaState);
        let deepBioAppDrawer = deepBioAppLayer.anno_drawer;
        this.props.dispatch(updateActiveAnnoDrawer(deepBioAppDrawer))
        // this.timer = setInterval(this.refreshData, 10000);
    }

    refreshData = () => {
        this.getSlideUploadStatus();
        // this.props.dispatch(doRefreshDeepBioAppDataFromBackend(this.slideState.slide_data.id, 1)) //TODO: Spandan&Utsav: annotation addtn breaks on polling!!
        this.getWholeSlideAIStatus();
    }

    componentWillUnmount() {
        let annoAppLayer = getAnnotationAppLayer(this.props.gammaState);
        let annoAppDrawer = annoAppLayer.anno_drawer;
        this.props.dispatch(updateActiveAnnoDrawer(annoAppDrawer))
    }

    switchDeepBioVisibilty = (annotation) => {
        console.log("newAnnoFeature1")
        let listannos=this.state.deep_bio_results_visible;
        if(listannos.indexOf(annotation.id)==-1){
            let deep_bio_result = annotation.deep_bio_result
            let annos = []
            let contours = deep_bio_result['heatmap']['contours'];
            console.log("newAnnoFeature2", contours )
            for(let i=0; i<contours.length; i++){

                let color = contours[i]['color'];
                let bounds = contours[i]['contour'];
                let new_anno = getAnnotationFromPoints(
                    color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
                    this.slideState.slide_data.id
                )
                let new_anno_feature = getFeatureFromAnnotation(new_anno);
                let features = this.deepBioState.resultVector.getSource().getFeatures();
                features.push(new_anno_feature)
                this.deepBioState.resultVector.setSource(
                    new VectorSource({
                        features: features,
                        wrapX: false
                    })
                )
            }

        }else{

        }
        this.setState({deep_bio_results_visible:listannos})
    }

    baseStyle = new Style({
            stroke: new Stroke({
            color: 'rgba(255, 0, 0, 0.0)',
            width: 2
        }),
        fill: new Fill({
            color: 'rgba(255, 0, 0, 0.0)'
        })
    });

    baseText = new OlText({
            font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
            placement: AllAnnotationsConstants.LINE,
            textBaseline: 'top',
            fill: new Fill({
                color: "#00FF00"
            }),
            backgroundFill: new Fill({
                    color: "#ffffff"
            }),
            backgroundStroke: new Fill({
                    color: "#ffffff"
            })
})

    selectedStyle = new Style({
            stroke: new Stroke({
            color: "#00b3ff",
            width: 8
        })
    })

    otherStyle = new Style({
            stroke: new Stroke({
            color: "white",
            width: 0
        })
    })

    hexToRGBA = (hex, opacity=0.2) => {
        return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
    }

    styleFunctionDeepBio = (feature, resolution) => {
        let baseStyleWithText = this.baseStyle;
        let baseText = this.baseText;
        this.baseText.setText(feature.get('title'));
        if(feature.getProperties().color != undefined && feature.getProperties().color != "") {
            baseStyleWithText.getStroke().setColor(feature.getProperties().color);
            baseText.getFill().setColor(feature.getProperties().color);
            baseStyleWithText.getFill().setColor(this.hexToRGBA(feature.getProperties().color))
            // baseStyleWithText.getFill().getColor().setOpacity(0.2)
        }
        baseStyleWithText.setText(baseText);
        if(feature.getProperties().selected) {
            return [this.selectedStyle, baseStyleWithText]
        }else{
            return [this.otherStyle, baseStyleWithText]
        }
    }

    showContoursFromDeepBioData = (vector, contours) => {
        let result_vector = vector;
        let features = [];
        for(let i=0;i<contours.length;i++){
            let color = contours[i]['color'];
            let bounds = contours[i]['contour'];
            let new_anno = getAnnotationFromPoints(
                color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
                this.slideState.slide_data.id
            )
            new_anno.title = contours[i]['label']
            let new_anno_feature = getFeatureFromAnnotation(new_anno);
            features.push(new_anno_feature);
        }
        vector.setSource(
            new VectorSource({
                features: features,
                wrapX: false,
            })
        )
        vector.setStyle(this.styleFunctionDeepBio)
    }

    displayWholeSlideAIResults = () => {
        let results = this.state.whole_slide_ai_result;
        let heatmap = results['heatmap']
        let contours = results['heatmap']['contours']
        this.showContoursFromDeepBioData(this.deepBioState.resultVector, contours);
        this.setState({
            showing_whole_slide_results: true,
        })
    }

    removeWholeSlideAIResults = () => {
        let vector = this.deepBioState.resultVector;
        vector.setSource(
            new VectorSource({
                features: [],
                wrapX: false,
            })
        )
        this.setState({
            showing_whole_slide_results: false,
        })
    }

    removeAnnoFromQueue = (annos) => {
        console.log("iamrunning", this.state.annos_in_queue, annos);
        let annos_in_queue = this.state.annos_in_queue;
        for(let i=0;i<annos.length;i++){
            let anno=annos[i];
            annos_in_queue[anno] = false;
        }
        this.setState({
            annos_in_queue: annos_in_queue,
            selected_annos:[],
        })
        let deepBioAppLayer = getDeepBioAppDrawerLayer(this.props.gammaState);
        let deepBioAppDrawer = deepBioAppLayer.anno_drawer;
        this.props.dispatch(retrieveDeepBioAnnotations(this.activeMapId, this.slideState.slide_data.id,
            deepBioAppDrawer))
    }

    onRunAIOnSelectedAnnos = (e, selected_anno_ids, reset) => {
        if(this.state.uploaded != 2){
            message.info("Please Upload Slide First")
            return;
        }
        let selected_annos = selected_anno_ids;
        let annos_in_queue = this.state.annos_in_queue;
        for(let i=0;i<selected_anno_ids;i++){
            annos_in_queue[selected_anno_ids[i]]=true;
        }
        this.setState({
            annos_in_queue: annos_in_queue,
        })
        console.log("Run AI on SELECTED ANNOS", selected_annos)
        let slide = this.slideState.slide_data;
        // runAIOnSelectedAnnotations(slide.morphle_id, slide.id, selected_annos, this.removeAnnoFromQueue);
        reset();
    }

    getMultipleSelectComponent = (selectedId, reset) => {
        let slideId = this.slideState.slide_data.id;
        let selected_anno_ids = []
        console.log("slide-x",selected_anno_ids, selectedId)
        for (const [key, value] of Object.entries(selectedId)) {
            if(value === true){
                selected_anno_ids.push(key)
            }
        }
        console.log("slide-x-after",selected_anno_ids)
        return <Grid sx={{height:'3vh'}}>
                    <Tooltip title={"Run AI on Selected"} placement={"right"}>
                    <IconButton onClick={(e)=>(this.onRunAIOnSelectedAnnos(e, selected_anno_ids, reset))} color={"secondary"}><PlayArrowIcon/></IconButton>
                    </Tooltip>
                </Grid>
    }



    getDefaultTopComponent = () => {
        let slideId = this.slideState.slide_data.id;
        let wholeSlide;
        // if(this.state.whole_slide_ai_status == "NOT_RUN"){
        //     wholeSlide = <Button variant={"contained"} color={"secondary"} onClick={(e)=>runAIOnWholeSlide(this.slideState.slide_data.morphle_id, slideId, null)}>
        //         Run AI on Whole Slide
        //     </Button>
        // }else if(this.state.whole_slide_ai_status == "RUNNING" ){
        //     wholeSlide = <Button variant={"outlined"} color={"secondary"}>
        //         Running AI on Whole Slide
        //     </Button>
        // }else if(this.state.whole_slide_ai_status == "DONE"){
        //     wholeSlide = <Button variant={"contained"} sx={{width:'98%', backgroundColor:"rgba(108,122,137,1)!important", color:'rgba(255,255,255,1) !important'}} disabled> Show Whole Slide AI Results</Button>
        // }
        //
        // return <Grid sx={{height:'3vh', textAlign:'center', marginBottom:'2vh'}}>
        //             {wholeSlide}
        //         </Grid>

        if (this.state.whole_slide_ai_status === "NOT_RUN"){
            wholeSlide = <Button variant={"contained"} color={"secondary"} onClick={(e)=>runAIOnWholeSlide(this.slideState.slide_data.morphle_id, slideId, null)}>
                Run AI on Whole Slide
            </Button>
        }
        else if (this.state.whole_slide_ai_status === "FINISHED"){
            if(!this.state.showing_whole_slide_results){
                wholeSlide = <Button variant={"contained"} color={"secondary"} onClick={(e)=>this.displayWholeSlideAIResults()}>
                               Show whole Slide AI Results
                            </Button>
            }else{
                wholeSlide = <Button variant={"contained"} color={"secondary"} onClick={(e)=>this.removeWholeSlideAIResults()}>
                               Hide Whole Slide AI Results
                            </Button>
            }

        }
        else{
            wholeSlide =
                <Tooltip sx={{zIndex:'100000000', color:'white'}} title={this.state.whole_slide_ai_status} placement="top">
                    <Button variant={"contained"} color={"secondary"} sx={{width:'98%', backgroundColor:"rgba(108,122,137,1)!important", color:'rgba(255,255,255,1) !important'}} disabled>
                        <Progress type="circle" percent={this.state.whole_slide_ai_progress} width={20}/> RUNNING AI ON WHOLE SLIDE
                    </Button>
                </Tooltip>

        }
        return <Grid sx={{height:'3vh', textAlign:'center', marginBottom:'2vh'}}>
            {wholeSlide}
        </Grid>
    }

    uploadSlide = () => {
        let slideId = this.slideState.slide_data.id;
        let url = `/api/deepbio_upload/?id=${slideId}`
        axios.get(url).then(res => {
            message.info("Slide Upload Started")
            this.getSlideUploadStatus();
        })
    }

    uploadSlideComponent = () => {
        let slideId = this.slideState.slide_data.id;
        let component;
        console.log("uploaded", this.state.uploaded)
        if(this.state.uploaded == 0){
            component = <Button variant={"contained"} color={"secondary"} onClick={this.uploadSlide}> UPLOAD SLIDE</Button>
        }else if(this.state.uploaded == 1){
            component = <Button sx={{width:'98%', backgroundColor:"rgba(108,122,137,1)!important", color:'rgba(255,255,255,1) !important'}} disabled>
                                     {this.state.upload_status} <CircularProgress size={10} sx={{marginLeft:'10px'}}/>
                        </Button>
        }else if(this.state.uploaded == 2){
            // component = this.getDefaultTopComponent();
            return <></>
        }

        return <Grid  sx={{height:'3vh', textAlign:'center', marginBottom:'2vh'}}>
                    {component}
                </Grid>
    }

    onRefreshApp = () => {
        this.getSlideUploadStatus();
        this.props.dispatch(retrieveDeepBioAnnotations(this.activeMapId, this.slideState.slide_data.id, 1))
        this.getWholeSlideAIStatus();
    }

    changeDeepBioActiveFilter = (e) => {
        let new_color = e.target.value;
        this.props.dispatch(updateActiveFilterColor(new_color))
    }

    getDeepBioTopComponent = () => {
        let labels = DeepBioConstants.LABELS_LIST;
        let label_colors = DeepBioConstants.LABEL_COLORS;
        let menuItems = [];
        menuItems.push(
            <MenuItem value={"ALL"}>
                <Typography>All Patterns</Typography>
            </MenuItem>
        )
        label_colors.map((color, index) =>  {
            menuItems.push(
                <MenuItem value={labels[index].toLowerCase()}>
                    <CircleIcon fontSize="small" sx={{color:color.toLowerCase()}}/> {labels[index]}
                </MenuItem>
            )
            return color;
        })
        return <FormControl  color="secondary" size="small" variant="filled" sx={{height:'3vh'}}>
            <FormGroup row={true} sx={{paddingTop:'0.2vh', marginLeft:'auto', marginRight:'auto', gap: 12}}>
                <Select value = {this.props.deepBioData.active_filter}
                        onChange={(e)=> {this.changeDeepBioActiveFilter(e)}}>
                    {menuItems}
                </Select>
                {}
                <AnnotationsDownload slideId={this.slideState.slide_data.id} annoDrawer={1} />
            </FormGroup>
        </FormControl>
    }


    render() {
        this.initState();
        if (!this.deepBioState) return <div />;

        let annotations = this.deepBioState.annotations;
        let headbarComponent; // whther to give ai_axns or slide upload axn

        let refreshComponent = <Grid sx={{height:'3vh', display:'flex', flexDirection:'row-reverse'}}>
                                    <IconButton onClick={this.onRefreshApp} color={"secondary"}><RefreshIcon/></IconButton>
                                </Grid>

        let uploadComponent = this.uploadSlideComponent();

        let showTopBar = this.state.slide_uploaded;

        let wholeSlideAi = {
            "status": this.state.whole_slide_ai_status,
            "progress": this.state.whole_slide_ai_progress,
            "result": this.state.whole_slide_ai_result,
        }

        let labels = DeepBioConstants.LABELS_LIST;

        let annotationsComponent = <AnnotationsListGeneric
            data = {annotations || []}
            getExpandedComponent={undefined}
            getMultipleSelectComponent = {this.getMultipleSelectComponent}
            vector = {this.deepBioState.vector}
            selectedAnnoMap = {this.state.selectedAnnoMap}
            getDefaultTopComponent = {this.getDeepBioTopComponent}
            showTopBar = {showTopBar}
            app = {"DeepBio"}
            uploadSlideComponent = {this.uploadSlideComponent}
            swicthDeepBioVisibility={this.switchDeepBioVisibilty}
            wholeSlideAi={wholeSlideAi}
            uploaded={this.state.uploaded}
            getWholeSlideAIStatus={this.getWholeSlideAIStatus}
        />

        return <Grid>
            {refreshComponent}
            <Grid>
                {uploadComponent}
            </Grid>
            <Grid>
                {annotationsComponent}
            </Grid>
        </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
        triggerData: state.triggerReducer,
        deepBioData: state.deepBioReducer,
    }
}

export default connect(mapStateToProps)(DeepBioApp);
