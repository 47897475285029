import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import {Grid, Tooltip} from "@mui/material";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{overflowY:'overlay'}}
            {...other}
        >
            {value === index && (
                <Box sx={{ p:1.5}}>
                    {/*<Typography>*/}
                        {children}
                    {/*</Typography>*/}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

export const getTabComponent = (id, app, handleChange) => {
    let tab_comp = <Tab icon={app.icon}  value={id} {...a11yProps(id)} onClick={(e)=>handleChange(e, id)}
                        sx={{fontSize:'3vh'}} />
    if(app.showTitleOnHover)
        return <Tooltip title={app.title} placement={"right"} value={id}>
            {tab_comp}
        </Tooltip>;
    else
        return tab_comp;
}

export const getTabPanelComponent = (id, app, currentTab) => {
    return (<TabPanel index={id} value={currentTab}>
        {/*<Scrollbars autoHeight autoHeightMin={'65.5vh'} autoHide style={{marginLeft:'0.7vw', msScrollbarBaseColor:'yellow'}}>*/}
        <Grid sx={{width:'15vw'}} >
            {app.component}
        </Grid>
        {/*</Scrollbars>*/}
    </TabPanel>)
}
