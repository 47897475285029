import {onSelectAnnotationOnMap} from "./annotations_app_utils";
import {
    retrieveAnnotations,
    retrieveDeepBioAnnotations,
    updateAnnotationVector, updateDeepBioResultVector,
    updateDeepBioVector
} from "../../../action/maps.state.action";

export const getAnnotationAppLayer = (gammaState) => {
    return {
        name: "Annotations Layer",
        map_name: "annotation_layer",
        anno_drawer: 0,
        permission: gammaState.appsInstalled["annotations_installed"],
        store: updateAnnotationVector,
        loadDataOnRender: true,
        dataLoad: (mapId, slideId) => retrieveAnnotations(mapId, slideId, 0),
        onSelectAnnotationOnMap: onSelectAnnotationOnMap,
        onSelectAnnotationOnMapArgs: [1,], // annotation and dispatch will be sent automatically add other reqd args like appId
        selectInteraction: true,
    }
}

export const getDeepBioAppDrawerLayer = (gammaState) => {
    return {
        name: "DeepBio Layer",
        map_name: "deepbio_layer",
        anno_drawer: 1,
        permission: gammaState.appsInstalled["deep_bio_installed"],
        store: updateDeepBioVector,
        loadDataOnRender: true,
        dataLoad: (mapId, slideId) => retrieveDeepBioAnnotations(mapId, slideId, 1),
        onSelectAnnotationOnMap: onSelectAnnotationOnMap,
        onSelectAnnotationOnMapArgs: [12,], // annotation and dispatch will be sent automatically add other reqd args like appId
        selectInteraction: false,
    }
}

export const getDeepBioAppResultLayer = (gammaState) => {
    return {
        name: "DeepBio Analysis",
        map_name: "deep_bio_analysis",
        anno_drawer: 2,
        store: updateDeepBioResultVector,
        permission: gammaState.appsInstalled["deep_bio_installed"],
        loadDataOnRender: false,
        dataLoad: undefined,
        dataLoadArguments: undefined,
        onSelectAnnotationOnMap: undefined,
        onSelectAnnotationOnMapArgs: [],
        selectInteraction: false,
    }
}

export const getAllLayers = (gammaState) => {
    return [
        getAnnotationAppLayer(gammaState),
        getDeepBioAppDrawerLayer(gammaState),
        getDeepBioAppResultLayer(gammaState),
    ]
}
