import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, Descriptions, Divider, Input, Button, message, Spin, List, Collapse } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { ongoingMotionAppAction } from '../../../action/admin.state.action';
import { previewTuningControlsAppKey } from '../initial_setup_app_keys';
import { updatePositionValues } from '../../../action/preview.tuning.action';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import "../../../asset/style/manualmode/motion_app.css";

const { Panel } = Collapse;

class MotionApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            virtualX: 0,
            virtualY: 0,
            realX: 0,
            realY: 0,
            ZVal: 0,
            relativeXInputValue: 1000,
            relativeYInputValue: 1000,
            relativeZInputValue: 0.1,
            absoluteVirtualXValue: 0,
            absoluteVirtualYValue: 0,
            absoluteRealXValue: 0,
            absoluteRealYValue: 0,
            absoluteZValue: 0,
            savedPositions: [],
        }

        this.getPosition();

    }

    componentDidMount = () => {
    }

    reloadPositions = (positions) => {
        this.setState({
            virtualX: positions["X"],
            virtualY: positions["Y"],
            realX: positions["XReal"],
            realY: positions["YReal"],
            ZVal: positions["Z"]
        });
    }

    addPositionToList = () => {
        var sav = [...this.state.savedPositions];
        sav.splice(9-sav.size, 0, this.state.virtualX+", "+this.state.virtualY)
        if(sav.length>9){
            sav.splice(9,1);
        }
        this.setState({
            savedPositions: sav});
    }

    move_to_position = (e) => {
        var val = e.target.innerText;
        var res = val.split(",")
        this.move(res[0], res[1], null, true, false)
    }

    getPosition = (callback = null) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~get_position";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.reloadPositions(response.data);
                    if(callback != null){
                        callback();
                    }
                }
                else {
                    console.log(response);
                    message.error("Not able to fetch current position!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to fetch current position!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    move = (x, y, z, abs, real) => {
        let partsOfUrl = "api~stage~move";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;

        if (x != null) {
            url += '?x=' + x;
        } else {
            url += '?x=';
        }

        if (y != null) {
            url += '&y=' + y;
        } else {
            url += '&y=';
        }

        if (z != null) {
            url += '&z=' + z;
        } else {
            url += '&z=';
        }

        url += '&abs=' + abs + '&real=' + real;

        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    this.reloadPositions(response.data);
                }
                else {
                    console.log(response);
                    message.error("Soft Limits Hit!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Soft Limits Hit!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    doCompensation = (e) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~compensate";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getPosition();
                }
                else {
                    console.log(response);
                    message.error("Not able to do compensation!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do compensation!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    doInverseSecondaryCompensation = (e) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~compensate_inv_secondary";
        let url = `/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getPosition();
                }
                else {
                    console.log(response);
                    message.error("Not able to do compensation!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do compensation!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    doHoming = (e) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~diagnostics~homing";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getPosition();
                }
                else {
                    console.log(response);
                    message.error("Not able to do homing!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do homing!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    ejectSlide = (e) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~scan~eject";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?background=false';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getPosition();
                }
                else {
                    console.log(response);
                    message.error("Not able to eject slide!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to eject slide!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    switchObjective = (objective) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let url = `/server/devices/` + this.props.currentState.deviceId + "/switchObjective?objective=" + objective;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            }).catch(err => {
                message.error("Objective Switching failed");
                console.log(err);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            });
    }

    savePositionValues = () => {
        this.props.dispatch(updatePositionValues([this.state.virtualX, this.state.virtualY]));
    }

    relativeXValueChange = (e) => {
        this.setState({
            relativeXInputValue: e.target.value
        })
    }

    relativeYValueChange = (e) => {
        this.setState({
            relativeYInputValue: e.target.value
        })
    }

    relativeZValueChange = (e) => {
        this.setState({
            relativeZInputValue: e.target.value
        })
    }

    absoluteVirtualXChange = (e) => {
        this.setState({
            absoluteVirtualXValue: e.target.value
        })
    }

    absoluteVirtualYChange = (e) => {
        this.setState({
            absoluteVirtualYValue: e.target.value
        })
    }

    absoluteRealXChange = (e) => {
        this.setState({
            absoluteRealXValue: e.target.value
        })
    }

    absoluteRealYChange = (e) => {
        this.setState({
            absoluteRealYValue: e.target.value
        })
    }

    absoluteZChange = (e) => {
        this.setState({
            absoluteZValue: e.target.value
        })
    }

    getRelativeMotionRow = (haveRowStyle, haveInputField, heading, inputField, button1, button2, button3, button4) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                {haveInputField ?
                    <Col offset={1} span={1}>
                        <b>{heading}</b>
                    </Col>
                    : null
                }
                {haveInputField ?
                    <Col span={6}>
                        {inputField}
                    </Col>
                    : null
                }
                <Col offset={haveInputField ? 0 : 8} span={4}>
                    {button1}
                </Col>
                <Col span={4}>
                    {button2}
                </Col>
                <Col span={4}>
                    {button3}
                </Col>
                <Col span={4}>
                    {button4}
                </Col>
            </Row>
        )
    }

    getAbsoluteMotionRow = (haveRowStyle, showSecondInputField, heading1, heading2, inputField1, inputField2, button) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={4}>
                    <b>{heading1}</b>
                </Col>
                <Col span={5}>
                    {inputField1}
                </Col>
                {showSecondInputField ?
                    <Col span={4}>
                        <b>{heading2}</b>
                    </Col>
                    : null
                }
                {showSecondInputField ?
                    <Col span={5}>
                        {inputField2}
                    </Col>
                    : null
                }
                <Col offset={showSecondInputField ? 0 : 9} span={5}>
                    {button}
                </Col>
            </Row>
        )
    }

    getMotionInputField = (inputValue, onChangeInput) => {
        return <Input size="small" value={inputValue} onChange={onChangeInput} className="motion-app-input-field" />
    }

    getMotionButton = (text, param1, param2, param3, param4, param5) => {
        return <Button size="small" type="primary" onClick={() => this.move(param1, param2, param3, param4, param5)}>
            {text}
        </Button>
    }

    render() {
            return (
                <Row className="app-parent overlayed-component motion-app-width">
                    <br />
                    <div>
                        <Spin spinning={this.props.adminState.motion_app_action}>
                            <Row>
                                <Col offset={1} span={17}>
                                    <div>
                                        <Descriptions size="small" column={10}>
                                            <Descriptions.Item label={<b>Virtual X</b>} span={3}>{this.state.virtualX}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Virtual Y</b>} span={3}>{this.state.virtualY}</Descriptions.Item>
                                                <Descriptions.Item >
                                                    <CopyToClipboard text={this.state.virtualX+", "+this.state.virtualY}
                                                        onCopy={() => message.success("Copied Virtual Positions")}
                                                    >
                                                        <CopyOutlined />
                                                    </CopyToClipboard>
                                                </Descriptions.Item>
                                            <Descriptions.Item label={<b>Z</b>} span={2}>{this.state.ZVal}</Descriptions.Item>
                                            <Descriptions.Item><CopyToClipboard text={this.state.ZVal} onCopy={() => message.success("Copied Z Position")}><CopyOutlined /></CopyToClipboard></Descriptions.Item>
                                            <Descriptions.Item label={<b>Real X</b>} span={3}>{this.state.realX}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Real Y</b>} span={3}>{this.state.realY}</Descriptions.Item>
                                                <Descriptions.Item ><CopyToClipboard text={this.state.realX+", "+this.state.realY}
                                                    onCopy={() => message.success("Copied Real Positions")}
                                                ><CopyOutlined /></CopyToClipboard></Descriptions.Item>
                                            <Descriptions.Item></Descriptions.Item>
                                            <Descriptions.Item></Descriptions.Item>
                                            <Descriptions.Item></Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                </Col>
                                <Col span={2}>
                                    <Row>
                                        <Button size="small" type="primary" onClick={() => this.getPosition(null)} 
                                            style={(this.props.appState || {}).closed ? {marginTop: 5} : {}}>
                                            Refresh Positions
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Button size="small" type="primary" onClick={() => this.getPosition(this.addPositionToList)}
                                            style={{marginTop: 5}}>
                                            Add Position to List
                                        </Button>
                                    </Row>
                                    {(this.props.appState || {}).closed ? null :
                                        <Row>
                                            <Button size="small" type="primary" onClick={this.savePositionValues} style={{marginTop: 5}}>
                                                Save Positions
                                            </Button>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            <Row>
                            <Collapse>
                                    <Panel showArrow={false} bordered={false} className="motion-app-heading" header="Saved Positions" key="1">
                            <List
                                grid={{ column: 15 }}
                                bordered
                                dataSource={this.state.savedPositions}
                                renderItem={item => <Button onClick={(e) => this.move_to_position(e)}><List.Item>{item}</List.Item></Button>}
                                />
                                </Panel>
                            </Collapse>
                            <Divider />
                            </Row>
                            <Divider />
                            <h4 className="motion-app-heading">Relative Motion</h4>
                            <Divider />
                            {this.getRelativeMotionRow(false, true, 'X: ',
                                this.getMotionInputField(this.state.relativeXInputValue, this.relativeXValueChange),
                                this.getMotionButton('VirtualX+', this.state.relativeXInputValue, null, null, false, false),
                                this.getMotionButton('VirtualX-', (this.state.relativeXInputValue) * -1, null, null, false, false),
                                this.getMotionButton('RealX+', this.state.relativeXInputValue, null, null, false, true),
                                this.getMotionButton('RealX-', (this.state.relativeXInputValue) * -1, null, null, false, true)
                            )}
                            {this.getRelativeMotionRow(true, true, 'Y: ',
                                this.getMotionInputField(this.state.relativeYInputValue, this.relativeYValueChange),
                                this.getMotionButton('VirtualY+', null, this.state.relativeYInputValue, null, false, false),
                                this.getMotionButton('VirtualY-', null, (this.state.relativeYInputValue) * -1, null, false, false),
                                this.getMotionButton('RealY+', null, this.state.relativeYInputValue, null, false, true),
                                this.getMotionButton('RealY-', null, (this.state.relativeYInputValue) * -1, null, false, true)
                            )}
                            {this.getRelativeMotionRow(true, true, 'Z: ',
                                this.getMotionInputField(this.state.relativeZInputValue, this.relativeZValueChange),
                                this.getMotionButton('Z+', null, null, this.state.relativeZInputValue, false, false),
                                this.getMotionButton('Z10+', null, null, (this.state.relativeZInputValue) * 10, false, false),
                                this.getMotionButton('Z100+', null, null, (this.state.relativeZInputValue) * 100, false, false),
                                this.getMotionButton('Z1000+', null, null, (this.state.relativeZInputValue) * 1000, false, false)
                            )}
                            {this.getRelativeMotionRow(true, false, null,
                                null,
                                this.getMotionButton('Z-', null, null, (this.state.relativeZInputValue) * -1, false, false),
                                this.getMotionButton('Z10-', null, null, (this.state.relativeZInputValue) * -10, false, false),
                                this.getMotionButton('Z100-', null, null, (this.state.relativeZInputValue) * -100, false, false),
                                this.getMotionButton('Z1000-', null, null, (this.state.relativeZInputValue) * -1000, false, false)
                            )}
                            <div style={{ marginTop: '13px' }}>
                                <Divider />
                                <h4 className="motion-app-heading">Absolute Motion</h4>
                                <Divider />
                            </div>
                            {this.getAbsoluteMotionRow(false, true, 'Virtual X: ', 'Virtual Y: ', 
                                this.getMotionInputField(this.state.absoluteVirtualXValue, this.absoluteVirtualXChange),
                                this.getMotionInputField(this.state.absoluteVirtualYValue, this.absoluteVirtualYChange),
                                this.getMotionButton('Go', this.state.absoluteVirtualXValue, this.state.absoluteVirtualYValue, null, true, false)
                            )}
                            {this.getAbsoluteMotionRow(true, true, 'Real X: ', 'Real Y: ', 
                                this.getMotionInputField(this.state.absoluteRealXValue, this.absoluteRealXChange),
                                this.getMotionInputField(this.state.absoluteRealYValue, this.absoluteRealYChange),
                                this.getMotionButton('Go', this.state.absoluteRealXValue, this.state.absoluteRealYValue, null, true, true)
                            )}
                            {this.getAbsoluteMotionRow(true, false, 'Z: ', null, 
                                this.getMotionInputField(this.state.absoluteZValue, this.absoluteZChange),
                                null,
                                this.getMotionButton('Go', null, null, this.state.absoluteZValue, true, false)
                            )}
                            <div style={{ marginTop: '13px' }}>
                                <Divider />
                                <h4 className="motion-app-heading">Objective Switching</h4>
                                <Divider />
                            </div>
                            <Row>
                                <Col span={4} offset={1}>
                                    <Button type="primary" size="small" onClick={() => this.switchObjective("FORTY_X")} >40X</Button>
                                </Col>
                                <Col span={4} offset={1}>
                                    <Button type="primary" size="small" onClick={() => this.switchObjective("TWENTY_X")}>20X</Button>
                                </Col>
                                <Col span={4} offset={1}>
                                    <Button type="primary" size="small" onClick={() => this.switchObjective("HUNDRED_X")}>100X</Button>
                                </Col>
                                <Col span={4} offset={1}>
                                    <Button type="primary" size="small" onClick={() => this.switchObjective("TEN_X")} >10X</Button>
                                </Col>
                                <Col span={3} offset={1}>
                                    <Button type="primary" size="small" onClick={() => this.switchObjective("FOUR_X")} >4X</Button>
                                </Col>
                            </Row>
                            <div style={{ marginTop: '13px' }}>
                                <Divider />
                                <h4 className="motion-app-heading">Miscellaneous</h4>
                                <Divider />
                            </div>
                            <Row>
                                <Col offset={2} span={3}>
                                    <Button size="small" type="primary" onClick={this.doCompensation}>
                                        Do Comp.
                                    </Button>
                                </Col>
                                <Col offset={2} span={5}>
                                    <Button size="small" type="primary" onClick={this.doInverseSecondaryCompensation}>
                                        Do Inv Secondary Comp
                                    </Button>
                                </Col>
                                <Col offset={2} span={3}>
                                    <Button size="small" type="primary" onClick={this.doHoming}>
                                        Do Homing
                                    </Button>
                                </Col>
                                <Col offset={1} span={5}>
                                    <Button size="small" type="primary" onClick={this.ejectSlide}>
                                        Eject
                                    </Button>
                                </Col>
                            </Row>
                        </Spin>
                    </div>
                    <br />
                </Row>
            )
        
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        appState: state.adminUrlReducer.app_state[previewTuningControlsAppKey.id]
    }
}

export default connect(mapStateToProps)(MotionApp);
