import { SlideActions } from "../actionTypes/slides.constant";
import { LoginAlertConstant } from "../actionTypes/login.alert.constant";
    
const allSlides = {
};

export const slidesReducer = (state = allSlides, action) => {
  switch (action.type) {
    case SlideActions.DELETE_SLIDE:
        var currentInfo = Object.assign({}, state[action.id]);
        currentInfo.isDeleted = true;
        return {
          ...state,
          [action.id]: currentInfo
        }
    case SlideActions.UPDATE_POLLING:
      delete action.data['keep_polling'];
      let ids = Object.keys(action.data);
      let currentState = Object.assign({}, state);
      ids.map((value) => {
        let currentValue = action.data[value];
        if ('tiling_status' in currentValue) currentState[value].tiling_status = currentValue.tiling_status;
        if ('upload_status' in currentValue) currentState[value].upload_status = currentValue.upload_status;
        if ('export_status' in currentValue) currentState[value].export_status = currentValue.export_status;
        if ('tiling_percent' in currentValue) currentState[value].tiling_percent = currentValue.tiling_percent;
        if ('upload_percent' in currentValue) currentState[value].upload_percent = currentValue.upload_percent;
        if ('export_percent' in currentValue) currentState[value].export_percent = currentValue.export_percent;
        if ('txty_time' in currentValue) currentState[value].txty_time = currentValue.txty_time;
        if ('txty_percent' in currentValue) currentState[value].txty_percent = currentValue.txty_percent;
        if ('stitcher_estimated_time' in currentValue) currentState[value].stitcher_estimated_time = currentValue.stitcher_estimated_time;
        if ('stitcher_percent' in currentValue) currentState[value].stitcher_percent = currentValue.stitcher_percent;
        if ('slide_status' in currentValue) currentState[value].status = currentValue.slide_status;
      });
      return currentState;
    case SlideActions.ALL_SLIDES_UPDATE_SUCCESS:
      var updatedInfo = {}
      for (var i = 0; i < action.response.data.results.length; i++){
        let slide = action.response.data.results[i]
        updatedInfo[slide.id] = slide;
        updatedInfo[slide.id].displayOrder = i;
      }
      return updatedInfo
    case LoginAlertConstant.LOGOUT:
      return {};
    case SlideActions.SLIDE_LIST_UPDATE_INITIATED:
        var currentInfo = Object.assign({}, state[action.id]);
        currentInfo.isFetching = true;
        return {
          ...state,
          [action.id]: currentInfo
        }
    case SlideActions.SLIDE_LIST_UPDATE:
      var currentInfo = Object.assign({}, state[action.id]);
      currentInfo = action.updatedInfo;
      currentInfo.isFetching = false;
      return {
        ...state,
        [action.id]: currentInfo
      }
    case SlideActions.SLIDE_LIST_DELETE:
        var newState = Object.assign({}, state);
        delete newState[action.id];
        return newState;
    case SlideActions.SLIDE_LIST_UPDATE_FAILED:
        var currentInfo = Object.assign({}, state[action.id]);
        currentInfo.isFetching = false;
        currentInfo.error = action.response
        return {
          ...state,
          [action.id]: currentInfo
        }
    case SlideActions.SET_SLIDE_LIST_ITEM_FETCHED:
        var currentInfo = Object.assign({}, state[action.id]);
        currentInfo.isFetching = false;
        return {
          ...state,
          [action.id]: currentInfo
        }
    case SlideActions.SLIDE_STATUS_UPDATED:
        return {
          ...state,
          [action.id]: action.status
        }
    // case SlideActions.FAILED_IN_TILING_POLLING_UPDATE:
    //     var currentInfo = Object.assign({}, state[action.id]);
    //     currentInfo.polling = false;
    //     currentInfo.error = action.err;
    //     currentInfo.tiling_status = 100;
    //     return {
    //       ...state,
    //       [action.id]: currentInfo
    //     }
    default:
      return state;
  }
};
