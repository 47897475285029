import React, { Component } from 'react';
import { Row, Col, Input, Checkbox, Divider, Button, message, Slider, InputNumber } from 'antd';
import { connect } from "react-redux";


class ImageSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFieldInFocus: false,
        }
    }

    componentDidMount = () => {
        
    }

    onFocusInputField = () => {
        this.setState({
            inputFieldInFocus: true,
        });
    }

    onBlurInputField = () => {
        this.setState({
            inputFieldInFocus: false,
        });
    }

    getCameraSliderRow = (haveRowStyle, heading, slider, buttons) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
            <Col span={5}  style={{marginTop: "15px"}}>
                <b>{heading}</b>
            </Col>
            <Col span={10} style={{marginRight: 5}}>
                {slider}
            </Col>
            <Col span={8}>
                {buttons}
            </Col>
            </Row>
        )
    }

    render() {
        return (
            <Row>
                <Col span={18}>
                    {this.getCameraSliderRow(false, 'Brightness',
                        <Row>
                            {/* <Col span={18}>
                                <Slider value={this.props.brightnessInputValue} min={1} max={100} //onAfterChange={this.setExposureValue} 
                                    onChange={this.props.brightnessValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}}  />
                            </Col> */}
                            <Col span={5} offset={1}>
                                <InputNumber min={0} max={100} style={{ margin: '10px 16px' }} 
                                    value={this.props.brightnessInputValue} onChange={this.props.brightnessValueChange}/>
                            </Col>
                        </Row>,
                    <>
                        <Row><Button type="primary" size={"small"} style={{marginRight: 5}} onClick={() => {this.props.brightnessValueChange(this.props.brightnessInputValue+(5))}}>+5</Button></Row>
                        <Row><Button type="primary" size={"small"} style={{marginRight: 5, marginTop: 5}} onClick={() => {this.props.brightnessValueChange(this.props.brightnessInputValue-(5))}}>-5</Button></Row>
                    </>
                    )}
                    {this.getCameraSliderRow(true, 'Contrast',
                    <Row>
                        {/* <Col span={18}>
                            <Slider value={this.props.contrastInputValue} min={1} max={100} //onAfterChange={this.setGammaValue} 
                                onChange={this.props.contrastValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}} 
                            />
                        </Col> */}
                        <Col span={5} offset={1}>
                        <InputNumber min={1} max={100} style={{ margin: '15px 16px' }} 
                                        value={this.props.contrastInputValue} onChange={this.props.contrastValueChange}/>
                        </Col>
                    </Row>,
                    <>
                        <Row><Button type="primary" size={"small"} style={{marginRight: 5, marginTop: 5}} onClick={() => {this.props.contrastValueChange(this.props.contrastInputValue+(5))}}>+5</Button></Row>
                        <Row><Button type="primary" size={"small"} style={{marginRight: 5, marginTop: 5}}onClick={() => {this.props.contrastValueChange(this.props.contrastInputValue-(5))}}>-5</Button></Row>
                    </>
                    )}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
		preview: state.previewStatusReducer[ownProps.id],
    };
};

export default connect(mapStateToProps)(ImageSettings);
