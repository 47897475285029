import React, {Component} from  "react";
import {connect} from "react-redux";
import {Grid, Tooltip} from "@mui/material";

class Thumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRotation: 0,
        }
    }

    rotate = () => {
        let rotation = this.state.currentRotation;
        rotation = (rotation+180)%360
        this.setState({
            currentRotation: rotation,
        })
        document.getElementById('thumbnail-img').style.transformOrigin="50% 50%"
        document.getElementById('thumbnail-img').style.transform=`rotate(${rotation}deg)`
    }

    render() {
        this.slideState = this.props.mapsState[this.props.activeMapId].slideState;
        if (!this.slideState) return <div />;
        return <Grid sx={{textAlign:'center', marginTop:'10%'}}>
            <Tooltip title={"Click to Rotate 180"}>
                <img id='thumbnail-img' src={"/scan-hdd/"+this.slideState.slide_data.scandrive_id+"/morphle_test/"+this.slideState.slide_data.path+'pre-processed/selection_marked.jpg'}
                     style={{height:'auto', width: '15vw', cursor:'pointer'}}
                     onClick={this.rotate}
                />
            </Tooltip>
        </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId : state.gammaStateReducer.activeMapId,
        mapsState : state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(Thumbnail);
