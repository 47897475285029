import {MagicToolActions} from "../actionTypes/magic_tool.constant";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import {getAnnotationFromPoints, getFeatureFromAnnotation} from "../component/gammaviewer/utils/annotations_app_utils";
import { magicToolResultDrawingKey} from "../component/gammaviewer/drawer/draw_tool_keys"

var querystring = require('querystring');

export const resetMagicTool = () => ({
    type: MagicToolActions.RESET_MAGIC_TOOL_DATA,
})

export const updateStartPoint = (value) => ({
    type: MagicToolActions.UPDATE_TOOL_START_POINT,
    response: value,
})

export const updateUnsavedMagicAnnotations = (value) => ({
    type: MagicToolActions.UPDATE_UNSAVED_MAGIC_ANNOTATIONS,
    response: value,
})

export const updateUnsavedMagicAnnotationsRaw = (value) => ({
    type: MagicToolActions.UPDATE_UNSAVED_MAGIC_ANNOTATIONS_RAW,
    response: value,
})

export const updateFetchingStatus = (value) => ({
    type: MagicToolActions.FETCHING_DATA,
    response: value,
})

export const updateMagicToolCurrPoint = (value) => ({
    type: MagicToolActions.UPDATE_CURR_POINT,
    response: value,
})

export const reloadMagicToolData = (value) => ({
    type: MagicToolActions.RELOAD,
    response: value,
})

export const updateCurrentLevel = (value) => ({
    type: MagicToolActions.UPDATE_CURRENT_LEVEL,
    response: value,
})

export const getAnnotations = (start,z, slide_id, extent, user_id, anno_drawer) => (dispatch) => {
    let url = `/api/get_brain_slider/`;
    let val = {
        'start_point':JSON.stringify(start),
        'z': z,
        'slide': slide_id,
        'extent': JSON.stringify(extent),
        'user_id': user_id
    }
    dispatch(resetMagicTool());
    dispatch(updateFetchingStatus(true));
    axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(updateFetchingStatus(false));
                console.log("data", response.data)
                dispatch(updateUnsavedMagicAnnotationsRaw(response.data));
                let annotations_data_level_wise = response.data;
                let annotation_features_data = [];
                for(let i=0; i<annotations_data_level_wise.length; i++){
                    let anno_data = annotations_data_level_wise[i];
                    let anno = getAnnotationFromPoints(anno_data['color'], anno_data['area'], anno_data['perimeter'], magicToolResultDrawingKey,
                                anno_data['new_bounds'], anno_data['center'], anno_drawer,
                                z, slide_id
                            )
                    let feature = getFeatureFromAnnotation(anno);
                    annotation_features_data.push(feature);
                    console.log("i-xx", anno_data, feature)
                }

                dispatch(updateUnsavedMagicAnnotations(annotation_features_data));
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                dispatch(resetMagicTool());
                dispatch(updateFetchingStatus(false));
            }
        })
        .catch(err => {
            dispatch(resetMagicTool());
            dispatch(updateFetchingStatus(false));
        });
}
