import { Input, Row, Spin, message } from 'antd';
import axios from "axios";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { setDeviceBusy, setDeviceIdle } from '../../action/device_status.action';
import {
    initPreviewForScan,
    takePreview,
    updatePreviewStatus,
    fetchingPreview,
    receivedPreviewStatus
} from "../../action/preview_status.action";
import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant";
import "../../asset/style/scanner/scanner.css";
import { globalUrlPrefix, progressStage, deviceType, cassetteType } from "../../utils/const";
import ScannerTimeline from './scanner_timeline';
import LoadSlidesPrompt from './scanWorkflow/loadSlidesPrompt';
import ScanProgress from './scanWorkflow/scan_progress';
import SetScanParamsView from './scanWorkflow/set_scan_params_view_new';
import { AuthHeader } from '../../helper/auth.token';
import { ScanApiConstant } from '../../actionTypes/scan.constant';


const { Search } = Input;

class ScanWorkflowNew extends Component {
    constructor(props) {
        super(props);
        // this.progressStage ={
        //     LOAD_SLIDES : "0",
        //     SET_PARAMETERS : "1",
        //     SCANNING: "2"
        // }

        this.state = {
            currentProcessStage: progressStage.LOAD_SLIDES,
            cassetteSize: 0,
            isObjectiveSwitcherPresent: false,
            activeSlot: -1,
            busy:false, 
            showSummary: false,
            scannerProperties: {},
            deviceType: "",
            cassetteType: cassetteType.SIX_1_BY_3_SLIDES, // default, NOTE: this field is only used for MAXIMUS scanner
            detectingCassette: false,
            askUserCassetteType: false // if true, we ask user the cassette type manually
        }
    }
    
    componentDidMount = () => {
        console.log("preogrss stage", this.props.match.params.stage);
        // if (this.props.match.params.stage == progressStage.SET_PARAMETERS) {
            // this.navigateToScannerDashboard();
        // } else {
            this.getScannerProperties();
        // }
    }

    componentDidUpdate = () => {
        // console.log(this.props);
    }

    navigateToScannerDashboard = () => {
        this.props.history.push("/" + globalUrlPrefix + "/scanners/" + this.props.match.params.id);
    }

    getScannerProperties = () => {
        this.props.dispatch(setDeviceBusy(this.props.match.params.id, "Setting Up"));
        const url = "/server/devices/" + this.props.match.params.id + "/settings/get_scanner_properties";
        axios
        .get(url)
        .then(res => {
            for (let i = 0; i < res.data.cassetteSize; i++) {
                this.initSlot(i, res.data.defaultConfig);
            }
            if ((res.data.cassetteSize === 1 || res.data.cassetteSize === 2) && this.props.match.params.stage == progressStage.LOAD_SLIDES) {
                this.activate(0, res);
            } else {
                console.log("Check " + res.data.cassetteSize)
                this.setState(Object.assign({}, this.state, {
                    cassetteSize: res.data.cassetteSize,
                    isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
                    allowScanOutputFormatChange: res.data.allowScanOutputFormatChange,
                    allowObjectiveChange: res.data.allowObjectiveChange,
                    defaultConfig: res.data.defaultConfig,
                    scannerProperties: res.data,
                    deviceType: res.data.deviceType
                }));
                this.props.dispatch(setDeviceIdle(this.props.match.params.id));
            }
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.match.params.id));
        });
    }

    activate = (key, res) => {
        this.setState(Object.assign({}, this.state, {activeSlot: key, showSummary: false}));
        this.takePreview(key, res);
    }

    takePreview = (slot, res) => {
        this.props.dispatch(updatePreviewStatus(slot, RegionSelectionConstants.GETTING_IMAGE));
        this.props.dispatch(setDeviceBusy(this.props.match.params.id, "Taking Preview"));
        this.props.dispatch(fetchingPreview(slot));
        let url = `/server/devices/` + this.props.match.params.id + "/take_preview?slot_id=" + slot;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, JSON.parse(response.data), slot))
                    this.props.dispatch(setDeviceIdle(this.props.match.params.id));
                    this.setState(Object.assign({}, this.state, {
                        cassetteSize: res.data.cassetteSize,
                        isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
                        allowScanOutputFormatChange: res.data.allowScanOutputFormatChange,
                        allowObjectiveChange: res.data.allowObjectiveChange,
                        defaultConfig: res.data.defaultConfig,
                        scannerProperties: res.data,
                        currentProcessStage: progressStage.SET_PARAMETERS,
                    }));
                }
                else {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, slot))
                    this.props.dispatch(setDeviceIdle(this.props.match.params.id));
                }
            })
            .catch(err => {
                this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, slot))
                this.props.dispatch(setDeviceIdle(this.props.match.params.id));
                console.log(err)
            })
    }

    initSlot = (slot, defaultConfig) => {
        this.props.dispatch(initPreviewForScan(slot, defaultConfig));
    }

    setDetectingCassette = (detecting) => {
        this.setState({detectingCassette: detecting});
    }

    // for Maximus scanner, user selected cassette type
    setCassetteType = (cassetteType) => {
        // 1. set cassette type in backend
        let url = `/server/devices/` + this.props.match.params.id + "/set_cassette_type?cassette_type=" + cassetteType;

        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                // 2. update UI
                console.debug("user selected cassetteType=", cassetteType);
                this.setState({
                    cassetteType: cassetteType
                });

                // UPDATE after detection
                this.setState({
                    currentProcessStage: progressStage.SET_PARAMETERS,
                    detectingCassette: false,
                    askUserCassetteType: false
                });
                this.props.history.push("/" + globalUrlPrefix + "/scannerflow/" + this.props.match.params.id + "/scan/" + progressStage.SET_PARAMETERS);
            }
        })
        .catch(err => {
            console.log(err);
            message.error("Failed to set cassette type, please contact Morphle Admin");
        });
    }

    handleProgressChange = (stage) => {
        console.debug(`deviceType=${this.state.deviceType}`);

        // detect cassette type at this stage
        if (stage == progressStage.SET_PARAMETERS && this.state.deviceType == deviceType.MAXIMUS) {
            this.setState({detectingCassette: true});
            let url = `/server/devices/` + this.props.match.params.id + "/detect_cassette_type";
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200 || response.status === 301 || response.status === 302) {

                        if (response.data.detectionSuccess) {
                            console.debug("cassette detection response", response.data);
                            this.setState({
                                cassetteType: response.data.cassetteType
                            });
    
                            // UPDATE after detection
                            this.setState({
                                currentProcessStage: progressStage.stage,
                                detectingCassette: false
                            });
                            console.debug("handleProgressChange", stage);
                            this.props.history.push("/" + globalUrlPrefix + "/scannerflow/" + this.props.match.params.id + "/scan/" + stage);
                        } else {
                            console.warn("failed to detect cassette, asking user");
                            this.setState({
                                detectingCassette: false,
                                askUserCassetteType: true
                            });
                        }
                    } else {
                        // detection failed, ask user
                        console.error("response is", response);

                        console.warn("failed to detect cassette, asking user");
                        this.setState({
                            detectingCassette: false,
                            askUserCassetteType: true
                        });
                    }
                })
                .catch(err => {
                    console.error("error detecting cassette:", err);

                    console.warn("failed to detect cassette, asking user");
                    this.setState({
                        detectingCassette: false,
                        askUserCassetteType: true
                    });
                })
        } else {
            this.setState({
                currentProcessStage: progressStage.stage,
            })
            console.log("handleProgressChange",stage)
            this.props.history.push("/" + globalUrlPrefix + "/scannerflow/" + this.props.match.params.id + "/scan/" + stage);
            // window.location.href = "/" + globalUrlPrefix + "/scannerflow/" + this.props.match.params.id + "/scan/" + stage;
            // console.log("handlehistory",this.props.history)
        }
    }
    
    render() {

        let cassetteSize = this.state.cassetteSize;
        
        let slotMenu = null;
        let summaryDiv = null;

        let noRegionSelected = 0;
        for (let i = 0; i < cassetteSize; i++) {
            let preview = this.props.previews[i];
            if ((preview || {}).status == RegionSelectionConstants.NOT_STARTED) {
                noRegionSelected += 1;
            }
        }

        let currentStageView;
        // switch (this.props.match.params.stage) {
        //     case progressStage.LOAD_SLIDES:
        //         // currentStageView = <LoadSlidesPrompt handleProgressChange={this.handleProgressChange} currentProcessIndex={this.props.match.params.stage}/>
        //         currentStageView = <SetScanParamsView 
        //                                 handleProgressChange={this.handleProgressChange} 
        //                                 device_id={this.props.match.params.id}
        //                                 currentProcessIndex={this.props.match.params.stage} 
        //                                 previews={this.props.previews}
        //                                 progressStage={this.props.match.params.stage}
        //                                 scannerProperties={this.state.scannerProperties}
        //                             />
        //         break;
        //     case progressStage.SET_PARAMETERS:
        //         currentStageView = <SetScanParamsView 
        //                                 handleProgressChange={this.handleProgressChange} 
        //                                 device_id={this.props.match.params.id}
        //                                 currentProcessIndex={this.props.match.params.stage} 
        //                                 previews={this.props.previews}
        //                                 scannerProperties={this.state.scannerProperties}
        //                             />
        //         break;
        //     case progressStage.SCANNING:
        //         currentStageView = <ScanProgress handleProgressChange={this.handleProgressChange} device_id={this.props.match.params.id}/>
        //         break;
        //     default:
        //         break;
        // }

        return (
            <div>
                {this.state.cassetteSize != 0 ?
                    <Row className="rounded-container">
                        <ScannerTimeline currentProcessIndex={this.props.match.params.stage} handleProgressChange={this.handleProgressChange} device_id={this.props.match.params.id} />
                        {this.props.match.params.stage == progressStage.LOAD_SLIDES ?
                            <SetScanParamsView 
                                setDetectingCassette={this.setDetectingCassette}
                                detectingCassette={this.state.detectingCassette}
                                askUserCassetteType={this.state.askUserCassetteType}
                                setCassetteType={this.setCassetteType}
                                handleProgressChange={this.handleProgressChange} 
                                device_id={this.props.match.params.id}
                                currentProcessIndex={this.props.match.params.stage} 
                                previews={this.props.previews}
                                progressStage={this.props.match.params.stage}
                                scannerProperties={this.state.scannerProperties}
                                navigateToScannerDashboard={this.navigateToScannerDashboard}
                                defaultConfig={this.state.defaultConfig}
                                isMaximusScanner={this.state.deviceType === deviceType.MAXIMUS}
                            /> :
                            this.props.match.params.stage == progressStage.SET_PARAMETERS ?
                                <SetScanParamsView 
                                    setDetectingCassette={this.setDetectingCassette}
                                    detectingCassette={this.state.detectingCassette}
                                    askUserCassetteType={this.state.askUserCassetteType}
                                    setCassetteType={this.setCassetteType}
                                    handleProgressChange={this.handleProgressChange} 
                                    device_id={this.props.match.params.id}
                                    currentProcessIndex={this.props.match.params.stage} 
                                    previews={this.props.previews}
                                    scannerProperties={this.state.scannerProperties}
                                    navigateToScannerDashboard={this.navigateToScannerDashboard}
                                    defaultConfig={this.state.defaultConfig}
                                    cassetteType={this.state.cassetteType}
                                    isMaximusScanner={this.state.deviceType === deviceType.MAXIMUS}
                                /> :
                                this.props.match.params.stage == progressStage.SCANNING ?
                                    <ScanProgress 
                                        handleProgressChange={this.handleProgressChange} 
                                        device_id={this.props.match.params.id} 
                                        scannerProperties={this.state.scannerProperties}
                                    />
                                    : null
                        }
                        {/* {currentStageView} */}
                    </Row> :
                    <div style={{width: '100%', height: '800px'}} className="rounded-container">
                        <Spin spinning={this.state.cassetteSize == 0} tip="Taking Preview...">
                            <div style={{width: '100%', height: '800px'}}></div>
                        </Spin>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews : state.previewStatusReducer,
        deviceProperties : state.deviceReducer,
        deviceStatus: state.deviceStatusReducer,
    };
};

export default connect(mapStateToProps)(ScanWorkflowNew);
