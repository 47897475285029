import React, {Component} from  "react";
import {connect} from 'react-redux';
import Draw from 'ol/interaction/Draw.js';
import { Snap, Modify } from 'ol/interaction.js';
import {Overlay} from "ol";
import LineString from 'ol/geom/LineString';
import { getCenter } from 'ol/extent.js'
import {IconButton, Tooltip} from "@mui/material";
import {magicToolDrawingKey, magicToolResultDrawingKey} from "./draw_tool_keys";
import {
    updateStartPoint,
    getAnnotations,
    updateMagicToolCurrPoint,
    resetMagicTool
} from "../../../action/magic_tool.action";
import cookie from "react-cookies";
import {store} from "../../../helper/store"
import {morphleBaseTheme1} from "../themes";

class OverlayMeasure {
    constructor(map, element = document.getElementById("popup"), offset = [0, -5], positioning = 'bottom-center',   className = 'ol-tooltip-measure ol-tooltip .ol-tooltip-static') {
        this.map = map;
        this.overlay = new Overlay({
            element: element,
            offset: offset,
            positioning: positioning,
            className: className
        });
        this.overlay.setPosition([0,0]);
        this.overlay.element.style.display = 'block';
        this.map.addOverlay(this.overlay);
    }
}

class DrawTool extends Component {

    constructor(props) {
        super(props);

        this.draw = null;
        this.snap = null;
        this.shiftPressed = false;
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyDownListener, false);
        document.addEventListener("keyup", this.keyUpListener, false);
    }

    componentWillUnmount() {
        // component is unmounting when splitting map, due to the fact circular progress replace it for while
        // we need to do perform these things on unmounting
        document.removeEventListener("keydown", this.keyDownListener, false);
        document.removeEventListener("keyup", this.keyUpListener, false);
        this.stopDrawing();
        this.props.dispatch(resetMagicTool());
    }

    initState = () => {
        // On change of activeMapId, manually pressing esc key to close drawtool
        if (this.activeMapId && this.props.gammaState.activeMapId !== this.activeMapId) {
            this.stopDrawing();
            this.props.dispatch(resetMagicTool());
        }
        this.activeMapId = this.props.gammaState.activeMapId;
        this.activeAnnoDrawer = this.props.gammaState.activeAnnoDrawer;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!this.shiftPressed) {
            this.slideState.slidemap.removeInteraction(this.draw);
            this.slideState.slidemap.removeInteraction(this.snap);
            this.slideState.slidemap.removeInteraction(this.modify);
        }
        if (this.props.currentSelectedKey === this.props.toolKey.name && !this.shiftPressed) {
            this.modify = new Modify({
                source: this.props.vector.getSource(),
                snapToPointer: true,
                pixelTolerance : 20,
            })

            this.slideState.slidemap.addInteraction(this.modify)
            this.modify.setActive(true);
            this.draw = new Draw({
                source: this.props.vector.getSource(),
                type: this.props.toolKey.shape,
                ...this.props.toolParams
            });
            this.slideState.slidemap.addInteraction(this.draw);
            // if (this.props.snapDrawing || true) {
            this.snap = new Snap({ source: this.props.vector.getSource(),pixelTolerance : 20,snapToEdges: true, snapToVertices: true  });
            this.slideState.slidemap.addInteraction(this.snap);
            // }

            this.draw.on("drawstart", this.onDrawStart, this);
            this.draw.on("drawend", this.onDrawEnd, this);
            document.addEventListener('contextmenu', () => {
                this.slideState.slidemap.getOverlays().clear();
            }, false);
            this.calDistance = (overlay, overlayPosition, distance) => {
                if(parseInt(distance) === 0) {
                    overlay.setPosition([0,0]);
                }
                else {
                    overlay.setPosition(overlayPosition);
                    if (distance >= 1000) {
                        overlay.element.innerHTML = (distance/1000).toFixed(2) + ' mm';
                        overlay.element.innerHTML = '<p style="color: DarkBlue; font-weight: bold; font-size:20px; ' +
                            'text-shadow: 0 0 5px white">' + (distance/1000).toFixed(2) + 'mm' + '</p>'
                    }
                    else {
                        overlay.element.innerHTML = '<p style="color: DarkBlue; font-weight: bold; ; font-size:20px; ' +
                            'text-shadow: 0 0 5px white">'+distance.toFixed(2) + 'um' + '</p>' ;
                    }
                }
            }
            this.calAngle = (overlay, overlayPosition, coordinates, e) => {
                // 1. get all the features that are linestrings
                // 2. get coordinates of all those features
                // 3. make line equations for all these features and check whether snap point satisfies any
                // 4. if any satisfied find angle between snap line and the line which we snapped to
                // 5. return the angle
                var features = this.props.vector.getSource().getFeatures();
                features = features.filter(f => {
                    return f.getGeometry().getType() === "LineString" && e.target.flatCoordinates.length === 4;
                })
                let coords_lines = features.map(f => {
                    return f.getGeometry().getCoordinates();
                })
                let found_any = false;
                console.log(coords_lines)
                for(let i=0; i<coords_lines.length; i++){
                    let line = coords_lines[i];
                    let point1 = line[0];
                    let point2 = line[1];
                    let x1 = point1[0]
                    let y1 = point1[1]
                    let x2 = point2[0]
                    let y2 = point2[1]
                    let snap_point = coordinates[1]
                    let a = snap_point[0]
                    let b = snap_point[1]

                    if(((b-y1)*(x2-x1)).toFixed(2)===((y2-y1)*(a-x1)).toFixed(2)){
                        found_any = true;
                        let p = coordinates[0]
                        let c = p[0]
                        let d = p[1]
                        let ms = (d-b)/(c-a)
                        let ml = (y2-y1)/(x2-x1)
                        let anglerad = Math.atan((ms-ml)/(1+ms*ml))
                        let angledeg = (anglerad * 180)/Math.PI
                        // if(angledeg<0){
                        //     angledeg = 180-angledeg;
                        // }
                        if(Math.abs(angledeg)>89){
                            angledeg=90
                        }
                        console.log("angle",parseInt(angledeg))
                        overlay.setPosition(overlayPosition);
                        if(String(parseInt(Math.ceil(angledeg)))!== "NaN"){
                            overlay.element.innerHTML='<p style="color: DarkBlue; font-weight: bold; font-size:15px; margin-botton: -10px;text-shadow: 0 0 5px white">'+parseInt(Math.ceil(angledeg))+ '&#176'+ '</p>';
                            }else{
                            overlay.element.innerHTML = ''
                            }
                        // overlay.element.innerHTML='<p style="color: DarkBlue; font-weight: bold; font-size:15px; margin-botton: -10px;text-shadow: 0 0 5px white">'+parseInt(Math.ceil(angledeg))+ '&#176'+ '</p>';
                        let angle = parseInt(Math.ceil(angledeg))
                        if(parseInt(Math.abs(angle)) >= 85 && Math.abs(angle) != 90){
                            let slope = (y2-y1)/(x2-x1);
                            // let nb = ((d*slope)-(x1)+(y1/slope))/(1+(1/slope))
                            // let na = x1 + ((nb-y1)/slope)
                            let na = (d+(c/slope)+(x1*slope)-y1)/(slope+(1/slope))
                            let nb = (slope*(na-x1))+y1
                            e.target.setCoordinates([coordinates[0],[na,nb]]);
                            return [na,nb];
                            // this.draw.appendCoordinates([na,nb]);
                        }
                    }
                }
                if(!found_any){
                    // /* this.slideState.slidemap.getOverlays().pop(); */
                    overlay.setPosition(overlayPosition);
                    // overlay.element.innerHTML='<p style="color: DarkBlue; font-weight: bold; font-size:10px; margin-botton: -10px">+''+'</p>';
                    overlay.element.innerHTML = ''
                }
                return []
            }
        }
    }

    keyDownListener = (event) => {
        if (event.target.tagName === 'INPUT' || event.ctrlKey || event.altKey)
            return;
        switch (event.code) {
            case "ShiftLeft":
            case "ShiftRight":
                this.shiftPressed = true;
                break;
            case this.props.toolKey.shortcutKeyCode:
                if (this.draw && !this.drawing) {
                    this.draw.setActive(true);
                    this.drawing = true;
                }
                break;
            default:
                break;
        }
    }

    keyUpListener = (event) => {
        if (event.target.tagName === 'INPUT' || event.shiftKey || event.ctrlKey || event.altKey)
            return;
        switch (event.key) {
            case "ShiftLeft":
            case "ShiftRight":
                this.shiftPressed = false;
                break;
            case this.props.toolKey.shortcutKeyCode:
                this.draw.setActive(false);
                this.drawing = false;
            case "Escape":
                this.props.resetTools();
                this.stopDrawing();
                event.stopPropagation();
                this.props.dispatch(resetMagicTool());
                break;
            default:
                break;
        }
    }

    stopDrawing = () => {
        if (!this.props.continuousMode) {
            this.slideState.slidemap.removeInteraction(this.draw);
            this.slideState.slidemap.removeInteraction(this.modify);
        }
        this.slideState.slidemap.getOverlays().clear();
        // this.props.setDrawing(false);
    }

    onDrawStart = (e) => {
        // this.props.setDrawing(true);
        if(this.props.currentSelectedKey === magicToolDrawingKey.name){
            e.feature.getGeometry().on('change',this.onGeomChange);
            let start = this.props.getGeometricParams(e.feature.getGeometry()).coordinates[0];
            this.props.dispatch(getAnnotations(start, this.mapState.z, this.slideState.slide_data.id,
                                                this.slideState.slidemap.getView().calculateExtent(
                                                    this.slideState.slidemap.getSize()),
                cookie.loadAll().user_id, this.activeAnnoDrawer))
            this.props.dispatch(updateStartPoint(start))
        }else{
            this.coordinates_length = 0;
            this.partDistanceOverlay = null;
            this.partAngleOverlay = null;
            this.overlay = new Overlay({
                element: document.getElementById('popup'),
                offset: [0, 5],
                positioning: 'bottom-center',
                className : 'ol-tooltip-measure ol-tooltip .ol-tooltip-static',
            })
            this.totalAreaDistanceOverlay = this.slideState.slidemap.addOverlay(this.overlay);
            e.feature.getGeometry().on('change',this.onGeomChange);
        }
    }


    onDrawEnd = (e) => {
        if(this.props.currentSelectedKey === magicToolDrawingKey.name){
            let state = store.getState().MagicToolReducer;
            let anno = state.unsaved_magic_annotations_raw[state.curr_level];
            if(anno === undefined)
                return;
            this.props.storeAnnotationMagicTool(
                anno['area'], anno['perimeter'], magicToolResultDrawingKey, anno['new_bounds'], "[0,0]"
            )
        }else{
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let params = this.props.getGeometricParams(geometry);

            this.props.addAnnotation(params.area, params.perimeter, this.props.toolKey, JSON.stringify(params.coordinates), getCenter(geometry.getExtent()));
            this.stopDrawing();
        }
    }


    onGeomChange = (e) => {
        if(this.props.currentSelectedKey === magicToolDrawingKey.name){
            let coordinates = e.target.getCoordinates();
            this.props.dispatch(updateMagicToolCurrPoint(coordinates[1]));
        }else{
            let geomType = e.target.getType();
            let coordinates = e.target.getCoordinates();
            let setToNinety = false;
            if(geomType !== "LineString" ||((e.target||{}).flatCoordinates||{}).length>4){
                return;
            }
            if (coordinates.length > this.coordinates_length) {
                this.partDistanceOverlay = new OverlayMeasure(this.slideState.slidemap).overlay;
                this.partAngleOverlay = new OverlayMeasure(this.slideState.slidemap).overlay;
                this.coordinates_length =  coordinates.length;
            }
            else {
                this.coordinates_length =  coordinates.length;
            }
            let partLine = new LineString([coordinates[this.coordinates_length-2], coordinates[this.coordinates_length-1]]);
            this.calDistance(this.partDistanceOverlay, partLine.getFlatMidpoint(), partLine.getLength());
            let coords = this.calAngle(this.partAngleOverlay, coordinates[1], coordinates, e)
        }
    }

    render() {
        this.initState();

        let icon;
        if (this.props.currentSelectedKey === this.props.toolKey.name) {
            icon = <IconButton onClick={(this.props.urlState || {}).presentCode !== undefined ? null : () =>
                this.props.onSelected(this.props.toolKey.name)} sx={{verticalAlign:'middle',
                color:morphleBaseTheme1.palette.headBarIcon.secondary}}>
                {this.props.contentSelected}
            </IconButton>
        }
        else {
            icon = <Tooltip title={this.props.toolKey.name} placement={"bottom"}>
                <IconButton onClick={(this.props.urlState || {}).presentCode !== undefined ? null : () =>
                    this.props.onSelected(this.props.toolKey.name)} sx={{verticalAlign:'middle',
                    color: morphleBaseTheme1.palette.headBarIcon.primary }}
                            disabled={!this.props.isActive}>
                    {this.props.content}
                    </IconButton>
            </Tooltip>
        }
        return (
            <>
                {icon} 
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        gammaState: state.gammaStateReducer,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(DrawTool);
