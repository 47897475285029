import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CreateIcon from "@mui/icons-material/Create";
import RouteIcon from "@mui/icons-material/Route";
import PentagonOutlinedIcon from "@mui/icons-material/PentagonOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";

export const lineDrawingKey = {
    name: 'Line',
    db_key: 'line',
    shape: 'LineString',
    icon: <RemoveIcon sx={{transform: "rotate(-45deg) scale(1.4)"}} />,
    shortcutKeyCode: 'Digit1',
}
export const freelineDrawingKey = {
    name: 'Freeline',
    db_key: 'line',
    shape: 'LineString',
    icon: <CreateIcon />,
    shortcutKeyCode: 'Digit2',
}
export const circleDrawingKey = {
    name: 'Circle',
    db_key: 'circle',
    shape: 'Circle',
    icon: <CircleOutlinedIcon />,
    shortcutKeyCode: 'Digit3',
}
export const freehandDrawingKey = {
    name: 'Freehand',
    db_key: 'polygon',
    shape: 'Polygon',
    icon: <RouteIcon />,
    shortcutKeyCode: 'Digit4',
}
export const rectangleDrawingKey = {
    name: 'Rectangle',
    db_key: 'polygon',
    shape: 'Circle',
    icon: <RectangleOutlinedIcon />,
    shortcutKeyCode: 'Digit5',
}
export const polygonDrawingKey = {
    name: 'Polygon',
    db_key: 'polygon',
    shape: 'Polygon',
    icon: <PentagonOutlinedIcon />,
    shortcutKeyCode: 'Digit6',
}

export const magicToolDrawingKey = {
    name: 'MagicTool',
    db_key: 'line',
    shape: 'LineString',
    icon: <AutoFixHighOutlinedIcon />,
    shortcutKeyCode: 'Digit7',
}

export const magicToolResultDrawingKey = {
    name: 'MagicToolResult',
    db_key: 'polygon',
    shape: 'Polygon',
    icon: <AutoFixHighOutlinedIcon />,
}
