import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    FormControl,
    Table,
    TableHead, TableRow, TableCell, TableBody, TableContainer, Paper
} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import {FaCircle} from "react-icons/all";
import Typography from "@mui/material/Typography";
import {AllAnnotationsConstants} from "../../../consts/anno.const";
import {getFormattedArea, getFormattedLength} from "../../../utils/utils";
import AnnotationComments from "./annotationComments";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    deleteAnnotation,
    deleteDeepBioAnnotation,
    updateAnnotation, updateDeepBioAnnotation,
} from "../../../action/maps.state.action";

class AnnotationListExpandedComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            edit_mode: false,
            filteredColor: AllAnnotationsConstants.ALL_COLORS,
            inputValue: this.props.annotation.title,
        }
    }

    initState = () => {
        this.activeMapId = this.props.activeMapId;
    }

    toggleEditMode = () => {
        let edit_mode = this.state.edit_mode;
        this.setState({
            edit_mode: !edit_mode,
        })
    }

    getEditComponent = (edit_mode) => {
        // if(edit_mode){
        //     return <EditOffIcon onClick={this.toggleEditMode}/>
        // }else{
        //     return <ModeEditIcon onClick={this.toggleEditMode}/>
        // }
        return <IconButton onClick={this.toggleEditMode} color={'secondary'}>
            {edit_mode? <EditOffIcon/> : <ModeEditIcon/>}
        </IconButton>
    }

    updateAnnotationGeneric = (annotation) => {
        if (this.props.annotation.anno_drawer === 0)
            this.props.dispatch(updateAnnotation(this.activeMapId, annotation));
        else if (this.props.annotation.anno_drawer === 1)
            this.props.dispatch(updateDeepBioAnnotation(this.activeMapId, annotation));
    }

    // changeColorFilter = (value) => {
    //     if(value.toLowerCase() != this.state.filteredColor.toLowerCase()) {
    //         this.setState({
    //             selectedAnnotationId: null,
    //         });
    //     }
    //     this.setState({
    //         filteredColor: value.toLowerCase(),
    //     });
    // }

    // changeColor = (annotation, color, updateAnnotationsCallback) => {
    //     console.log("deb-test", annotation.color, color)
    //     this.props.dispatch(changeAnnotationColor(annotation, color))
    // }

    getColorAndNameComponent = () => {
        let component;
        let colors = AllAnnotationsConstants.COLORS_LIST;
        let title;
        if(this.state.edit_mode){
            component=<Grid display={"flex"} justifyContent={"center"}>
                            <FormControl  color="secondary" size="small" variant="filled" sx={{ minWidth: 70 }}>
                                <Select
                                    value = {this.props.annotation.color.toLowerCase()}
                                    label="Change Color"
                                    onChange={(e) => this.updateAnnotationGeneric({
                                        id: this.props.annotation.id,
                                        color: e.target.value,
                                    })} //, doRefreshAnnotationsFromBackend
                                    size={"small"}
                                >
                                    {colors.map((color, index) => {
                                    return <MenuItem value={color.toLowerCase()}>
                                                <CircleIcon sx={{color:color.toLowerCase()}}/>
                                            </MenuItem>
                                })}
                                </Select>
                            </FormControl>

                            <TextField autoFocus color='secondary' margin='normal' variant="filled" value={this.state.inputValue}
                                       onKeyPress={(e) => {
                                           if (e.key === 'Enter') {
                                               this.updateAnnotationGeneric({
                                                   id: this.props.annotation.id,
                                                   title: this.state.inputValue,
                                               });
                                               this.setState({edit_mode:false})
                                               e.preventDefault();
                                           }
                                       }}
                                       onChange={(e) =>
                                           this.setState({
                                               inputValue : e.target.value
                                           })}
                                       sx={{px:1, backgroundColor:'#0b1c3b', justifyContent:'center'}}/>
                            <IconButton size="small" onClick={(e) => {
                                this.updateAnnotationGeneric({
                                    id: this.props.annotation.id,
                                    title: this.state.inputValue,
                                })
                                this.setState({edit_mode:false})
                            }}>
                                <CheckIcon color={'secondary'}/>
                            </IconButton>
                        </Grid>
        }else{
            component = <Grid display={"flex"} justifyContent={"center"}>
                            <FaCircle style={{color:this.props.annotation.color, marginTop:'0.3vh'}}/>
                            <Typography gutterBottom={true} sx={{marginLeft:'0.5vw'}}
                            >{this.props.annotation.title}</Typography>
                        </Grid>
        }
        return component;
    }

    getInfoComponent = () => {
        let annotation = this.props.annotation;
        return <Grid display={"flex"}>
            {
                annotation.shape === AllAnnotationsConstants.LINE ?
                    <div>
                        <b>Length : </b> {getFormattedLength(annotation.perimeter)}
                    </div>:
                    <div>
                        <b>Area : </b> {getFormattedArea(annotation.area)}
                        <p><b>Perimeter: </b> {getFormattedLength(annotation.perimeter)}</p>
                    </div>
            }
        </Grid>
    }

    getAppSpecificTools = () => {
        let tools = null;
        if (this.props.app === "DeepBio") {
            tools = <IconButton onClick={(e) => {this.props.switchDeepBioVisibility(this.props.annotation)}} color={'secondary'}>
                <VisibilityIcon/>
            </IconButton>
        }
        return tools;
    }

    getDeepBioTable = (summary) => {

        let tableData=[];
        let deepBioTable=null;
        if (this.props.app==="DeepBio") {
            let deep_bio_result = (this.props.annotation||{}).deep_bio_result;
            console.log("summm", summary);
            let summary_ = summary;
            if(!summary_){
                return <></>;
            }
            // let deep_bio_label = deep_bio_result["heatmap"]["contours"][0]["label"];
            // tableData.push(<TableRow>
            //     <TableCell sx={{color:"#00f548"}}>LABEL</TableCell>
            //     <TableCell>{deep_bio_label}</TableCell>
            // </TableRow>)
            // console.log("test1", deep_bio_label, Object.keys(deep_bio_label))
            for(let i=0; i<(Object.keys(summary_).length);i++){
                // if(i == Object.keys(summary_).length-1){
                //     console.log("object", JSON.stringify(Object.values(summary_)[i]), typeof(JSON.stringify(Object.values(summary_)[i])))
                // }else {
                //     tableData.push(<TableRow>
                //         <TableCell sx={{color:"#00f548"}}>{Object.keys(summary_)[i].toUpperCase()}</TableCell>
                //         <TableCell>{Object.values(summary_)[i]}</TableCell>
                //     </TableRow>)
                // }
                tableData.push(<TableRow>
                    <TableCell sx={{color:"#00f548"}}>{Object.keys(summary_)[i].toUpperCase()}</TableCell>
                    <TableCell>{JSON.stringify(Object.values(summary_)[i])}</TableCell>
                </TableRow>)
            }
            console.log("test2", tableData)
            deepBioTable = <TableContainer component={Paper}>
                                <Table sx={{ width: '95%' }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            {/*<TableCell>Property</TableCell>*/}
                                            {/*<TableCell align="right">Value</TableCell>*/}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData}
                                    </TableBody>
                                </Table>
                            </TableContainer>


        }
        return deepBioTable
    }

    render() {
        this.initState();
        let edit_mode = this.state.edit_mode;
        let annotation = this.props.annotation;
        let summary;
        let deepBio = false;
        if(annotation.deep_bio_result){
            summary = (annotation.stored_result||{})["summary"];
        }
        if(this.props.app === "DeepBio"){
            deepBio = true;
        }
        return <Grid >
            {/*TODO: chnage bg color*/}
            {/*actionables->edit, del*/}
                    <Grid display="flex" justifyContent="flex-end">
                        {/*{this.getAppSpecificTools()}*/}
                        {this.getEditComponent(edit_mode)}
                        <IconButton onClick={(e) => {deepBio ?
                            this.props.dispatch(deleteDeepBioAnnotation(this.activeMapId, this.props.annotation.id)) :
                            this.props.dispatch(deleteAnnotation(this.activeMapId, this.props.annotation.id))}}
                                    color={'secondary'}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                    <Grid>
                        {this.getColorAndNameComponent()}
                    </Grid>
                    <br/>
                    <Grid>
                        {annotation.stored_result?this.getDeepBioTable(summary):null}
                    </Grid>
                    <br/>
                    {this.getInfoComponent()}
                    {/*<CommentComp annoId={this.props.annotation.id}></CommentComp>*/}
                    <AnnotationComments annoId={this.props.annotation.id}/>
                </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(AnnotationListExpandedComponent)
