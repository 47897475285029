import React, {Component} from "react";
import {connect} from 'react-redux';
import BackgroundBlendAppView from "./background_blend_app_view";

class BackgroundBlendApp extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedAnnoMap: "",
        }
    }

    initState = () => {
        console.debug("BackgroundBlendApp initState");
        this.annotationState = this.props.mapsState[this.props.activeMapId].annotationState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.triggerData.annotation_app_anno_id_selected !==
            this.props.triggerData.annotation_app_anno_id_selected) &&
            this.props.triggerData.annotation_app_anno_id_selected !== ''){
            this.setState({
                selectedAnnoMap: this.props.triggerData.annotation_app_anno_id_selected,
            });
        }
    }

    render() {
        this.initState();
        if (!this.annotationState) return <div />;

        console.debug("BackgroundBlendApp render");

        return <BackgroundBlendAppView
            data={this.annotationState.annotations || []}
            getExpandedComponent={undefined} // you can give your own or default will be used
            getMultipleSelectComponent={undefined} // you can give your own or default will be used
            vector={this.annotationState.vector} // vector of map in which anno features are
            selectedAnnoMap= {this.state.selectedAnnoMap}
            getDefaultTopComponent = {undefined}
            showTopBar={true}
        />
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
        triggerData: state.triggerReducer,
    }
}

export default connect(mapStateToProps)(BackgroundBlendApp)
