import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";

import {
    BookOutlined,
    CloudUploadOutlined,
    DeleteFilled,
    InfoCircleOutlined,
    SettingFilled,
    StarFilled,
} from '@ant-design/icons';

import { Table, Button, Row, Modal, message, Col, Pagination, Divider, Tooltip } from 'antd';
import { startUpload, slideUpdateAlert, startTiling } from '../../action/slides.action'
import { changePage } from '../../action/search.action';
import { deleteSlide } from "../../action/slides.action";
import { historyMP } from "../../helper/history";
import FilterComp from "../dashboard/filter";
import SearchBar from "../dashboard/searchbar";
import MultipleTagAddition from "../dashboard/multiple.tag.addition";
import { globalUrlPrefix, openInNewTabType, slideViewType } from '../../utils/const'
import { getTilingComponent, getExportComponent, getTagsComponent, getPreviewComponent, getLabelComponent, getDeleteComponent, getStarredComponent, getDbEntryComponent, getUploadComponent, getMorphleIDComponent, getAssignCaseComponent, getObjectiveComponent, getAssignCaseButtonComponent, stitchingPercentComponent, getOldTilingComponent } from "./slidelist_utils";
import CaseSuggestions from '../viewer/viewer.case';
import MultipleCaseSuggestions from '../dashboard/multiple.case.assign.modal';
import ChangeSlideOwner from '../dashboard/change.slide.owner.modal';
import { makeMenuVisible } from "../../action/context.menu.action";
import { updateTagsAndSearches } from "../../action/search.action";
import {linkViewer, linkViewerNewTab} from "../../utils/utils";

const ButtonGroup = Button.Group;

const pageSize = 12;

export class SlideList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            assignCaseModalVisible: false,
            assignMultipleCaseModalVisible: false,
            changeSlideOwnerModalVisible: false,
            selectedSlide: {},
            showTagsSection: false,
            totalSelection: false,
            currentHoverRowID: -1,
        }

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile == 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.urlState.searches.length != this.props.urlState.searches.length ||
            prevProps.urlState.tags.length != this.props.urlState.tags.length) {
            this.setState({ selectedRowKeys: [], totalSelection: false });
        }
    }

    handleUpload = (morphle_id, id, allIds, uploadDebug, uploadDebugOnly) => {
        this.props.dispatch(startUpload(morphle_id, id, allIds, uploadDebug, uploadDebugOnly));
    }

    handleDelete = (slide) => {
        this.props.dispatch(deleteSlide(slide, this.props.urlState))
    }

    starSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.props.allSlides[value], true);
        });
    }

    uploadSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            let slide = this.props.allSlides[value];
            this.props.dispatch(startUpload(slide.morphle_id, slide.id, this.props.allIds, false, false));
        });
    }

    tileSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.props.dispatch(startTiling(this.props.allSlides[value].morphle_id, this.props.allIds));
        });
    }

    copySelectedRows = () => {
        let allPaths = "";
        this.state.selectedRowKeys.map((value, index) => {
            let scanFolderUrl = this.props.allSlides[value].loc_on_machine + "/scans/" + this.props.allSlides[value].bucket_name + "/" + this.props.allSlides[value].path;
            allPaths += '"' + scanFolderUrl + '",\n';
        });
        navigator.clipboard.writeText(allPaths.substring(0, allPaths.length-2));
        message.info("Path Copied To Clipboard");
    }

    unstarSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.props.allSlides[value], false);
        });
    }

    deleteSelectedRows = (e) => {
        this.state.selectedRowKeys.map((value, index) => {
            if (this.props.allSlides[value] == undefined) return;
            this.handleDelete(this.props.allSlides[value]);
        });
        message.loading("Scheduling slides for Deletion");
    }

    handleStarButton = (slide, input) => {
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: slide.name,
            starred: input,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    handleNameChange = (slide, newName) => {
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: newName,
            starred: slide.starred,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    handleAssignCase = (slide, input) => {
        this.setState({
            selectedSlide: slide,
            assignCaseModalVisible: true,
        });
    }

    openMultipleAssignCaseModal = () => {
        this.setState({
            assignMultipleCaseModalVisible: true,
        });
    }

    openChangeOwnerModal = () => {
        this.setState({
            changeSlideOwnerModalVisible: true,
        });
    }

    closeModal = () => {
        this.setState({
            assignCaseModalVisible: false,
        });
    }

    closeMultipleCaseModal = () => {
        this.setState({
            assignMultipleCaseModalVisible: false,
        });
    }

    closeChangeOwnerModal = () => {
        this.setState({
            changeSlideOwnerModalVisible: false,
        });
    }

    openDeleteModal = () => {

        var deletedIDs = "";

        this.state.selectedRowKeys.map((value, index) => {
            if (this.props.allSlides[value] == undefined) return;
            if (index != this.state.selectedRowKeys.length - 1) deletedIDs += (this.props.allSlides[value] || {}).name + ", ";
        });

        deletedIDs += this.props.allSlides[this.state.selectedRowKeys[this.state.selectedRowKeys.length - 1]].name;

        const deleteRowsHandle = this.deleteSelectedRows;

        Modal.confirm({
            title: 'Following scans will be deleted. Confirm?',
            icon: <InfoCircleOutlined></InfoCircleOutlined>,
            content: (<Row>{deletedIDs}</Row>),
            onOk() { deleteRowsHandle() },
            onCancel() { }
        });
    }

    getRowActionsIfAtleastOneRowSelected = () => {
        let selectedSlides = [];
        if (this.state.selectedRowKeys.length > 0) {
            for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                selectedSlides.push(this.props.allSlides[this.state.selectedRowKeys[i]]);
            }
        }
        return this.state.selectedRowKeys.length > 0 ?
            <Row>
                <Col span={18}>
                    <ButtonGroup key={1}>
                        {!this.state.totalSelection ?
                            <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allSlides).map((value) => parseInt(value)), totalSelection: false }) }}>
                                Select All Scans
                            </Button> : undefined}
                        {cookie.loadAll().is_staff === "true" && !this.state.totalSelection ?
                            <Button key={5} onClick={this.openMultipleAssignCaseModal}>
                                Assign Case
                                    <BookOutlined style={{ fontSize: "13px" }} />
                            </Button> : undefined}
                        {cookie.loadAll().superuser === "true" && !this.state.totalSelection ?
                            <Button key={1} onClick={this.tileSelectedRows}>
                                Start Tiling
                            </Button> : undefined}
                        {cookie.loadAll().deployment_mode === 'offline' && !this.state.totalSelection ?
                            <Button key={-1} onClick={this.uploadSelectedRows}>
                                Upload
                                <CloudUploadOutlined style={{ fontSize: "13px" }} />
                            </Button> : undefined}
                        {!this.state.totalSelection ?
                            <Button key={2} onClick={this.starSelectedRows}>
                                Star
                                    <StarFilled style={{ color: "#f5667b", fontSize: "13px" }} />
                            </Button> : undefined}
                        {!this.state.totalSelection ?
                            <Button key={3} onClick={this.unstarSelectedRows}>
                                Unstar
                                    <StarFilled style={{ fontSize: "13px" }} />
                            </Button> : undefined}
                        {cookie.loadAll().is_staff === "true" && !this.state.totalSelection ?
                            <Button key={4} onClick={this.openDeleteModal}>
                                Delete
                                    <DeleteFilled style={{ fontSize: "13px" }} />
                            </Button> : undefined}
                        {(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_owner_change_action ?
                            <Button key={6} onClick={this.openChangeOwnerModal}>
                                Change Owner
                                    <SettingFilled style={{ fontSize: "13px" }} />
                            </Button> : undefined}
                        {cookie.loadAll().superuser === "true" && !this.state.totalSelection ?
                            <Button key={1} onClick={this.copySelectedRows}>
                                Copy Path
                            </Button> : undefined}
                    </ButtonGroup>
                </Col>
                {!this.state.totalSelection ?
                    <Col span={3}>
                        <MultipleTagAddition selectedSlides={selectedSlides} />
                    </Col> : undefined}
                <Col span={2} offset={1}>
                    <Button key={0} className="lighter-danger-button" type="danger" onClick={() => { this.setState({ selectedRowKeys: [], totalSelection: false }) }} ghost>
                        Cancel Selection
                    </Button>
                </Col>
            </Row>
            :
            <Row>
                <Col span={12}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allSlides).map((value) => parseInt(value)), totalSelection: false }) }}>
                        Select All Scans
                    </Button>
                </Col>
                <Col offset={1} span={11}>
                    <Button type={this.state.showTagsSection ? "primary" : ""} onClick={() => { this.setState({ showTagsSection: !this.state.showTagsSection }) }}>
                        {this.state.showTagsSection ? "Hide All Tags" : "Show All Tags"}
                    </Button>
                </Col>
            </Row>;

    }

    getFilters = () => {
        return this.state.selectedRowKeys.length > 0 ? undefined : <FilterComp />;
    }

    getRowActions = () => {
        return <Row>
            <Col span={this.state.selectedRowKeys.length > 0 ? 24 : 8}>
                <Row>
                    <Col span={3}>
                        <span key={0} style={{ marginRight: "10px" }}>
                            {this.state.selectedRowKeys.length > 0 ? "What do you want to do ? " : ""}
                        </span>
                    </Col>
                    <Col span={20}>
                        {this.getRowActionsIfAtleastOneRowSelected()}
                    </Col>
                </Row>
            </Col>
            <Col span={this.state.selectedRowKeys.length > 0 ? 0 : 16}>{this.getFilters()}</Col>
        </Row>
    }

    getPaginationDiv = () => {
        return <Pagination
            total={this.props.total}
            showTotal={(total, range) =>
                this.isMobile ? `` : `Showing ${range[0]}-${range[1]} out of ${total} total scans`
            }
            current={this.props.urlState.page}
            pageSize={pageSize}
            defaultCurrent={1}
            onChange={this.updatePage}
            size="small"
            className="pagination-mp"
        />
    }

    updatePage = (pageNumber) => {
        historyMP.push('/' + globalUrlPrefix + '/dashboard?page=' + pageNumber);
        this.props.dispatch(changePage(pageNumber, this.props.urlState));
        this.setState({ selectedRowKeys: [], totalSelection: false });
    }

    getTableSource = (allSlides) => {
        let allSlidesList = [];
        Object.keys(allSlides).sort(
            function (a, b) {
                return allSlides[a].displayOrder - allSlides[b].displayOrder;
            }
        ).map((key) => {
            let slide = allSlides[key];
            if (!slide.isDeleted && slide.name != undefined) {
                allSlidesList.push({
                    id: slide.id,
                    type: openInNewTabType.SLIDE,
                    status: slide.status,
                    morphle_id: slide.morphle_id,
                    only_tiling: slide.only_tiling,
                    slideCard: this.getSlideCard(slide),
                })
            }
        });

        return allSlidesList;
    }

    getAudienceCard = (slide) => {
        return <div>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Col span={7}>
                    <Row>
                        <b>{slide.name}</b>
                    </Row>
                </Col>
                <Col span={11}>
                    <Row>
                        {getTagsComponent(slide, this.props.dispatch, this.props.urlState)}
                    </Row>
                </Col>
                <Col span={6}>
                    {getPreviewComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
            </Row>
        </div>
    }

    getGroupActionables = (slide) => {
        return <Row>
            <Col span={8}>
                {getStarredComponent(slide, this.handleStarButton)}
            </Col>
            {slide.status === 9 || slide.status === 12 ?
                <div>
                    <Col span={8}>
                        {getUploadComponent(slide, this.props.allIds, this.handleUpload)}
                    </Col>
                    {cookie.loadAll().superuser === "true" ?
                        <Col span={8}>
                            {getTilingComponent(slide, this.props.allIds, this.props.dispatch)}
                        </Col> :
                        null
                    }
                </div> :
                slide.status === 0 ?
                    <Col span={8}>
                        <div>Scanning</div>
                    </Col> :
                    <Col span={16}>
                        <Row style={{ marginLeft: 11 }}>
                            {stitchingPercentComponent(slide)}
                        </Row>
                        <Row>
                            <span style={{ fontSize: 10, marginLeft: 11 }}>Stitching</span>
                        </Row>
                    </Col>
            }
        </Row>
    }

    getSlideInfoCardDetails = (slide) => {
        return <Col span={8}>
            <Row>
                <span style={{ fontWeight: 400 }}>{slide.name}</span>
            </Row>
            {slide.case_id.length > 0 ?
                <Row style={{ marginTop: 5, fontSize: 12 }}>
                    <span>{"CASE ID: " + slide.case_id}</span>
                </Row> :
                this.state.currentHoverRowID == slide.id && cookie.loadAll().is_staff === "true" ?
                    <Row style={{ marginTop: 5 }}>
                        {getAssignCaseButtonComponent(slide, this.handleAssignCase)}
                    </Row> :
                    null
            }
            <Row style={{ marginTop: 10 }}>
                {getTagsComponent(slide, this.props.dispatch, this.props.urlState)}
            </Row>
        </Col>
    }

    getActionables = (slide) => {
        return <div>
            <Row style={{ marginTop: 10 }}>
                <Col offset={cookie.loadAll().superuser === "true" ? 4 : cookie.loadAll().is_staff === "true" ? 16 : 20} span={4}>
                    {getAssignCaseComponent(slide, this.handleAssignCase)}
                </Col>
                {/* {cookie.loadAll().superuser === "true" ?
                    <Col span={3}>
                        {getExecutableComponent(slide)}
                    </Col> :
                    null
                } */}
                {cookie.loadAll().is_staff === "true" ?
                    <Col span={4}>
                        {getExportComponent(slide, this.props.allIds, this.props.dispatch)}
                    </Col> :
                    null
                }
                {cookie.loadAll().superuser === "true" ?
                    <Col span={4}>
                        {getDbEntryComponent(slide)}
                    </Col> :
                    null
                }
                {cookie.loadAll().superuser === "true" ?
                    <Col span={4}>
                        {getMorphleIDComponent(slide)}
                    </Col> :
                    null
                }
                {cookie.loadAll().superuser === "true" ?
                    <Col span={4}>
                        {getOldTilingComponent(slide, this.props.allIds, this.props.dispatch)}
                    </Col> :
                    null
                }
                {cookie.loadAll().superuser === "true" || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_delete_slide_action ?
                    <Col span={4}>
                        {getDeleteComponent(slide, this.handleDelete)}
                    </Col> :
                    null
                }
            </Row>
            <br />
        </div>
    }

    getSlideDetailsForCard = (slide) => {
        return <Row style={this.state.currentHoverRowID == slide.id && cookie.loadAll().is_staff === "true" ? {} : { marginTop: 35 }}>
            <Col offset={cookie.loadAll().superuser === "true" ? 0 : cookie.loadAll().is_staff === "true" ? 6 : 19} span={5}>
                <div>{slide.date}</div>
            </Col>
            {cookie.loadAll().is_staff === "true" ?
                <Col span={4}>
                    <div>{slide.owner_name}</div>
                </Col> :
                null
            }
            {cookie.loadAll().superuser === "true" ?
                <Col span={3}>
                    {getObjectiveComponent(slide, slideViewType.DASHBOARDVIEW)}
                </Col> :
                null
            }
            {cookie.loadAll().is_staff === "true" ?
                <Col span={4}>
                    <div>{slide.scan_speed}</div>
                </Col> :
                null
            }
            {cookie.loadAll().is_staff === "true" ?
                <Col span={3}>
                    <div>{slide.scan_time}</div>
                </Col> :
                null
            }
            {cookie.loadAll().superuser === "true" ?
                <Col span={3}>
                    <div>{slide.rescan_time}</div>
                </Col> :
                null
            }
            {cookie.loadAll().superuser === "true" ?
                <Col span={5}>
                    <div>{slide.area_of_box_scanned.toFixed(2) + "mm2"}</div>
                </Col> :
                null
            }

        </Row>
    }

    getSlideCard = (slide) => {
        if ((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
            this.getAudienceCard(slide);
        } else {
            return <div>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col span={3}>
                        {this.getGroupActionables(slide)}
                    </Col>
                    {this.getSlideInfoCardDetails(slide)}
                    <Col span={4}>
                        {getPreviewComponent(slide, this.is_cloud, this.isMobile)}
                    </Col>
                    <Col span={2}>
                        {getLabelComponent(slide, this.is_cloud, this.isMobile)}
                    </Col>
                    <Col span={6}>
                        {this.state.currentHoverRowID == slide.id && cookie.loadAll().is_staff === "true" ?
                            this.getActionables(slide) :
                            null
                        }
                        {this.getSlideDetailsForCard(slide)}
                    </Col>
                </Row>
            </div>
        }
    }

    searchTag = (tag) => {
        let currentTags = this.props.urlState.tags;
        if (currentTags.includes(tag)) {
            const index = currentTags.indexOf(tag);
            if (index > -1) {
                currentTags.splice(index, 1);
            }
        } else {
            currentTags.push(tag);
        }
        this.props.dispatch(updateTagsAndSearches(currentTags, [], this.props.urlState));
    }

    selectedScansAllPages = () => {
        this.setState({
            totalSelection: true,
        });
    }

    render() {
        let tableSource = this.getTableSource(this.props.allSlides);

        let finalColumns = [{
            dataIndex: 'slideCard',
            key: 'slideCard',
        }];

        finalColumns = finalColumns.map(col => {
            if (col.dataIndex === 'tagsComponent') {
                return col;
            } else {
                return {
                    ...col,
                    onCell: record => ({
                        onClick: (event) => {
                            if (event.ctrlKey) {
                                if (record.status === 9 || record.status === 12) {
                                    linkViewerNewTab(record);
                                } else {
                                    message.error("Scanning going on!!", 2.5);
                                }
                            } else {
                                if (record.status === 9 || record.status === 12) {
                                    linkViewer(record);
                                } else {
                                    message.error("Scanning going on!!", 2.5);
                                }
                            }
                        },
                    }),
                };
            }
        });

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                })
            },
            selectedRowKeys: this.state.selectedRowKeys
        };

        const onRow = (record, rowIndex) => {
            return {
                onContextMenu: (event) => {
                    event.preventDefault();
                    this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
                },
                onMouseEnter: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: record.id,
                    });
                },
                onMouseLeave: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: -1,
                    });
                }
            };
        }

        let rowActions = this.isMobile || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience ? undefined :
            [
                <Divider key={3} />,
                <Row key={0} className="row-actions">
                    {this.getRowActions()}
                </Row>
            ];

        return (
            [<Row key={0} className="fixed-controls-dashboard" id="fixed-controls-dashboard">
                <Col span={24}>
                    <Row key={-2} style={{ marginTop: 30 }}>
                        <Col span={this.isMobile ? 22 : 12} offset={this.isMobile ? 1 : 6}>
                            <SearchBar />
                        </Col>
                    </Row>
                    {this.state.showTagsSection ?
                        <Row>
                            <div className="tags-section">
                                {this.props.allTags.map((tag, index) => {
                                    let tagElem;
                                    tagElem = (
                                        <Button
                                            size="small"
                                            onClick={() => this.searchTag(tag)}
                                            className={this.props.urlState.tags.includes(tag) ? "tags-button lighter-danger-button" : "tags-button lighter-primary-button"}
                                            type={this.props.urlState.tags.includes(tag) ? "danger" : "primary"}
                                            ghost
                                        >
                                            {tag}
                                        </Button>
                                    );
                                    return tagElem;
                                })}
                            </div>
                        </Row>
                        : null
                    }
                    <Row key={-1}>
                        {this.getPaginationDiv()}
                    </Row>
                    {rowActions}
                    <Divider />
                </Col>
            </Row>,
            <div>
                {(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_owner_change_action && this.state.selectedRowKeys.length == pageSize && !this.state.totalSelection ?
                    <Row className="selected-scan-message">
                        <span>{"All " + pageSize + " scans on this page are selected."}</span>
                        <span className="select-all-scans-message" onClick={this.selectedScansAllPages}>
                            {"Select " + this.props.total + " scans on all the pages."}
                        </span>
                    </Row>
                    : null
                }
            </div>,
            <div>
                {(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_owner_change_action && this.state.totalSelection ?
                    <Row className="selected-scan-message">
                        <span>{this.props.total + " scans on all the pages are selected."}</span>
                    </Row>
                    : null
                }
            </div>,
            <Row key={1} className="scrollable-dashboard">
                <Table
                    key={1}
                    loading={this.props.isFetching}
                    dataSource={tableSource}
                    columns={finalColumns}
                    rowKey="id"
                    pagination={false}
                    showHeader={false}
                    rowSelection={this.isMobile || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience ? undefined : rowSelection}
                    onRow={onRow}
                    rowClassName="slide-table-rows custom-table-padding"
                />
                <Row key={2}>
                    {this.getPaginationDiv()}
                </Row>
            </Row>,
            <CaseSuggestions modalVisible={this.state.assignCaseModalVisible} selectedSlide={this.state.selectedSlide} closeModal={this.closeModal} />,
            <MultipleCaseSuggestions modalVisible={this.state.assignMultipleCaseModalVisible} selectedRowKeys={this.state.selectedRowKeys} closeModal={this.closeMultipleCaseModal} />,
            <ChangeSlideOwner modalVisible={this.state.changeSlideOwnerModalVisible} selectedRowKeys={this.state.selectedRowKeys} closeModal={this.closeChangeOwnerModal} totalSlides={this.props.total} filteredSlides={this.state.totalSelection} />
            ]
        )
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        isFetching: state.searchReducer.isFetching,
        numPages: state.dashboardReducer.numPages,
        total: state.dashboardReducer.total,
        urlState: state.searchReducer,
        allTags: state.dashboardReducer.allTags
    }
}

export default connect(mapStateToProps)(SlideList);
