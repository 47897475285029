import React, {Component} from 'react';
import {connect} from "react-redux";
import {globalUrlPrefix} from "../../../utils/const";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from "@mui/material/Typography";
import {
    Button,
    Divider,
    Grid,
    List, ListItem, ListItemText,
    MenuItem,
    Select, Skeleton,
    TextField
} from "@mui/material";
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import cookie from "react-cookies";
import {AuthHeader} from "../../../helper/auth.token";
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import {message} from "antd";
import queryString from "query-string";
import {openInNewtab} from "../../../utils/utils";
class CliaApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide_data: this.props.mapsState[this.props.activeMapId].slideState.slide_data,
            user_id: cookie.load('user_id'),
            fetching: true,
            currCase: "",
            valdn_diagnosis:"",
            valdn_scanQuality:"",
            valdn_diagnosticCertainity:"",
            valdn_concordanceDesignation:"",
            caseScansArray:[],
            fetchingCaseScans: true,
        }
    }

    componentDidMount = () => {
        if(this.state.slide_data.case != null || this.state.slide_data.case != undefined){
            this.getCase();
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if(this.state.user_id != prevState.user_id){
            this.getCaseValidationData();
        }
    }

    getCase = () => {
        let url = `/api/case/${this.state.slide_data.case}/`;
        axios.get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
            .then(res => {
                console.log("response**", res.data)
                this.setState({
                    currCase: res.data,
                })
            }).then(() => {
                this.getCaseValidationData();
                this.getCaseSlides()
            })
    }

    getCaseSlides = () => {
        let url = `/api/nonpagslides/?case_id=` + this.state.currCase.id;
        axios
            .get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                this.setState({
                    caseScansArray: response.data,
                })
            })
    }

    getCaseValidationData = () => {
        let url = `/api/user_case_slide_specific_validation_data/?case_id=${this.state.currCase.id}&user=${parseInt(this.state.user_id)}&slide_id=${this.state.slide_data.id}`;
        this.setState({
            fetching: true,
        })
        axios.get(url).then(response => {
            let data = response.data['result'];
            this.setState({
                valdn_diagnosis: data['diagnosis'],
                valdn_scanQuality: data['quality_of_scan'],
                valdn_diagnosticCertainity:data['diagnostic_certainty'],
                valdn_concordanceDesignation:data['concordance_designation'],
                fetching: false,
            })
        })
    }


    URLify=(string)=>{
        return string.trim().replace(/\s/g, '%20');
    }

    redirectCliaCase = () => {
        window.location.href = "/" + globalUrlPrefix + "/clia_case/" + this.state.slide_data.case;
    }

    updateValidationData = (e, type) => {
        if(type == "diagnosis"){
            this.setState({
                valdn_diagnosis: e.target.value,
            })
        }else if(type == "scan_quality"){
            this.setState({
                valdn_scanQuality: e.target.value,
            })
        }else if(type == "diagnostic_certainity"){
            this.setState({
                valdn_diagnosticCertainity: e.target.value,
            })
        }else if(type == "concordance_designation"){
            this.setState({
                valdn_concordanceDesignation: e.target.value,
            })
        }
    }

    getDiagnosisForm = () => {
        return <Grid sx={{marginTop:'2px'}}>
                    {/*<TextField*/}
                    {/*    id="outlined-multiline-flexible"*/}
                    {/*    label="Diagnosis"*/}
                    {/*    label={<div style={{color:"white"}}>Diagnosis</div>}*/}
                    {/*    multiline*/}
                    {/*    maxRows={10}*/}
                    {/*    type={"secondary"}*/}
                    {/*    value={"value"}*/}
                    {/*    // onChange={handleChange}*/}
                    {/*/>*/}
                    <Typography color={"secondary"} sx={{marginTop:'1vh'}}>Diagnosis</Typography>
                    <TextField
                        multiline
                        rows={4}
                        variant="filled"
                        fullWidth={true}
                        value={this.state.valdn_diagnosis}
                        onChange={(e)=>{this.updateValidationData(e, "diagnosis")}}
                    />
                    <Typography color={"secondary"} sx={{marginTop:'5vh'}}>Scan Quality</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"} value={this.state.valdn_scanQuality}
                            onChange={(e)=>{this.updateValidationData(e, "scan_quality")}}>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Adequate">Adequate</MenuItem>
                        <MenuItem value="Borderline Adequate">Borderline Adequate</MenuItem>
                        <MenuItem value="Inadequate">Inadequate</MenuItem>
                    </Select>
                    <Typography color={"secondary"} sx={{marginTop:'5vh'}}>Diagnostic Certainity</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"} value={this.state.valdn_diagnosticCertainity}
                            onChange={(e)=>{this.updateValidationData(e, "diagnostic_certainity")}}>
                        <MenuItem value="Highly Confident">Highly Confident</MenuItem>
                        <MenuItem value="Mostly Confident">Mostly Confident</MenuItem>
                        <MenuItem value="Not Confident">Not confident</MenuItem>
                    </Select>
                    <Typography color={"secondary"} sx={{marginTop:'5vh'}}>Concordance Designation</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"} value={this.state.valdn_concordanceDesignation}
                            onChange={(e)=>{this.updateValidationData(e, "concordance_designation")}}>
                        <MenuItem value="Complete Agreement">Complete Agreement</MenuItem>
                        <MenuItem value="Minor Disagree No Impact">Minor Disagree - No Clinical Impact</MenuItem>
                        <MenuItem value="Minor Disagree Minimal Impact">Minor Disagree - Minimal Clinical Impact</MenuItem>
                        <MenuItem value="Major Disagreement">Major Disagreement</MenuItem>
                    </Select>
                </Grid>
    }

    getFormLoading = () => {
            return <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
    }

    changeUser = (e) => {
        this.setState({
            user_id: e.target.value,
        })
    }

    getAssignedUsers = () => {
        let assigned_user_options;
        if((this.state.currCase||{}).assigned_users){
            let curr_case = JSON.parse(JSON.stringify(this.state.currCase))
            let assigned_users = curr_case['assigned_users']
            assigned_users.push({
                "id": cookie.load('user_id'),
                "username": cookie.load('username'),
            })
            assigned_user_options = Object.values(assigned_users).map((user, index) => {
                return <MenuItem key={index} value={user.id}>{user.username}</MenuItem>
            })
        }
        return <div>
            <Typography color={"secondary"}>User</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"} value={this.state.user_id}
                        onChange={(e)=>{this.changeUser(e)}}>
                        {assigned_user_options}
                    </Select>
                </div>
    }

    storeValidationData = () => {
        if(this.state.valdn_diagnosis=="" || this.state.valdn_scanQuality=="" || this.state.valdn_diagnosticCertainity==""){
            message.info("Please fill all the required fields")
            return;
        }
        let data = {
            user: parseInt(this.state.user_id),
            case_id: this.state.currCase.case_id,
            slide_id: this.state.slide_data.id,
            slide_name: this.state.slide_data.name,
            diagnosis: this.state.valdn_diagnosis,
            quality_of_scan: this.state.valdn_scanQuality,
            diagnostic_certainity: this.state.valdn_diagnosticCertainity,
            concordance_designation: this.state.concordance_designation,
            input_type: "POST-GLASS",
            notes: ''
        }
        let url = `/api/update_validation_data/`;
        axios.post(url, queryString.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if(response.status === 200){
                    message.error("Failed to update data, Contact Admin", 3);
                    return;
                }
                message.success("Successfully saved data", 3);
            })

    }

    openSlide = (e, id) => {
        if(this.state.slide_data.id == id){
            return;
        }
        let url = "/" + globalUrlPrefix + "/gamma-viewer/" + id;
        window.open(url, "_blank")
    }

    render(){
        console.log("diag--", this.state.caseScansArray)
        let other_slides_in_scan=[];
        for(let i=0; i<this.state.caseScansArray.length; i++){
            let slide = this.state.caseScansArray[i];
            other_slides_in_scan.push(
                <ListItem key={slide.id} disablePadding onClick={(e)=>this.openSlide(e, slide.id)} disabled={this.state.slide_data.id == slide.id}>
                    <ListItemText
                        primary={slide.name}
                    />
                </ListItem>
            )
        }
        let trf_link = "/case-files/" + this.state.slide_data.case +"/case_trf/"+this.state.slide_data.case+".pdf";
        let trf_linkified = this.URLify(trf_link);
        let clia_link = "/" + globalUrlPrefix + "/clia_case/" + this.state.slide_data.case;

        let goto_clia_case;
        if(this.state.slide_data.case == null){
            return <div sx={{marginTop:'30px'}}>
                        <HelpOutlineIcon sx={{marginLeft:'40%', fontSize:'50px'}}/>
                        <br/>
                        <Typography sx={{fontSize:'18px'}}>
                            Slide doesnt belong to any case.
                        </Typography>
                    </div>
        }else{
            goto_clia_case = <Button color={"secondary"} onClick={()=>{openInNewtab(clia_link)}} sx={{textAlign:'centre'}}>
                                    <LaunchOutlinedIcon/> &nbsp;
                                    Open Clia Case
                                </Button>
        }
        let trf_upload;
        if(this.state.slide_data.trf_uploaded == "true" || this.state.slide_data.trf_uploaded == true){
            trf_upload = <div>
                            <Button color={"secondary"} onClick={() => openInNewtab(trf_linkified)}>
                                <PageviewOutlinedIcon/> &nbsp;
                                View Ancillary Clinical Info
                            </Button>
                        </div>
        }

        console.log("slide_data",this.state.slide_data)

        let save_data = <Grid style={{textAlign:'center', marginTop:'2vh'}} onClick={()=>this.storeValidationData()}>
                            <Button color={"secondary"}
                                disabled={this.state.valdn_scanQuality.length!=0 && cookie.loadAll().is_staff !== "true"}>
                                <SaveIcon/> &nbsp;
                                Save
                            </Button>
                        </Grid>

        return  <div>
                    {goto_clia_case}
                    {trf_upload}
                    <Divider/>
                    {!this.state.fetching && cookie.loadAll().is_staff === "true" ? this.getAssignedUsers(): this.getFormLoading()}
                    <Divider sx={{mt: 1}}/>
                    {!this.state.fetching ? this.getDiagnosisForm(): this.getFormLoading()}
                    {save_data}
                    <Divider sx={{mt: 1}}/>
                    <Typography color={"secondary"} sx={{textAlign:'center'}}>Other Slides In Case</Typography>
                    <List>
                        {other_slides_in_scan}
                    </List>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(CliaApp)
