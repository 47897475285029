import React,{Component} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Grid, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {getAllSideBarTopApps} from "./app_maker";
import AppsIcon from '@mui/icons-material/Apps';
import {getTabPanelComponent, getTabComponent} from "./utils/side_bar_utils";
import {TabPanel} from "./utils/side_bar_utils";
import {openAppSidebarTop} from "../../action/triggers.action";
import {updateAppInstalledStatus} from "../../action/gamma.state.action";
import {updateTopBarAppOpen} from "../../action/triggers.action";
import {morphleBaseTheme1} from "./themes";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

class SideBarTop extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentTab: -1, // -1 used as representation to close tab-panel or to say no app selected,
            tabs: [],
            tabPanels: [],
            appIdTabIdMap: {},
        }
    }

    handleChange = (event, value) => {
        if(value === this.state.currentTab){
            this.setState({
                currentTab: -1,
            })
            return;
        }
        this.setState({
            currentTab: value,
        })
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.triggerData.appId_open !== prevProps.triggerData.appId_open) &&
            this.props.triggerData.appId_open !== ""){
            let newTabToOpen = this.state.appIdTabIdMap[this.props.triggerData.appId_open]
            this.setState({
                currentTab : newTabToOpen,
            })
            this.props.dispatch(openAppSidebarTop(""))
        }
        if(prevState.currentTab === -1 && this.state.currentTab !== -1){
            this.props.dispatch(updateTopBarAppOpen(true))
        }
        if(prevState.currentTab!==-1 && this.state.currentTab===-1){
            this.props.dispatch(updateTopBarAppOpen(false))
        }
    }


    getTabsAndTabPanels = (gammaState) => {
        let apps_list = getAllSideBarTopApps(gammaState);
        if(!apps_list){
            return [[],[]]
        }

        let allAppsTab = <Tab icon={<AppsIcon sx={{fontSize:'inherit'}}/>} {...a11yProps(0)}
                              onClick={(e)=>this.handleChange(e, 0)}
                            sx={{fontSize:'3vh'}} value={0}>
                        </Tab>
        let tabs = [
            allAppsTab,
        ]; //shown on leftSide
        let tabPanels = []//shown only in allApps section
        let allAppsTabPanel = [];
        let rowGrid = []
        let appIdTabIdMap = {}
        appIdTabIdMap[0]=0;
        let count = 1;
        for(let i=1; i<=apps_list.length; i++){
            let app = apps_list[i-1];
            if (app.permission === undefined)
                this.props.dispatch(updateAppInstalledStatus(app.id, app.defaultPermission));
            if (!app.permission)
                continue;
            appIdTabIdMap[app.appId] = i;
            if(app.makeSideBarIcon){
                tabs.push(getTabComponent(i, app, this.handleChange));
            }
            tabPanels.push(getTabPanelComponent(i, app, this.state.currentTab));
            rowGrid.push(
                <Grid item sm={4} sx={{textAlign:'center', height:'10vh'}}>
                    {/*<Button onClick={(e) => {*/}
                    {/*    this.handleChange(e, i);*/}
                    {/*}}*/}
                    {/*>*/}
                        {/*{app.icon}*/}
                        <IconButton
                            onClick={(e) => {
                                this.handleChange(e, i);
                            }}
                            size={'large'}
                            color={'secondary'}
                            sx={{fill:morphleBaseTheme1.palette.secondary.main}} //For DeepDx Logo -- Do not remove, if conflicting contact spandan
                        >
                            {app.icon}
                        </IconButton>
                    <Typography noWrap={true}>
                        {app.title}
                    </Typography>
                    {/*</Button>*/}
                </Grid>
            )
            if(count%3 === 0){
                allAppsTabPanel.push(
                    <Grid container>
                        {[...rowGrid]}
                        {/*{rowGrid}*/}
                    </Grid>
                )
                rowGrid=[];
            }
            count++;
        }
        if((Object.keys(this.state.appIdTabIdMap).length) === 0){
            this.setState({
                appIdTabIdMap: appIdTabIdMap,
            })
        }
        if(rowGrid.length>0){
            allAppsTabPanel.push(<Grid container>
                {[...rowGrid]}
            </Grid>);
        }
        let allAppsTabPanelComp = <TabPanel index={"0"} value={String(this.state.currentTab)}>
                <Grid sx={{width:'15vw'}} >
                        {allAppsTabPanel}
                </Grid>
            </TabPanel>
        tabPanels.unshift(allAppsTabPanelComp)
        return [tabs, tabPanels];
    }

    render() {
        let [tabs, tabPanels] = this.getTabsAndTabPanels(this.props.gammaState);
        return (
                <Box
                    sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: '71vh',
                        // overflowY:'overlay'
                    }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.currentTab}
                        onChange={this.handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: "divider" }}
                    >
                        {tabs}
                    </Tabs>
                    <React.Fragment >
                        {tabPanels}
                    </React.Fragment>

                </Box>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        triggerData: state.triggerReducer,
    }
}

export default connect(mapStateToProps)(SideBarTop);

