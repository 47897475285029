import React from "react";
import Brightness6Icon from '@mui/icons-material/Brightness6';
import ContrastIcon from '@mui/icons-material/Contrast';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import OpacityIcon from '@mui/icons-material/Opacity';


export const brightnessKey = {
    id: 'brightness',
    name: 'Brightness',
    cssKey: 'brightness',
    unit: "",
    min: 0,
    max: 100,
    multiplier: 2,
    icon: <Brightness6Icon/>,
}
export const contrastKey = {
    id: 'contrast',
    name: 'Contrast',
    cssKey: 'contrast',
    unit: "",
    min: 0,
    max: 100,
    multiplier: 2,
    icon: <ContrastIcon/>,
}
export const hueKey = {
    id: 'hue',
    name: 'Hue',
    cssKey: 'hue-rotate',
    unit: "deg",
    min: 0,
    max: 100,
    multiplier: 1,
    icon: <OpacityIcon/>
}
export const saturationKey = {
    id: 'saturation',
    name: 'Saturation',
    cssKey: 'saturate',
    unit: "",
    min: 0,
    max: 100,
    multiplier: 1,
    icon: <FilterFramesIcon/>
}
export const invertKey = {
    id: 'invert',
    name: 'Invert',
    cssKey: 'invert',
    unit: "",
    min: 0,
    max: 100,
    multiplier: 1,
    icon: <InvertColorsIcon/>
}
export const grayscaleKey = {
    id: 'grayscale',
    name: 'Grayscale',
    cssKey: 'grayscale',
    unit: "",
    min: 0,
    max: 100,
    multiplier: 1,
    icon: <FilterBAndWIcon/>
}
