import axios from "axios";
import { AuthHeader } from "../../../helper/auth.token";
import cookie from 'react-cookies';

import {message} from 'antd';
import queryString from 'query-string';

var querystring = require('querystring');

export const runAIOnWholeSlide = (morphleId, slideId, updateStateCallBack) => {
    console.log("Run AI On Whole Slide", slideId);
    let url = `/api/deep_bio_analysis_request/`
    axios.post(url,
    querystring.stringify({morphle_id: morphleId, slide_id: slideId,creator: parseInt(cookie.load('user_id')), checked_annotations:[]})).then(
        res=>{
            if(res.status == 200){
                message.info("Request Successful")
                updateStateCallBack();
            }
        }
    ).catch(err=>{
        message.error("Request Failed, contact admin")
    })
}

export const runAIOnSelectedAnnotations = (morphleId, slideId, selectedAnnos, updateStateCallback) => {
    let url = `/api/deep_bio_analysis_request/`
    axios.post(url,
            querystring.stringify({morphle_id: morphleId, slide_id: slideId,creator: parseInt(cookie.load('user_id')), checked_annotations:selectedAnnos})).then(
            res=>{
                if(res.status == 200){
                    message.info("Request Successful")
                    updateStateCallback(selectedAnnos);
                }
            }
        ).catch(err=>{
            message.error("Request Failed, contact admin")
            updateStateCallback(selectedAnnos);
        })
}

